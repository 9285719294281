import React from 'react';
import './assets/scss/index.scss';
import AppRoute from './routes/routes';
import { WagmiProvider } from 'wagmi';
import { QueryClient } from '@tanstack/query-core';
import { QueryClientProvider } from '@tanstack/react-query';
import { wagmiConfig } from './config/wagmi';

function App() {
    const queryClient = new QueryClient();
    return (
        <React.Fragment>
            <WagmiProvider config={wagmiConfig}>
                <QueryClientProvider client={queryClient}>
                    <AppRoute />
                </QueryClientProvider>
            </WagmiProvider>
        </React.Fragment>
    );
}

export default App;

