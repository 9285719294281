/* eslint-disable @typescript-eslint/no-namespace */
import { Box, Button, Container, Grid, Link, Typography } from '@mui/material';
import * as React from 'react';
import Footer from '../CommonLayout/Footer';
import { CurrentUserContext } from '../CommonLayout/context';
import ApiUtils from '../../api/ApiUtils';
// If using TypeScript, add the following snippet to your file as well.
declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': React.DetailedHTMLProps<
                React.HTMLAttributes<HTMLElement>,
                HTMLElement
            >;
        }
    }
}
function PricingPage() {
    const currentUser = React.useContext(CurrentUserContext);
    if (currentUser && currentUser.isCustomer) {
        ApiUtils.getManagePaymentLink().then((res) => {
            window.location.href = res;
        });
    }

    return (
        <>
            {currentUser && (
                <Box
                    sx={{
                        background: '#ffffff',
                        borderRadius: '20px',
                        padding: ' 1rem 2rem 2rem 2rem',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography variant="h4">Subscriptions</Typography>
                    </Box>
                    <Box
                        className="upgrade"
                        textAlign={'center'}
                        sx={{ position: 'relative', borderRadius: '16px', marginTop: '1.5rem' }}
                    >
                        <Grid
                            container
                            spacing={3}
                            sx={{
                                textAlign: 'center',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '86px 24px',
                            }}
                        >
                            <Grid item lg={7} md={12} sm={12}>
                                <Typography
                                    sx={{
                                        fontSize: '1.5rem',
                                        lineHeight: '1.75rem',
                                        fontWeight: '600',
                                        margin: '0px',
                                    }}
                                >
                                    Choose a plan that is the best for you
                                </Typography>
                                <Typography
                                    sx={{
                                        margin: '24px 0px',
                                        fontSize: '0.875rem',
                                        fontWeight: '400',
                                        lineHeight: '1.75',
                                    }}
                                >
                                    Select the plan that fits your needs and your wallet! Enjoy our
                                    subscription service
                                </Typography>
                            </Grid>
                        </Grid>

                        <Box className="blur_bg"></Box>
                    </Box>
                    <Box
                        sx={{
                            marginBottom: 4,
                        }}
                    >
                        <stripe-pricing-table
                            pricing-table-id="prctbl_1OpCvGB0YQhHqdkk1EelQBdu"
                            publishable-key="pk_test_6jC3doFzfBQrJHhTuzl0l2BC"
                            customer-email={currentUser?.emailId}
                        ></stripe-pricing-table>
                    </Box>
                </Box>
            )}
            <Footer></Footer>
        </>
    );
}

export default PricingPage;

