import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../Common/PrimaryButton';

const primary = '#f4f4f4';

export default function Error() {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: primary,
            }}
        >
            <Container
                sx={{
                    display: 'block',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    margin: 'auto',
                    width: '100%',
                    textAlign: 'center',
                }}
            >
                <img
                    src="https://coins-of-kin.vercel.app/static/media/404-error-idea.a65b475afb310d2c0c4e.gif"
                    alt="404"
                    style={{ width: '100%', maxWidth: '500px' }}
                ></img>

                <Box sx={{ textAlign: 'center' }}>
                    <Typography
                        variant="h1"
                        sx={{
                            // textAlign: 'center',
                            color: '#191919',
                            fontSize: '2.25rem',
                            fontWeight: '600',
                            lineHeight: '2.75rem',
                            marginBottom: '32px',
                        }}
                    >
                        Opps!!!
                    </Typography>
                    <Typography
                        variant="h4"
                        sx={{
                            // textAlign: 'center',
                            color: '#191919',
                            fontSize: '1.3125rem',
                            fontWeight: '600',
                            lineHeight: '1.6rem',
                            marginBottom: '32px',
                        }}
                    >
                        This page you are looking for could not be found.
                    </Typography>

                    <Button
                        style={{
                            backgroundColor: '#191919',
                            borderRadius: '20px',
                            color: '#fff',
                            padding: '6px 20px',
                            textTransform: 'capitalize'
                        }}
                        onClick={() => navigate('/assets')}
                    >
                        Go Back to Home
                    </Button>
                </Box>
            </Container>
        </Box>
    );
}
