import Typography from '@mui/material/Typography';
import { Avatar, Box, Button, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { useFormik } from 'formik';
import { SignInValidationSchema } from '../../validations/SignInValidation';
import ApiUtils from '../../api/ApiUtils';
import toast from 'react-hot-toast';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { BASE_IMG_URL } from '../../config/Constants';
import CustomToast from '../Common/CustomToast';
import Footer from '../CommonLayout/Footer';

function Assets() {
    const navigate = useNavigate();
    const matches = useMediaQuery('(min-width:600px)');
    const [currentUser, setCurrentUser] = useState<any | null>(null);
    useEffect(() => {
        (async () => {
            const data = await ApiUtils.getCurrentUser();
            setCurrentUser(data);
        })();
    }, []);
    const formik = useFormik({
        initialValues: {
            userName: '',
            password: '',
        },
        validationSchema: SignInValidationSchema,
        onSubmit: async (values) => {
            try {
                const userLogin = await ApiUtils.authLogin({
                    username: values.userName,
                    password: values.password,
                });
                if (userLogin?.data?.token) {
                    CustomToast({
                        message: 'Sign-in successful!',
                        severity: 'success',
                    });
                    sessionStorage.setItem('__COI_TOKEN__', userLogin.data.token);
                    window.location.href = '/assets';
                } else {
                    CustomToast({
                        message: 'Unauthorized Access!',
                        severity: 'error',
                    });
                }
            } catch (error) {
                CustomToast({
                    message: 'Unauthorized Access!',
                    severity: 'error',
                });
            }
        },
    });

    return (
        <>
            <Box
                mb={3}
                sx={{
                    borderRadius: '16px',
                    backgroundColor: 'rgb(255, 255, 255)',
                    boxShadow: 'rgba(153, 153, 153, 0.12) 0px 6px 20px 0px',
                    padding: '0px 0px 20px 0px',
                }}
            >
                <Box
                    sx={{
                        display: matches ? 'flex' : 'block',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '20px 24px',
                        boxShadow: 'rgba(153, 153, 153, 0.12) 0px 6px 20px 0px',
                        borderRadius: '16px',
                        backgroundColor: 'rgb(255, 255, 255)',
                    }}
                >
                    <Typography mb={matches ? 0 : 3} variant="h5" sx={{ fontWeight: 700 }}>
                        Account
                    </Typography>
                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        {/* <Box
                            className="update_pass"
                            sx={{
                                color: '#FFFF',
                                backgroundColor: 'black',
                                borderRadius: '20px',
                                padding: '10px 20px',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                fontSize: '14px',
                                fontWeight: '500',
                                '&:hover': {
                                    opacity: '0.6',
                                },
                            }}
                            onClick={() => {
                                navigate('/update-password');
                            }}
                        >
                            Update Password
                        </Box> */}
                        <Box
                            className="update_pass"
                            sx={{
                                color: '#FFFF',
                                backgroundColor: 'black',
                                borderRadius: '20px',
                                padding: '10px 20px',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                fontSize: '14px',
                                fontWeight: '500',
                                '&:hover': {
                                    opacity: '0.6',
                                },
                            }}
                            onClick={() => {
                                navigate('/manage-account');
                            }}
                        >
                            Manage Account
                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        padding: '20px 24px',
                        gap: '40px',
                        flexWrap: matches ? 'nowrap' : 'wrap',
                    }}
                >
                    <Box sx={{ mb: matches ? 0 : 1 }}>
                        {currentUser?.photo ? (
                            <Avatar
                                style={{ width: '95px', height: '95px' }}
                                src={BASE_IMG_URL + currentUser.photo}
                                alt="profile"
                            />
                        ) : (
                            <Avatar style={{ width: '95px', height: '95px' }}>
                                {/* Render the first letter of the first name in Avatar */}
                                {currentUser?.firstName &&
                                    currentUser?.firstName.charAt(0).toUpperCase()}
                            </Avatar>
                        )}
                    </Box>
                    <Box sx={{ mb: matches ? 0 : 1 }}>
                        <Typography mb={2} sx={{ mb: 0, fontWeight: 600, fontSize: '1rem' }}>
                            First Name
                        </Typography>
                        <Box sx={{ color: '#666666', marginBottom: '12px' }}>
                            {' '}
                            {currentUser?.firstName}
                        </Box>

                        <Typography mb={2} sx={{ mb: 0, fontWeight: 600, fontSize: '1rem' }}>
                            Last Name
                        </Typography>
                        <Box sx={{ color: '#666666', marginBottom: '12px' }}>
                            {currentUser?.lastName}
                        </Box>

                        <Typography mb={2} sx={{ mb: 0, fontWeight: 600, fontSize: '1rem' }}>
                            Email Address
                        </Typography>
                        <Box sx={{ color: '#666666', marginBottom: '12px' }}>
                            {currentUser?.emailId}
                        </Box>

                        <Typography mb={2} sx={{ mb: 0, fontWeight: 600, fontSize: '1rem' }}>
                            Contact Number
                        </Typography>
                        <Box sx={{ color: '#666666', marginBottom: '12px' }}>
                            {currentUser?.mobile}
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box
                mb={3}
                className="profile-box"
                sx={{
                    borderRadius: '16px',
                    backgroundColor: 'rgb(255, 255, 255)',
                    boxShadow: 'rgba(153, 153, 153, 0.12) 0px 6px 20px 0px',
                    padding: '0px 0px 20px 0px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '20px 24px',
                        boxShadow: 'rgba(153, 153, 153, 0.12) 0px 6px 20px 0px',
                        borderRadius: '16px',
                        backgroundColor: 'rgb(255, 255, 255)',
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: '21px',
                            display: 'flex',
                            gap: '12px',
                            alignItems: 'center',
                            mb: '0px',
                        }}
                    >
                        Current Plan
                    </Typography>
                    <Box
                        sx={{
                            color: '#FFFF',
                            backgroundColor: 'black',
                            borderRadius: '20px',
                            padding: '10px 20px',
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            fontSize: '14px',
                            fontWeight: '500',
                            '&:hover': {
                                opacity: '0.6',
                            },
                        }}
                        onClick={() => {
                            window.open('/subscription', '_blank');
                        }}
                    >
                        Change Plan
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '20px 24px',
                        borderRadius: '16px',
                    }}
                >
                    <Box
                        sx={{
                            fontSize: '16px',
                        }}
                    >
                        <Box>Package Name : Freemium</Box>
                        <Box>Description : Access to market search</Box>
                    </Box>
                </Box>
            </Box>
            <Footer />
        </>
    );
}

export default Assets;

