import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    Avatar,
    Box,
    Collapse,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    TableCell,
    TableRow,
    Typography,
    useMediaQuery,
} from '@mui/material';
import BeneficiaryImage from '../../assets/images/asset_img.png';
import BeneficiaryPopup from '../Popup/BeneficiaryPopup';
import EditBeneficiaryPopup from '../Popup/EditBeneficiaryPopup';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { useLazyGetWalletTokensByBeneficiaryIDQuery } from '../../services/Slices/apiSlice/assetsAPI';
import AutoCompleteLabel from './AutoCompleteLabel';
import { ConnectWalletContext, CurrencyContext, WalletContext } from '../CommonLayout/context';
import { NorthEastIcon, SouthEastIcon } from '../../config/Images';
import { BASE_IMG_URL } from '../../config/Constants';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ApiUtils from '../../api/ApiUtils';
import CustomToast from './CustomToast';

export default function AllBeneficiariesRow(props: any) {
    const matches = useMediaQuery('(min-width:600px)');
    const { row, walletTok, labelList } = props;
    const [open, setOpen] = useState(false);
    const [benData, setBenData] = useState<any>([]);
    const currency = useContext(CurrencyContext);
    const walletID = useContext(WalletContext);
    const wallet = useContext(ConnectWalletContext);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [editIcon, setEditIcon] = useState(false);
    const [EditBen, setEditBen] = useState<any>({});
    const [editPopup, setEditPopup] = useState(false);
    const [editLabel, setEditLabel] = useState('');
    const [confirm, setConfirm] = useState<any>(null);

    const handleEditBen = (value: any) => {
        setEditBen(value);
        setEditPopup(true);
    };

    const handleOpenPopup = () => {
        setPopupOpen(true);
    };
    const [GetWalletTokensByBeneficiaryID, { data, isLoading, error }] =
        useLazyGetWalletTokensByBeneficiaryIDQuery();

    useEffect(() => {
        const getData = async () => {
            const wallData = await GetWalletTokensByBeneficiaryID({
                beneficiaryID: row.id,
                walletID: walletID,
            });
            setBenData(wallData.data);
        };
        getData();
    }, [open, walletID, row]);
    // ONCLOSE DROPDOWN
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setEditLabel('');
            setEditIcon(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell
                    align="center"
                    sx={{ borderBottom: 'unset', justifyContent: 'center', display: 'flex' }}
                >
                    <Avatar
                        alt={row.beneficiaryName}
                        src={BASE_IMG_URL + row.photoFileName}
                        sx={{ width: '40px', height: '40px', backgroundColor: '#d0dbe1' }}
                    />
                </TableCell>

                <TableCell sx={{ borderBottom: 'none' }}>{row.beneficiaryName}</TableCell>
                <TableCell sx={{ borderBottom: 'none' }}>{row.nickName}</TableCell>
                <TableCell sx={{ borderBottom: 'unset', textAlign: 'center' }}>
                    {row.beneficiaryLabels && editLabel == '' ? (
                        <>
                            <Box
                                sx={{
                                    backgroundColor: '#fff',
                                    alignItems: 'center',
                                    position: 'relative',
                                    display: 'flex',
                                    gap: '8px',
                                }}
                                onMouseEnter={() => {
                                    setEditIcon(true);
                                }}
                                onMouseLeave={() => {
                                    setEditIcon(false);
                                }}
                            >
                                <Box
                                    className="assets_span"
                                    sx={{
                                        borderRadius: '16px',
                                        padding: '7px 20px',
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        backgroundColor: '#8649D333',
                                        color: '#8649D3',
                                        alignItems: 'center',
                                        // position: 'absolute',
                                    }}
                                >
                                    <span
                                        style={{
                                            height: '8px',
                                            width: '8px',
                                            borderRadius: '50%',
                                            display: 'inline-block',
                                            backgroundColor: '#8649D3',
                                            marginRight: '8px',
                                        }}
                                    ></span>
                                    {row.beneficiaryLabels}
                                </Box>
                                <Box>
                                    {editIcon && (
                                        <EditOutlinedIcon
                                            onClick={() => {
                                                setEditLabel(row.beneficiaryLabels);
                                            }}
                                            sx={{
                                                cursor: 'pointer',
                                                padding: '0px 5px',
                                                width: '15px',
                                                border: '1.5px solid rgb(134, 73, 211)',
                                                borderRadius: '8px',
                                                transition: 'opacity 0.5s ease',
                                                position: 'absolute',
                                                opacity: editIcon ? 1 : 0,
                                                transform: 'translate(-10%, -50%)',
                                            }}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <div ref={dropdownRef}>
                            <AutoCompleteLabel
                                handleUpdate={props.handleUpdateBenData}
                                labelData={labelList}
                                sentID={row.id}
                                labelType={1}
                            />
                        </div>
                    )}
                </TableCell>
                <TableCell sx={{ borderBottom: 'unset' }}>
                    <Box
                        className="edit_beneficiary"
                        onClick={() => {
                            handleEditBen(row);
                        }}
                        sx={{
                            textAlign: 'center',
                            flexGrow: 0,
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '14px',
                            fontWeight: '500',
                            lineHeight: '1.75',
                            // height: '25px',
                            padding: '6px 20px',
                            borderRadius: '20px',
                            backgroundColor: 'transparent',
                            border: '2px solid rgba(25, 25, 25, 0.5)',
                            cursor: 'pointer',
                            color: 'black',
                            width: 'max-content',
                            transition: 'border-color 0.3s ease',
                            '&:hover': {
                                borderColor: 'black',
                            },
                        }}
                    >
                        Edit Beneficiary
                    </Box>
                </TableCell>
                <TableCell sx={{ borderBottom: 'unset', textAlign: 'end' }}>
                    <Box sx={{ display: 'flex', gap: 10, justifyContent: 'end' }}>
                        {!wallet?.submitContract && (
                            <Box
                                className="manage_asset"
                                sx={{
                                    flexGrow: 0,
                                    display: 'flex',
                                    gap: '10px',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    padding: '6px 20px',
                                    borderRadius: '20px',
                                    backgroundColor: 'rgb(25, 25, 25)',
                                    cursor: 'pointer',
                                    color: '#FFF',
                                    // height: '30px',
                                    alignItems: 'center',
                                    position: 'relative',
                                    // left: '3%',
                                    // width: '200px',
                                    // textWrap: 'nowrap',
                                    right: '3%',
                                    '&:hover': {
                                        opacity: '0.6',
                                    },
                                }}
                                onClick={handleOpenPopup}
                                // onClick={() => setShowPopup(!showPopup)}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-settings"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"></path>
                                    <circle cx="12" cy="12" r="3"></circle>
                                </svg>
                                Assets Split
                            </Box>
                        )}
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? (
                                <img
                                    src={SouthEastIcon}
                                    alt="SouthEastIcon"
                                    width={'20px'}
                                    height={'20px'}
                                />
                            ) : (
                                <img
                                    src={NorthEastIcon}
                                    alt="NorthEastIcon"
                                    width={'20px'}
                                    height={'20px'}
                                />
                            )}
                        </IconButton>
                    </Box>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ display: 'flex', gap: '32px' }}>
                            <Avatar
                                className="big_avatar"
                                alt={row.beneficiaryName}
                                src={BASE_IMG_URL + row.photoFileName}
                                sx={{
                                    width: '240px',
                                    height: '160px',
                                    borderRadius: '13px',
                                    marginBottom: '16px',
                                    backgroundColor: '#d0dbe1',
                                }}
                            />

                            <Box sx={{ margin: 1, width: '100%' }}>
                                <Typography
                                    sx={{
                                        fontSize: '24px',
                                        fontWeight: '600',
                                        marginBottom: '16px',
                                        display: 'flex',
                                        gap: '8px',
                                        alignItems: 'center',
                                    }}
                                >
                                    {row.beneficiaryName}
                                </Typography>
                                <Box
                                    sx={{
                                        marginBottom: '16px',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                    }}
                                >
                                    Assets :
                                </Box>
                                <Box
                                    className="assets_wrapper"
                                    sx={{
                                        display: matches ? 'flex' : 'block',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '12px',
                                            alignItems: 'center',
                                            width: '60%',
                                            flexWrap: 'wrap',
                                        }}
                                    >
                                        {benData &&
                                            benData.map((ben: any, index: any) => (
                                                <Box
                                                    sx={{
                                                        backgroundColor: '#BCBCBC1A',
                                                        borderRadius: '30px',
                                                        padding: '8px 18px',
                                                        fontSize: '12px',
                                                        fontWeight: '500',
                                                        width: 'fit-content',
                                                        display: 'flex',
                                                        gap: '1rem',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Typography key={index}>
                                                        {`${ben?.name}`}
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                fontSize: '11px',
                                                                fontWeight: '400',
                                                                color: '#666666',
                                                                width: 'max-content',
                                                            }}
                                                        >
                                                            {currency == 'USD'
                                                                ? `${parseFloat(
                                                                      ben.balanceUSD
                                                                  ).toFixed(4)} USD`
                                                                : `${parseFloat(
                                                                      ben.balance
                                                                  ).toFixed(4)} ${ben.name}`}
                                                            {` (${ben?.percentageGiven})%`}
                                                        </Box>
                                                    </Typography>
                                                    {!wallet?.submitContract && (
                                                        <DeleteOutlinedIcon
                                                            onClick={() => {
                                                                setConfirm(ben);
                                                            }}
                                                            sx={{ cursor: 'pointer' }}
                                                            color="error"
                                                        />
                                                    )}
                                                </Box>
                                            ))}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Collapse>
                    <Dialog maxWidth="md" fullWidth={false} open={confirm ? true : false}>
                        <DialogTitle>Confirm delete</DialogTitle>
                        <DialogContent>
                            <Box>
                                <Typography>
                                    Are you sure you want to delete beneficiary ?
                                </Typography>{' '}
                            </Box>
                            <Box
                                sx={{
                                    marginTop: '2rem',
                                    float: 'right',
                                    gap: '10px',
                                    display: 'flex',
                                }}
                            >
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                        flexGrow: 0,
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        height: '25px',
                                        padding: '6px 20px',
                                        borderRadius: '20px',
                                        backgroundColor: 'black',
                                        border: '2px solid rgba(25, 25, 25, 0.5)',
                                        cursor: 'pointer',
                                        color: 'white',
                                        transition: 'border-color 0.3s ease',
                                        '&:hover': {
                                            opacity: '0.6',
                                        },
                                    }}
                                    onClick={async () => {
                                        try {
                                            if (confirm && confirm.id) {
                                                await ApiUtils.deleteBeneficiaryAsset(confirm);
                                            }
                                            CustomToast({
                                                message: 'Beneficiary successfully removed',
                                                severity: 'success',
                                            });
                                        } catch (e) {
                                            CustomToast({
                                                message: 'Something went wrong',
                                                severity: 'error',
                                            });
                                        }
                                        await props.handleUpdateAssetsData();
                                        setConfirm(null);
                                    }}
                                    className="confirm_contract"
                                >
                                    YES
                                </Box>
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                        flexGrow: 0,
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        height: '25px',
                                        padding: '6px 20px',
                                        borderRadius: '20px',
                                        backgroundColor: 'transparent',
                                        border: '2px solid rgba(25, 25, 25, 0.5)',
                                        cursor: 'pointer',
                                        color: 'black',
                                        transition: 'border-color 0.3s ease',
                                        '&:hover': {
                                            borderColor: 'black',
                                        },
                                    }}
                                    onClick={() => {
                                        setConfirm(null);
                                    }}
                                >
                                    NO
                                </Box>
                            </Box>
                        </DialogContent>
                    </Dialog>
                    <BeneficiaryPopup
                        handleUpdate={props.handleUpdateBenData}
                        row={row}
                        data={benData}
                        result={isPopupOpen}
                        setResult={setPopupOpen}
                        walletTok={walletTok}
                    />
                </TableCell>
                {editPopup && EditBen.id && (
                    <EditBeneficiaryPopup
                        data={EditBen}
                        result={editPopup}
                        setResult={setEditPopup}
                        handleUpdate={props.handleUpdateBenData}
                    />
                )}
            </TableRow>
        </React.Fragment>
    );
}

