import { createSlice } from "@reduxjs/toolkit";
localStorage.setItem('langOptions', JSON.stringify([{
    id: 0,
    languageName: 'auto',
    languageCode: 'auto',
    languageIsocode: 'auto'
}]));
const language = JSON.parse(localStorage.getItem('langOptions') || "");
const initialState = {
    addedLanguages: [
        ...language
    ],
};

const LanguageSlice = createSlice({
    name: "language",
    initialState,
    reducers: {
        setAddedLanguages: (state, { payload }) => {
            state.addedLanguages = payload;
        }
    }
});

export const { setAddedLanguages } = LanguageSlice.actions;
export default LanguageSlice.reducer;
