import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    InputBaseComponentProps,
    TextField,
    Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { ManageAccountValidationSchema } from '../../validations/ManageAccountValidation';
import CloseIcon from '@mui/icons-material/Close';
import ApiUtils from '../../api/ApiUtils';
import { BASE_IMG_URL } from '../../config/Constants';
import Avatars from '../../assets/images/avatars.png';
import Avatar2 from '../../assets/images/avatar2.76aea921824e609d81bbe7ebc59b11a5.png';
import Avatar3 from '../../assets/images/avatar3.73ab20fc1efc49594ad0b8a1b4715e02.png';
import Avatar4 from '../../assets/images/avatar4.b8dd5f61765d636ae42361fe1961c001.png';
import Avatar5 from '../../assets/images/avatar5.63115c3678244d98cfad7ea8d9be8c7b.png';
import Avatar6 from '../../assets/images/avatar6.7cb6b47dd062c4cfecf293749b7cecb0.png';
import Avatar7 from '../../assets/images/avatar7.b179f9a2b741ef294cedf77bd0fac4b2.png';
import Avatar8 from '../../assets/images/avatar8.4dc0db45fbd8fbb50351bd073c9952ec.png';
import Avatar9 from '../../assets/images/avatar9.b8d63a80923c4ceaf546cca61a764a17.png';
import Avatar10 from '../../assets/images/avatar10.590b38a4762f555595d9b2ccb2380fe7.png';
import Avatar11 from '../../assets/images/avatar11.598ac38f6a40baed8283b2f741626367.png';
import Avatar12 from '../../assets/images/avatar12.6a53c6b87e2569c3a47c5abc6618bb55.png';
import Avatar13 from '../../assets/images/avatar13.e0848e909d5e33d92b4ca9507076e2bc.png';
import Avatar14 from '../../assets/images/avatar14.4653d915e299689f9acf1cb9e6616d6c.png';
import Avatar15 from '../../assets/images/avatar15.5aa111c99a5adc0af1855126e071a1a3.png';
import Avatar16 from '../../assets/images/avatar16.3400e09d4864b06131b436507e9dddaa.png';
import Avatar17 from '../../assets/images/avatar17.a6aa63eade64be6993738a1cb64c7ed4.png';
import Avatar18 from '../../assets/images/avatar18.5d53c0f88abaef9f11497b0aad8e8a58.png';

import CustomToast from '../Common/CustomToast';
import Footer from '../CommonLayout/Footer';
import MuiPhoneNumber from 'material-ui-phone-number';
import { FetchCurrentUserContext } from '../CommonLayout/context';

interface User {
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
}

function ManageAccount() {
    const avatarSources = [
        Avatars,
        Avatar2,
        Avatar3,
        Avatar4,
        Avatar5,
        Avatar6,
        Avatar7,
        Avatar8,
        Avatar9,
        Avatar10,
        Avatar11,
        Avatar12,
        Avatar13,
        Avatar14,
        Avatar15,
        Avatar16,
        Avatar17,
        Avatar18,
    ];
    const navigate = useNavigate();
    const [openPopup, setOpenPopup] = React.useState(false);
    const [openAvatar, setOpenAvatar] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState('avatar');

    const handleOpenPopup = () => {
        setOpenPopup(true);
    };
    const handleClosePopup = () => {
        setOpenPopup(false);
    };
    const handleClosedPopup = () => {
        setOpenAvatar(false);
    };
    const handlePageChange = (page: string) => {
        setCurrentPage(page);
    };
    const [selectedAvatar, setSelectedAvatar] = React.useState<number | null>(null);
    const handleAvatarClick = async (index: number) => {
        const imageUrl = avatarSources[index];
        try {
            // Fetch the image as a Blob
            const response = await fetch(imageUrl);
            const blob = await response.blob();

            // Convert the Blob to Base64
            const base64String = await new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => resolve(reader.result as string);
                reader.onerror = (error) => reject(error);
            });

            // Update state with selected avatar and Base64 representation
            setSelectedAvatar(index);
            setBenPhoto(base64String);
        } catch (error) {
            console.error('Error fetching or converting the image:', error);
        }
    };
    const [currentUser, setCurrentUser] = useState<any | null>(null);

    useEffect(() => {
        (async () => {
            const data = await ApiUtils.getCurrentUser();
            setCurrentUser(data);
        })();
    }, []);

    useEffect(() => {
        if (currentUser) {
            formik.setValues({
                firstName: currentUser.firstName || '',
                lastName: currentUser.lastName || '',
                email: currentUser.emailId || '',
                mobile: currentUser.mobile || '',
            });
        }
    }, [currentUser]);

    const [BenPhoto, setBenPhoto] = useState<any>(null);
    const fetchUser = useContext(FetchCurrentUserContext);
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
        },
        validationSchema: ManageAccountValidationSchema,
        onSubmit: async (values) => {
            try {
                const data = await ApiUtils.UpdateUser(values);
                // Add logic to handle image upload/capture
                if (BenPhoto) {
                    const photoData = await ApiUtils.addUserPhoto({
                        file: BenPhoto,
                    });
                }
                CustomToast({
                    message: 'User updated successfully!',
                    severity: 'success',
                });
                if (fetchUser) {
                    try {
                        await fetchUser();
                    } catch (e) {
                        console.log(e);
                    }
                }
                setTimeout(() => {
                    navigate('/account-settings');
                }, 1000);
            } catch (error) {
                CustomToast({
                    message: 'Error adding user. Please try again.',
                    severity: 'error',
                });
                console.error('Error adding user:', error);
            }
        },
    });

    return (
        <>
            <Box className="primary-box" sx={{ maxWidth: '30rem', mx: 'auto' }}>
                <Typography
                    className="manage-account"
                    mb={3}
                    variant="h5"
                    sx={{ fontWeight: 700, fontSize: '25px' }}
                >
                    Manage Account
                </Typography>
                <Box component="form" noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                    <TextField
                        sx={{ mb: 2 }}
                        label="First Name"
                        fullWidth
                        required
                        autoFocus
                        name="firstName"
                        value={formik.values.firstName}
                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                        helperText={formik.touched.firstName && formik.errors.firstName}
                        onChange={formik.handleChange}
                    />
                    <TextField
                        sx={{ mb: 2 }}
                        label="Last Name"
                        fullWidth
                        required
                        name="lastName"
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                        helperText={formik.touched.lastName && formik.errors.lastName}
                    />

                    <TextField
                        sx={{ mb: 2 }}
                        fullWidth
                        required
                        name="mobile"
                        label="Contact Number"
                        value={formik.values.mobile}
                        error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                        helperText={formik.touched.mobile && formik.errors.mobile}
                        InputProps={{
                            // inputComponent: (props) => <MuiPhoneNumber {...props} style={{ borderBottom: 'none' }} />,
                            inputComponent:
                                MuiPhoneNumber as React.ComponentType<InputBaseComponentProps>, // Cast MuiPhoneNumber to React.ComponentType<InputBaseComponentProps>
                            inputProps: {
                                defaultCountry: 'ae',
                                onChange: (value) => {
                                    formik.setFieldValue('mobile', value);
                                },
                            },
                        }}
                    />
                    <TextField
                        sx={{ mb: 2 }}
                        label="Email Address"
                        fullWidth
                        required
                        name="email"
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                    />

                    <Box
                        className="picture_wrapper"
                        sx={{
                            display: 'flex',
                            gap: '20px',
                            // justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            className="picture"
                            component="div"
                            sx={{
                                display: 'flex',
                                gap: '10px',
                                fontSize: '14px',
                                alignItems: 'center',
                                borderRadius: '20px',
                                border: '2px solid rgba(25, 25, 25, 0.5)',
                                padding: '6px 20px',
                                cursor: 'pointer',
                                fontWeight: '500',
                                height: '36px',
                                '&:hover': {
                                    borderColor: 'black',
                                },
                            }}
                            onClick={handleOpenPopup}
                        >
                            {BenPhoto || currentUser?.photo ? (
                                <>
                                    <img
                                        src={BenPhoto || BASE_IMG_URL + currentUser?.photo}
                                        alt="selected-avatar"
                                        style={{ width: '20px', height: '20px' }}
                                    />
                                    <span>Picture Selected</span>
                                </>
                            ) : (
                                <>
                                    {/* <img
                                        src={BenPhoto || BASE_IMG_URL + currentUser?.photo}
                                        alt="picture"
                                        style={{ width: '20px', height: '20px' }}
                                    /> */}
                                    <span>Select Picture</span>
                                </>
                            )}
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            gap: '8px',
                            justifyContent: 'end',
                            marginTop: '10px',
                        }}
                    >
                        <Button
                            sx={{
                                backgroundColor: '#000000',
                                color: '#FFFF',
                                padding: '6px 12px',
                                '&:hover': {
                                    backgroundColor: '#000000',
                                    opacity: '0.6',
                                },
                            }}
                            type="submit"
                        >
                            Update
                        </Button>
                        <Button
                            sx={{ backgroundColor: '#D3D3D3' }}
                            onClick={() => {
                                navigate('/account-settings');
                            }}
                        >
                            Back
                        </Button>
                    </Box>
                </Box>
            </Box>

            <Dialog open={openPopup} onClose={handleClosedPopup} maxWidth="md" fullWidth={true}>
                <DialogTitle sx={{ padding: '5px 0px 0px 0px' }}>
                    <Box
                        className="avatar_responsive"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: '30%',
                            alignItems: 'center',
                            transition: '0.3s ease-in',
                        }}
                    >
                        <Box
                            className="avatar_wrapper"
                            onClick={() => handlePageChange('avatar')}
                            sx={{
                                fontSize: '18px',
                                fontWeight: '600',
                                cursor: 'pointer',
                                color: currentPage === 'avatar' ? 'black' : '#666666',
                                transition: 'text-decoration 0.3s ease',
                                borderBottom: currentPage === 'avatar' ? '2px solid black' : 'none',
                                padding: '12px 10px',
                            }}
                        >
                            Avatar
                        </Box>
                        {/* <Box
                            className="avatar_wrapper"
                            onClick={() => handlePageChange('upload')}
                            sx={{
                                fontSize: '18px',
                                fontWeight: '600',
                                display: 'flex',
                                cursor: 'pointer',
                                color: currentPage === 'upload' ? 'black' : '#666666',
                                transition: 'text-decoration 0.3s ease',
                                borderBottom: currentPage === 'upload' ? '2px solid black' : 'none',
                                padding: '12px 10px',
                            }}
                        >
                            Upload
                        </Box> */}
                    </Box>
                    <Box
                        className="close_svg"
                        sx={{
                            position: 'absolute',
                            right: '30px',
                            top: '20px',
                        }}
                    >
                        <CloseIcon onClick={handleClosePopup} sx={{ cursor: 'pointer' }} />
                    </Box>
                </DialogTitle>
                <Divider sx={{}} />

                <DialogContent>
                    {currentPage === 'avatar' && (
                        <Box sx={{ margin: '0 60px' }}>
                            <Box
                                className="avatar"
                                sx={{
                                    display: 'flex',
                                    gap: '46px',
                                    marginBottom: '24px',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                }}
                            >
                                {/* {base64Avatar && (
    <img
        src={base64Avatar}
        alt={`selected-avatar`}
        style={{
            width: '100px', // Adjust the width as needed
            height: '100px', // Adjust the height as needed
        }}
    />
)} */}
                                {avatarSources.map((avatarSrc, index) => (
                                    <img
                                        width={70}
                                        key={index}
                                        src={avatarSrc}
                                        alt={`avatar${index + 1}`}
                                        onClick={() => handleAvatarClick(index)}
                                        style={{
                                            borderWidth: '2px',
                                            borderStyle: 'solid',
                                            borderColor:
                                                selectedAvatar === index ? '#000' : '#E8E8E8',
                                            cursor: 'pointer',
                                            borderRadius: '20px',
                                            transition: 'text-decoration 0.2s ease',
                                        }}
                                        className="avatar_img"
                                    />
                                ))}
                            </Box>
                            <Box
                                onClick={handleClosePopup}
                                sx={{
                                    color: 'white',
                                    backgroundColor: 'black',
                                    width: 'auto',
                                    padding: '10px 20px',
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    cursor: 'pointer',
                                    maxWidth: 'max-content',
                                    marginLeft: 'auto',
                                    borderRadius: '20px',
                                    marginTop: '24px',
                                    '&:hover': {
                                        opacity: '0.6',
                                    },
                                }}
                            >
                                Select
                            </Box>
                        </Box>
                    )}
                    {/* {currentPage === 'upload' && (
                        <DropFileInput
                            onFileChange={(files: any) => {
                                setBenPhoto(files);
                                handleClosePopup();
                            }}
                            onClick={handleClosePopup}
                        />
                    )} */}
                </DialogContent>
            </Dialog>
            <Footer />
        </>
    );
}

export default ManageAccount;

