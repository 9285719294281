import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { mainApi } from './Slices/mainApiSlice';
import LanguageReducer from './Slices/ui/uiSlice';

export const store = configureStore({
    reducer: {
        [mainApi.reducerPath]: mainApi.reducer,
        Language: LanguageReducer,
    },

    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(mainApi.middleware),
});

setupListeners(store.dispatch);

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
