import * as yup from 'yup';

const CreateBenValidationSchema = yup.object({
    beneficiaryName: yup.string().required('Beneficiary Name is required'),
    nickName: yup.string().required('Nick Name is required'),
    publicAddress: yup.string().required('Public Address is required'),
});

export { CreateBenValidationSchema };

