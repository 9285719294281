import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { commonProtectedRoutes, publicRoutes } from './index';
import CommonLayout from '../components/CommonLayout';
import Error404 from '../components/Error/Error404';

function AppRoute() {
    // const token = sessionStorage.getItem('__COI_TOKEN__');
    return (
        <React.Fragment>
            <Routes>
                <Route
                    path="/"
                    element={
                        // !isAuthProtected ? (
                        //     <Navigate to="/login" replace />
                        // ) : (
                        <Navigate to="/assets" replace />
                        // )
                    }
                />
                {publicRoutes.map((route: any, idx: any) => {
                    const id = idx * Math.floor(Math.random() * 10);
                    return (
                        <Route
                            key={id}
                            path={route.path}
                            element={
                                // !isAuthProtected ? (
                                //     <Navigate to="/login" replace state={{ from: route.path }} />
                                // ) : (
                                <route.component />
                                // )
                            }
                        />
                    );
                })}
                {commonProtectedRoutes.map((route, idx) => {
                    const id = idx * Math.floor(Math.random() * 10);
                    return (
                        <Route
                            key={id}
                            path={route.path}
                            element={
                                // !token ? (
                                //     <>
                                //         <Navigate
                                //             to="/login"
                                //             replace
                                //             state={{ from: route.path }}
                                //         />
                                //     </>
                                // ) : (
                                <>
                                    <CommonLayout>
                                        <route.component />
                                    </CommonLayout>
                                </>
                                // )
                            }
                        />
                    );
                })}
                <Route path="*" element={<Error404 />} />
            </Routes>
        </React.Fragment>
    );
}

export default AppRoute;

