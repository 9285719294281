import { Config, useWriteContract, useSendTransaction, version } from 'wagmi';
import CKFundSol from '../config/abi/CKFund.json';
import CKFund20Sol from '../config/abi/CKFund20.json';
import CKFund1155Sol from '../config/abi/CKFund1155.json';
import { Address, parseEther } from 'viem';
import {
    WriteContractMutate,
    WriteContractMutateAsync,
    SendTransactionMutate,
    SendTransactionMutateAsync,
} from 'wagmi/query';

export const CKFundBase = {
    addBeneficiary: (params: {
        fundAddress: Address,
        newBeneficiaryAddress: Address,
        beneficiariesAndShares: { address: Address, share: number }[],
    }) => {
        const { fundAddress, newBeneficiaryAddress, beneficiariesAndShares } = params;

        return {
            abi: CKFundSol.abi,
            functionName: 'addBeneficiary',
            address: fundAddress,
            args: [newBeneficiaryAddress, beneficiariesAndShares.map(({ share }) => share)],
        };
    },

    removeBeneficiary: (params: {
        fundAddress: Address,
        beneficiaryAddress: Address,
        beneficiariesAndShares: { address: Address, share: number }[],
    }) => {
        const { fundAddress, beneficiaryAddress, beneficiariesAndShares } = params;

        return {
            abi: CKFundSol.abi,
            functionName: 'removeBeneficiary',
            address: fundAddress,
            args: [beneficiaryAddress, beneficiariesAndShares.map(({ share }) => share)],
        };
    },

    updateBeneficiaries: (params: {
        fundAddress: Address,
        beneficiariesAndShares: { address: Address, share: number }[],
    }) => {
        const { fundAddress, beneficiariesAndShares } = params;

        return {
            abi: CKFundSol.abi,
            functionName: 'updateBeneficiaries',
            address: fundAddress,
            args: [
                beneficiariesAndShares.map(({ address }) => address),
                beneficiariesAndShares.map(({ share }) => share),
            ],
        };
    },

    updateBeneficiary: (params: {
        fundAddress: Address,
        beneficiaryAddress: Address,
        newShare: number,
    }) => {
        const { fundAddress, beneficiaryAddress, newShare } = params;

        return {
            abi: CKFundSol.abi,
            functionName: 'updateBeneficiary',
            address: fundAddress,
            args: [beneficiaryAddress, newShare],
        };
    },
};

export const CKFund = {
    deposit: (params: { fundAddress: Address, amount: number }) => {
        const { fundAddress, amount } = params;

        return {
            abi: CKFundSol.abi,
            functionName: 'deposit',
            address: fundAddress,
            value: parseEther(amount.toString()),
        };
    },

    withdraw: (params: { fundAddress: Address, amount: number }) => {
        const { fundAddress, amount } = params;

        return {
            abi: CKFundSol.abi,
            functionName: 'withdraw',
            address: fundAddress,
            args: [parseEther(amount.toString())],
        };
    },
};

export const sharesToPerc = (
    beneficiaries: { address: Address, share: number }[]
): { address: Address, share: number }[] => {
    // transform from free numbers to percentages

    const totalShares = beneficiaries.reduce((acc, { share }) => acc + share, 0);
    const shares = beneficiaries.map(({ address, share }) => {
        return { address, share: Math.floor((share / totalShares) * 100) };
    });

    // if there is a remainder, add it to the beneficiary with the least shares
    const remainder = 100 - shares.reduce((acc, { share }) => acc + share, 0);
    if (remainder > 0) {
        shares.sort((a, b) => a.share - b.share);
        shares[0].share += remainder;
    }

    // reorder as original
    shares.sort(
        (a, b) =>
            beneficiaries.findIndex(({ address }) => address === a.address) -
            beneficiaries.findIndex(({ address }) => address === b.address)
    );

    return shares;
};

export const percToShares = (
    beneficiaries: { address: Address, share: number }[]
): { address: Address, share: number }[] => {
    // transform from percentages to integers that are reduced
    // ex: 50, 50 -> 1, 1
    // ex: 33, 33, 33 -> 1, 1, 1
    // use largest common divisor to reduce
    const gcd = (a: number, b: number): number => {
        if (b === 0) return a;
        return gcd(b, a % b);
    };

    const shares = beneficiaries.map(({ address, share }) => {
        return { address, share: share };
    });
    const sharesArr = shares.map(({ share }) => share);
    const divisor = sharesArr.reduce(gcd);

    return shares.map(({ address, share }) => {
        return { address, share: share / divisor };
    });
};

