import axios from 'axios';
import { BASE_URL } from '../../../config/Constants';
import { mainApi } from '../mainApiSlice';
import api from '../../../api/Index';

const GetLabels = async (id: number) => {
    return await api.get(`${BASE_URL}/api/Label/GetLabelList?categoryID=${id}`);
};
const GetBenWalletTokensByWalletID = async (id: number) => {
    return await api.get(
        `${BASE_URL}/api/BeneficiaryAsset/GetBeneficiaryAssetList?walletTokenID=${id}`
    );
};
const GetWalletTokensByBeneficiaryID = async ({ beneficiaryID, walletID }: any) => {
    return await api.get(
        `${BASE_URL}/api/BeneficiaryAsset/GetBeneficiaryAssetList?beneficiaryID=${beneficiaryID}&walletID=${walletID}`
    );
};

const GetAllBeneficiaries = async ({ fromValue, toValue, label }: any) => {
    let url = `${BASE_URL}/api/Beneficiary/SearchBeneficiaryList`;
    if (fromValue) {
        url += `&fromValue=${fromValue}`;
    }
    if (toValue) {
        url += `&toValue=${toValue}`;
    }
    if (label) {
        url += `&labelName=${label}`;
    }
    return await api.get(url);
};
const GetWalletBeneficiaries = async ({ walletID, fromValue, toValue, label }: any) => {
    let url = `${BASE_URL}/api/Beneficiary/GetWalletBeneficiaryList?walletID=${walletID}`;
    if (fromValue) {
        url += `&fromValue=${fromValue}`;
    }
    if (toValue) {
        url += `&toValue=${toValue}`;
    }
    if (label) {
        url += `&labelName=${label}`;
    }
    return await api.get(url);
};

const GetWalletTokens = async ({ walletID, fromValue, toValue, label }: any) => {
    let url = `${BASE_URL}/api/Wallet/GetWalletTokens?walletID=${walletID}`;
    if (fromValue) {
        url += `&fromValue=${fromValue}`;
    }
    if (toValue) {
        url += `&toValue=${toValue}`;
    }
    if (label) {
        url += `&labelName=${label}`;
    }
    return await api.get(url);
};

const WalletAssetsAPI = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        AddLabelList: builder.mutation({
            query: (data: any) => ({
                url: `${BASE_URL}/api/Label/AddLabel`,
                method: 'POST',
                body: data,
            }),
        }),
        AddWalletAsset: builder.mutation({
            query: (data: any) => ({
                url: `${BASE_URL}/api/Wallet/AddWalletToken`,
                method: 'POST',
                body: data,
            }),
        }),
        UpdateWalletAsset: builder.mutation({
            query: (data: any) => ({
                url: `${BASE_URL}/api/Wallet/UpdateTokenWallet`,
                method: 'POST',
                body: data,
            }),
        }),
        UpdateBeneficiaryAssetList: builder.mutation({
            query: (data: any) => ({
                url: `${BASE_URL}/api/BeneficiaryAsset/UpdateBeneficiaryAssetList`,
                method: 'POST',
                body: data,
            }),
        }),
        GetAllBeneficiaries: builder.query({
            query: ({ fromValue, toValue, label }) => {
                let url = `${BASE_URL}/api/Beneficiary/SearchBeneficiaryList?`;
                if (fromValue) {
                    url += `&fromValue=${fromValue}`;
                }
                if (toValue) {
                    url += `&toValue=${toValue}`;
                }
                if (label) {
                    url += `labelName=${label}`;
                }
                return {
                    url: url,
                    method: 'GET',
                };
            },
        }),
        GetWalletBeneficiaries: builder.query({
            query: ({ walletID, fromValue, toValue, label }) => {
                let url = `${BASE_URL}/api/Beneficiary/SearchWalletBeneficiaryList`;
                if (fromValue) {
                    url += `&fromValue=${fromValue}`;
                }
                if (toValue) {
                    url += `&toValue=${toValue}`;
                }
                if (label) {
                    url += `&labelName=${label}`;
                }
                return {
                    url: url,
                    method: 'GET',
                };
            },
        }),
        GetLabelList: builder.query({
            query: (categoryID: number) => ({
                url: `${BASE_URL}/api/Label/GetLabelList?categoryID=${categoryID}`,
                method: 'GET',
            }),
        }),
        GetBenWalletTokens: builder.query({
            query: (walletTokenID: number) => ({
                url: `${BASE_URL}/api/BeneficiaryAsset/GetBeneficiaryAssetList?walletTokenID=${walletTokenID}`,
                method: 'GET',
            }),
        }),
        GetWalletTokens: builder.query({
            query: ({ walletID, fromValue, toValue, label }) => {
                let url = `${BASE_URL}/api/Wallet/GetWalletTokens?walletID=${walletID}`;
                if (fromValue) {
                    url += `&fromValue=${fromValue}`;
                }
                if (toValue) {
                    url += `&toValue=${toValue}`;
                }
                if (label) {
                    url += `&labelName=${label}`;
                }
                return {
                    url: url,
                    method: 'GET',
                };
            },
        }),
        GetWalletTokensByBeneficiaryID: builder.query({
            query: ({ beneficiaryID, walletID }) => ({
                url: `${BASE_URL}/api/BeneficiaryAsset/GetBeneficiaryWalletAssetList?beneficiaryID=${beneficiaryID}&walletID=${walletID}`,
                method: 'GET',
            }),
        }),
    }),
});

export {
    GetLabels,
    GetBenWalletTokensByWalletID,
    GetWalletTokensByBeneficiaryID,
    GetAllBeneficiaries,
    GetWalletBeneficiaries,
    GetWalletTokens,
};
export const {
    useGetWalletTokensQuery,
    useLazyGetWalletTokensQuery,
    useLazyGetWalletBeneficiariesQuery,
    useLazyGetAllBeneficiariesQuery,
    useAddLabelListMutation,
    useAddWalletAssetMutation,
    useUpdateWalletAssetMutation,
    useUpdateBeneficiaryAssetListMutation,
    useLazyGetLabelListQuery,
    useLazyGetBenWalletTokensQuery,
    useLazyGetWalletTokensByBeneficiaryIDQuery,
} = WalletAssetsAPI;

