import { createConfig, http} from 'wagmi';
import { defineChain } from 'viem'
import { mainnet, sepolia, localhost} from 'wagmi/chains';
import { injected } from 'wagmi/connectors';


export const hardhat = defineChain({
    id: 31337,
    name: 'Hadhat Local',
    nativeCurrency: {
      decimals: 18,
      name: 'HardhatEther',
      symbol: 'HardhatETH',
    },
    rpcUrls: {
      default: { http: ['http://127.0.0.1:8545'] },
    },
  });

export const wagmiConfig = createConfig({
    chains: [mainnet, sepolia, hardhat],
    transports: {
        [mainnet.id]: http(),
        [sepolia.id]: http(),
        [hardhat.id]: http(),
    },
    // connectors: [ injected({ target: 'metaMask' })],
});

