import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { logo } from '../../config/Images';
import { Stack, useMediaQuery } from '@mui/material';
import { useFormik } from 'formik';
import { ForgotPasswordValidationSchema } from '../../validations/ForgotPassValidation';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import ApiUtils from '../../api/ApiUtils';
import CustomToast from '../Common/CustomToast';

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            Copyright © {new Date().getFullYear()} Coin of Kin. All rights reserved.
        </Typography>
    );
}

export default function ForgetPassword() {
    const navigate = useNavigate();
    const matches = useMediaQuery('(max-width:500px)');
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: ForgotPasswordValidationSchema,
        onSubmit: async (values) => {
            try {
                const userLogin = await ApiUtils.forgotPassword({
                    email: values.email,
                });
                if (userLogin) {
                    CustomToast({
                        message: 'Check your mailbox for reset password',
                        severity: 'success',
                    });
                    setTimeout(() => {
                        window.location.href = '/login';
                    }, 3000);
                } else {
                    CustomToast({
                        message: 'Something went wrong.',
                        severity: 'error',
                    });
                    setTimeout(() => {
                        window.location.href = '/login';
                    }, 3000);
                }
            } catch (error) {
                CustomToast({
                    message: 'Something went wrong.',
                    severity: 'error',
                });
            }
        },
    });

    return (
        <Stack className="container">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: matches ? 0 : 6,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <img
                    className="logo"
                    style={{
                        width: '250px',
                        // width: matches ? '60%' : '15%',
                        marginRight: '50px',
                        cursor: 'pointer',
                    }}
                    src={logo}
                    alt="logo"
                />
                <Typography component="h1" variant="h5">
                    Forgot Password
                </Typography>
                <Box
                    component="form"
                    noValidate
                    onSubmit={formik.handleSubmit}
                    sx={{ mt: '1rem', width: '40%' }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{
                            height: '45px',
                            mt: matches ? 0 : 3,
                            mb: 2,
                        }}
                    >
                        Submit
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link href="/signup" variant="body2">
                                Don't have an account? Sign up
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 5 }} />
        </Stack>
    );
}

