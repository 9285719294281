import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, useMediaQuery } from '@mui/material';
import Footer from '../CommonLayout/Footer';

function Help() {
    const matches = useMediaQuery('(min-width:800px)');
    const accordionData = [
        {
            id: 1,
            title: 'How does the subscription work?',
            content:
                'Users can subscribe to “Coin of Kin” and have access to the services within the application by paying a monthly or yearly fee. Upon subscription, users gain access to several tiers/subscription the services as long as the subscription remains active.',
        },
        {
            id: 2,
            title: 'What benefits do subscribers receive?',
            content:
                'The subscriber will be able to have access to smart contract benefits linking available funds to any beneficiary of their own choice.',
        },
        {
            id: 3,
            title: 'How to subscribe?',
            content:
                'First, add beneficiary, click on choose plan, and identify a plan that suits best.',
        },
        {
            id: 4,
            title: 'Can the user cancel the subscription?',
            content: 'Yes, subscription can be cancelled anytime. Please check T&C.',
        },
        {
            id: 5,
            title: 'Will the user get a full refund?',
            content:
                'The refund amount can vary depending on subscription and tiers. Mainly the service will stop at the end of billing cycle of the month cancellation occurred. T&C applied.',
        },
        {
            id: 6,
            title: 'Can the user change his subscription?',
            content:
                'Yes, the subscription will be automatically renewed/changed at the end of the billing cycle. T&C applied.',
        },
    ];

    const [expanded, setExpanded] = React.useState<string | false>('panel1');

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <>
            <Paper
                sx={{
                    backgroundColor: '#fff',
                    boxShadow: 'rgba(153, 153, 153, 0.12) 0px 6px 20px 0px',
                    borderRadius: '16px',
                    padding: matches ? '40px' : '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: matches ? '65vh' : '100vh',
                    position: 'relative',
                }}
            >
                <Box
                    sx={{
                        fontWeight: 600,
                        fontSize: '21px',
                        marginBottom: '24px',
                    }}
                >
                    Need Some Help ?
                </Box>
                <Box sx={{ width: matches ? '70%' : '100%', position: 'absolute' }}>
                    {accordionData.map((item) => (
                        <Accordion
                            key={item.id}
                            expanded={expanded === `panel${item.id}`}
                            onChange={handleChange(`panel${item.id}`)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${item.id}-content`}
                                id={`panel${item.id}-header`}
                            >
                                <Typography sx={{ fontWeight: '600' }}>
                                    {item.id}. {item.title}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography sx={{ marginLeft: '1.5rem', marginRight: '1.5rem' }}>
                                    {item.content}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            </Paper>
            <Footer />
        </>
    );
}

export default Help;

