import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Menu,
    MenuItem,
    Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import {
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { beneficiariesIcon, assetsIcon, SelectArrow } from '../../config/Images';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import ToggleSwitch from '../Common/ToggleSwitch';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import ApiUtils from '../../api/ApiUtils';
import toast from 'react-hot-toast';
import CustomToast from './CustomToast';

const pages = [
    { name: 'Assets', path: '/assets', icon: <img src={assetsIcon} alt="assetsIcon"></img> },
    {
        name: 'Beneficiaries',
        path: '/beneficiaries',
        icon: <img src={beneficiariesIcon} alt="assetsIcon"></img>,
    },
];

export default function HeaderDrawer(props: any) {
    const navigate = useNavigate();
    const drawerWidth = 340;
    const [openDate, setOpenDate] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const datePickerRef = useRef<HTMLDivElement | null>(null);

    const handleClickOutside = (event: any) => {
        if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
            // Clicked outside of the DatePicker, close it
            setOpenDate(false);
        }
    };
    useEffect(() => {
        // Add event listener when component mounts
        document.addEventListener('click', handleClickOutside);

        // Remove event listener when component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <>
            <Drawer
                // variant="permanent"
                open={props.openToggle}
                onClose={props.handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: {
                        xs: 'block',
                        sm: 'block',
                        md: 'block',
                        lg: 'none',
                        xl: 'none',
                    },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: drawerWidth,
                    },
                }}
            >
                <Box
                    sx={{
                        textAlign: 'center',
                        height: '100vh',
                        justifyContent: 'space-between',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box>
                        <Typography variant="h6" sx={{ my: 2, px: 2, textAlign: 'left' }}>
                            Navigation
                        </Typography>
                        <Divider />
                        <List>
                            {pages.map((item: any, index: any) => (
                                <ListItem key={item.path} disablePadding>
                                    <ListItemButton
                                        onClick={() => {
                                            navigate(item.path);
                                            // props.handleCloseNavMenu();
                                            props.handleDrawerToggle();
                                        }}
                                        sx={{ textAlign: 'left' }}
                                    >
                                        <ListItemIcon sx={{ minWidth: '35px' }}>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.name} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                    <Box
                        sx={{ display: 'flex', px: 2, pb: 2, flexDirection: 'column', gap: '16px' }}
                    >
                        {props.selectedWallet && !props.selectedWallet.submitContract && (
                            <Button
                                sx={{
                                    textTransform: 'capitalize',
                                    flexGrow: 0,
                                    fontSize: '14px',
                                    padding: '6px 20px',
                                    borderRadius: '20px',
                                    backgroundColor: '#191919',
                                    border: '2px solid rgba(25, 25, 25, 0.5)',
                                    color: '#fff',
                                    transition: 'border-color 0.3s ease',
                                    '&:hover': {
                                        backgroundColor: '#191919',
                                        border: '2px solid rgba(25, 25, 25, 0.5)',
                                    },
                                }}
                                onClick={() => {
                                    props.setConfirm(true);
                                    props.handleDrawerToggle();
                                }}
                                className="submit_contract"
                            >
                                Submit Contract
                            </Button>
                        )}

                        {props.selectedWallet && props.selectedWallet !== '' && (
                            <Box
                                sx={{
                                    flexGrow: 0,
                                    display: 'flex',
                                    height: '25px',
                                    padding: '6px 20px',
                                    borderRadius: '20px',
                                    backgroundColor: 'transparent',
                                    border: '2px solid rgba(78, 193, 123, 0.5)',
                                    cursor: 'pointer',
                                    color: 'black',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    fontWeight: '500',
                                    gap: '8px',
                                    '&:hover': {
                                        backgroundColor: 'rgb(78 193 123 / 2%)',
                                        border: '2px solid #4ec17b',
                                    },
                                }}
                                // onClick={props.handleOpenMenu}
                            >
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                        lineHeight: 'unset',
                                    }}
                                    // onClick={() => {
                                    //     props.handleDrawerToggle();
                                    // }}
                                >
                                    <Box
                                        style={{
                                            height: '8px',
                                            width: '8px',
                                            borderRadius: '50%',
                                            backgroundColor: 'rgb(78, 193, 123)',
                                        }}
                                    ></Box>
                                    {props.selectedWallet.cryptoAddress.slice(0, 6) +
                                        '...' +
                                        props.selectedWallet.cryptoAddress.slice(-5)}
                                </Typography>
                                {/* <Box>
                                    <img
                                        src={SelectArrow}
                                        alt="SelectArrow"
                                        width={'14px'}
                                        height={'14px'}
                                    ></img>
                                </Box> */}
                            </Box>
                        )}

                        {props.userWallet && props.userWallet.length && (
                            <Menu
                                id="basic-menu"
                                anchorEl={props.anchorEl}
                                open={props.open}
                                onClose={props.handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                sx={{ overflowX: 'auto' }}
                            >
                                {props.userWallet &&
                                    props.userWallet.map((wallet: any, index: any) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                paddingRight: '10px !important',
                                            }}
                                        >
                                            <MenuItem
                                                sx={{ paddingTop: 1, paddingBottom: 1 }}
                                                onClick={async () => {
                                                    props.setAnchorEl(null);
                                                    props.setSelectedWallet(wallet);
                                                    // if (wallet.executionTransferStartDate) {
                                                    //     props.setSelectedDate({
                                                    //         startDate: new Date(
                                                    //             wallet.executionTransferStartDate
                                                    //         ),
                                                    //         endDate: new Date(
                                                    //             wallet.executionTransferEndDate
                                                    //         ),
                                                    //     });
                                                    // } else {
                                                    //     props.setSelectedDate({
                                                    //         startDate: '',
                                                    //         endDate: '',
                                                    //     });
                                                    // }
                                                    props.handleDrawerToggle();
                                                    props.handleClose();
                                                }}
                                            >
                                                {wallet.cryptoAddress}
                                            </MenuItem>
                                            <IconButton
                                                onClick={() => {
                                                    setConfirm(true);
                                                }}
                                            >
                                                <DeleteTwoToneIcon
                                                    sx={{ width: '20px', height: '20px' }}
                                                />
                                            </IconButton>
                                        </Box>
                                    ))}
                                <MenuItem>
                                    <Box
                                        onClick={() => {
                                            props.setConfirmConnect(true);
                                            props.handleDrawerToggle();
                                            props.handleClose();
                                        }}
                                        sx={{
                                            padding: '5px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Connect Wallet
                                    </Box>
                                </MenuItem>
                            </Menu>
                        )}
                        {props.selectedWallet && props.selectedWallet.id && (
                            <Box ref={datePickerRef} sx={{ width: '100%' }}>
                                <DatePicker
                                    wrapperClassName="custom-date-picker"
                                    onInputClick={() => setOpenDate((value: any) => !value)}
                                    minDate={props.addDays(new Date(), 1)}
                                    maxDate={props.maxDate()}
                                    // value={addDays(dateValue, 4)}
                                    selected={props.selectedDate}
                                    popperPlacement="top-end"
                                    disabledKeyboardNavigation
                                    monthsShown={1}
                                    onChange={async (value) => {
                                        if (value) {
                                            props.setSelectedDate(value);
                                        }
                                    }}
                                    open={openDate}
                                    // inline
                                    customInput={
                                        <Button
                                            sx={{
                                                width: '100%',
                                                textTransform: 'capitalize',
                                                flexGrow: 0,
                                                fontSize: '14px',
                                                padding: '6px 100px',
                                                borderRadius: '30px',
                                                fontWeight: '500',
                                                backgroundColor: 'rgb(211,211,211)',
                                                // border: '2px solid rgba(25, 25, 25, 0.5)',
                                                color: 'black',
                                                transition: 'background-color 0.3s ease',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(25, 25, 25, 0.02)',
                                                },
                                            }}
                                        >
                                            {props.selectedWallet?.executionTransferStartDate
                                                ? `${props.formatDate(
                                                      new Date(
                                                          props.selectedWallet?.executionTransferStartDate
                                                      )
                                                  )}`
                                                : 'Execution\u00A0Date'}
                                        </Button>
                                    }
                                >
                                    <Box>
                                        <Box
                                            sx={{
                                                marginLeft: '15px',
                                                marginRight: '15px',
                                            }}
                                        >
                                            {(props.selectedDate ||
                                                props.selectedWallet
                                                    ?.executionTransferStartDate) && (
                                                <>
                                                    <Typography>
                                                        <strong>
                                                            *Execution Date can vary by 1 day
                                                            maximum.
                                                        </strong>
                                                    </Typography>
                                                </>
                                            )}
                                        </Box>

                                        <Box
                                            sx={{
                                                display: 'inline-flex',
                                                gap: '10px',
                                                alignItems: 'center',
                                                marginTop: '10px',
                                                justifyContent: 'center',
                                                width: '100%',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    borderRadius: '10px',
                                                    maxHeight: '2.5rem',
                                                    textTransform: 'capitalize',
                                                }}
                                                onClick={() => {
                                                    setOpenDate(false);
                                                    props.updateWalletDates();
                                                    props.handleDrawerToggle();
                                                }}
                                            >
                                                {props.selectedWallet?.executionTransferStartDate
                                                    ? 'Update Execution'
                                                    : 'Set Execution'}
                                            </Button>
                                            {props.selectedWallet?.executionTransferStartDate && (
                                                <Button
                                                    variant="outlined"
                                                    sx={{
                                                        borderRadius: '10px',
                                                        maxHeight: '2.5rem',
                                                        textTransform: 'capitalize',
                                                    }}
                                                    onClick={() => {
                                                        props.cancelWalletDates();
                                                        setOpenDate(false);
                                                        props.handleDrawerToggle();
                                                    }}
                                                >
                                                    Cancel Execution
                                                </Button>
                                            )}
                                        </Box>
                                    </Box>
                                </DatePicker>
                            </Box>
                        )}

                        <Button
                            className="create_beneficiary"
                            onClick={() => {
                                navigate('/create-beneficiary');
                                props.handleDrawerToggle();
                            }}
                            sx={{
                                textTransform: 'capitalize',
                                flexGrow: 0,
                                fontSize: '14px',
                                padding: '6px 20px',
                                borderRadius: '30px',
                                backgroundColor: 'black',
                                border: '2px solid rgba(25, 25, 25, 0.5)',
                                color: 'white',
                                transition: 'border-color 0.3s ease',
                                '&:hover': {
                                    backgroundColor: 'black',
                                },
                            }}
                        >
                            Create Beneficiary
                        </Button>

                        <Box
                            className="upgrade_plan"
                            onClick={() => {
                                navigate('/subscription');
                                props.handleDrawerToggle();
                            }}
                            sx={{
                                flexGrow: 0,
                                fontWeight: '500',
                                display: 'flex',
                                fontSize: '14px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '10px 20px',
                                borderRadius: '20px',
                                backgroundColor: 'rgb(211,211,211)',
                                cursor: 'pointer',
                                color: 'black',
                                transition: 'background-color 0.3s ease',
                                ':hover': {
                                    backgroundColor: 'rgba(25, 25, 25, 0.02)',
                                },
                            }}
                        >
                            Subscription
                        </Box>
                        <Box
                            sx={{ display: 'flex', justifyContent: 'center', pb: '3' }}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            <ToggleSwitch setCurrency={props?.setCurrency} />
                        </Box>
                    </Box>
                </Box>
            </Drawer>

            <Dialog maxWidth="md" fullWidth={false} open={confirm}>
                <DialogTitle>Delete Wallet</DialogTitle>
                <DialogContent>
                    <Box>
                        <Typography>Are you sure you want to delete wallet ?</Typography>{' '}
                    </Box>
                    <Box sx={{ marginTop: '2rem', float: 'right', gap: '10px', display: 'flex' }}>
                        <Box
                            sx={{
                                textAlign: 'center',
                                flexGrow: 0,
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '14px',
                                fontWeight: '500',
                                height: '25px',
                                padding: '6px 20px',
                                borderRadius: '20px',
                                backgroundColor: 'black',
                                border: '2px solid rgba(25, 25, 25, 0.5)',
                                cursor: 'pointer',
                                color: 'white',
                                transition: 'border-color 0.3s ease',
                                '&:hover': {
                                    borderColor: 'black',
                                },
                            }}
                            onClick={async () => {
                                try {
                                    const updateContractResponse = await ApiUtils.deleteWallet(
                                        props.selectedWallet.id
                                    );
                                    CustomToast({
                                        message: 'Wallet deleted successfully.',
                                        severity: 'success',
                                    });
                                } catch (e) {
                                    console.log(e);
                                }
                                setConfirm(false);
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                            }}
                            className="confirm_contract"
                        >
                            YES
                        </Box>
                        <Box
                            sx={{
                                textAlign: 'center',
                                flexGrow: 0,
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '14px',
                                fontWeight: '500',
                                height: '25px',
                                padding: '6px 20px',
                                borderRadius: '20px',
                                backgroundColor: 'transparent',
                                border: '2px solid rgba(25, 25, 25, 0.5)',
                                cursor: 'pointer',
                                color: 'black',
                                transition: 'border-color 0.3s ease',
                                '&:hover': {
                                    borderColor: 'black',
                                },
                            }}
                            onClick={() => {
                                setConfirm(false);
                            }}
                        >
                            NO
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}

