import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const ColorButton: React.FC<any> = (props: any) => {
    const [subType, setSubType] = useState<string>('ETH');

    useEffect(() => {
        props.setCurrency(subType);
    }, [subType]);
    return (
        <Box sx={{ display: 'block', width: 'max-content' }}>
            <Box className="mask-box" sx={{ width: 'max-content' }}>
                <Box
                    className="mask"
                    style={{
                        transform: `translateX(${subType === 'ETH' ? 0 : '60px'})`,
                    }}
                />
                <Button
                    disableRipple
                    variant="text"
                    sx={{ color: subType === 'ETH' ? '#ffffff' : 'black' }}
                    onClick={() => setSubType('ETH')}
                >
                    ETH
                </Button>
                <Button
                    disableRipple
                    variant="text"
                    sx={{ color: subType === 'USD' ? '#ffffff' : 'black' }}
                    onClick={() => setSubType('USD')}
                >
                    USD
                </Button>
            </Box>
        </Box>
    );
};

export default ColorButton;

