import axios from 'axios';
import { BASE_URL } from '../config/Constants';
// import {store} from "../redux/store";

const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
        Accept: 'multipart/form-data',
        'Content-Type': 'application/json',
        // 'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
    },
});

instance.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        const loader = document.querySelector('.loading');
        (loader as HTMLElement).style.display = 'block';

        const token = sessionStorage.getItem('__COI_TOKEN__');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },
    function (error) {
        console.log('error', error);
        // Do something with request error
        // return Promise.reject(error);
    }
);

// Add a response interceptor
instance.interceptors.response.use(
    function (response) {
        const loader = document.querySelector('.loading');
        (loader as HTMLElement).style.display = 'none';
        return response;
    },
    function (error) {
        const loader = document.querySelector('.loading');
        (loader as HTMLElement).style.display = 'none';
        if (error.response.status === 401 && !window.location.href.includes('login')) {
            window.location.href = '/login';
        }
        return Promise.reject(error);
        // if (error?.response?.status === 401) {
        //     // store.dispatch();
        //     localStorage.removeItem('mel-user');
        //     return Promise.reject(error);
        // } else {
        //     // Any status codes that falls outside the range of 2xx cause this function to trigger
        //     // Do something with response error
        //     const loader = document.querySelector('.loading');
        //     (loader as HTMLElement).style.display = 'none';
        //     return Promise.reject(error);
        // }
    }
);
export default instance;

