import React, { createContext, useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from './Header';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    CurrencyContext,
    WalletContext,
    ConnectWalletContext,
    CurrentUserContext,
    FetchCurrentUserContext,
} from './context';
import { useAccount } from 'wagmi';

type Props = {
    children: JSX.Element;
};
const Layout = ({ children }: Props) => {
    const navigate = useNavigate();
    const { isConnected } = useAccount();
    const token = sessionStorage.getItem('__COI_TOKEN__');

    useEffect(() => {
        if (!token || !isConnected) {
            navigate('/login');
        }
    }, [token, isConnected]);

    const [wallet, setWallet] = useState<any>(null);
    const [currency, setCurrency] = useState<any>(null);
    const [connectWallet, setConnectWallet] = useState<any>(null);
    const [currentUser, setCurrentUser] = useState<any>(null);
    const [fetchCurrentUser, setFetchCurrentUser] = useState<any>(null);
    const location = useLocation().pathname;

    if (!token) {
        return null;
    }

    return (
        <CurrencyContext.Provider value={currency}>
            <WalletContext.Provider value={wallet}>
                <ConnectWalletContext.Provider value={connectWallet}>
                    <CurrentUserContext.Provider value={currentUser}>
                        <FetchCurrentUserContext.Provider value={fetchCurrentUser}>
                            <div className="main_wrapper">
                                <Header
                                    setWallet={setWallet}
                                    setCurrency={setCurrency}
                                    setConnectWallet={setConnectWallet}
                                    setCurrentUser={setCurrentUser}
                                    setFetchCurrentUser={setFetchCurrentUser}
                                />
                                <div className="container">
                                    <div
                                        className={
                                            (location !== '/search' ? 'container-wrapper' : '') + ''
                                        }
                                    >
                                        {children}
                                    </div>
                                </div>
                            </div>
                        </FetchCurrentUserContext.Provider>
                    </CurrentUserContext.Provider>
                </ConnectWalletContext.Provider>
            </WalletContext.Provider>
        </CurrencyContext.Provider>
    );
};

export default Layout;

