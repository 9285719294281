export const BASE_URL = process.env.REACT_APP_BASE_URL_API;
export const BASE_IMG_URL = process.env.REACT_APP_BASE_URL_IMG;
export const BASE_BASE_TEST_MICOSERVICE_URL = process.env.REACT_APP_BASE_TEST_MICOSERVICE_URL;
export const BASE_TEST_FUND_ADDRESS = process.env.REACT_APP_BASE_TEST_FUND_ADDRESS;
export const MORALIS_KEY = process.env.REACT_APP_MORALIS_API_KEY;

export const APP_ENV_TYPE = Object.freeze({
    LOCAL: 'local',
    DEVELOPMENT: 'development',
});

export const DOC_TYPES = Object.freeze({
    Excel: 1,
    PDF: 2,
    WORD: 3,
    DB: 4,
    Gateway: 5,
    web_page: 6,
    ServiceURL: 7,
    Text: 8,
});
