import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const mainApi = createApi({
    reducerPath: 'mainApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL_API,
        prepareHeaders: (headers) => {
            const token = sessionStorage.getItem('__COI_TOKEN__');

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
        responseHandler: async (response: Response) => {
            // Your custom logic here
            if (response.status === 401) {
                window.location.href = '/login';
            }
            // You must return a promise
            return response.json();
        },
    }),
    tagTypes: ['Main'],
    keepUnusedDataFor: 0,
    endpoints: () => ({}),
});
