import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    InputBase,
    TextField,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import ApiUtils from '../../api/ApiUtils';
import { Field, useFormik, FormikProvider } from 'formik';
import { CreateBenValidationSchema } from '../../validations/CreateBenValidationSchema';
import toast from 'react-hot-toast';
import UploadPhotoPopup from './UploadPhotoPopup';
import { BASE_IMG_URL } from '../../config/Constants';
import CustomToast from '../Common/CustomToast';

function EditBeneficiaryPopup(props: any) {
    const navigate = useNavigate();
    const [data, setData] = useState<any>({ ...props.data });
    const [isPopupOpen, setPopupOpen] = useState(false);
    // const [openUploadPopup, setopenUploadPopup] = useState(false);
    const handleOpenPopup = () => {
        setPopupOpen(true);
    };
    const handleClosePopup = () => {
        setPopupOpen(false);
    };
    const [benPhoto, setBenPhoto] = useState(BASE_IMG_URL + props.data.photoFileName);

    const handleBenPhotoChange = (newPhoto: any) => {
        setBenPhoto(newPhoto);
    };
    const formik_update = useFormik({
        initialValues: {
            beneficiaryName: props?.data?.beneficiaryName,
            nickName: props.data.nickName,
            publicAddress: props.data.publicAddress,
        },
        validationSchema: CreateBenValidationSchema,
        onSubmit: async (values: any, { setSubmitting }) => {
            try {
                if (data && data.id) {
                    const updateData = {
                        id: data.id,
                        beneficiaryName: values.beneficiaryName,
                        nickName: values.nickName,
                        publicAddress: values.publicAddress,
                    };
                    await ApiUtils.updateBeneficiary(updateData);
                    if (benPhoto && !benPhoto.includes('api')) {
                        await ApiUtils.addBenPhoto({
                            beneficiaryID: data.id,
                            file: benPhoto,
                        });
                    }
                    props.handleUpdate();
                    props.setResult(false);
                }
                setSubmitting(false);
            } catch (error) {
                CustomToast({
                    message: 'Error adding user. Please try again.',
                    severity: 'error',
                });
            }
        },
    });

    return (
        <React.Fragment>
            <Dialog maxWidth="md" fullWidth={true} open={props.result}>
                <DialogTitle
                    className="asset_popup"
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: '23px',
                        fontWeight: '600',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        className="edit_beneficiary"
                        sx={{ margin: '16px 0px', fontSize: '20px', fontWeight: '500' }}
                    >
                        Edit Beneficiary
                    </Typography>
                    <CloseIcon
                        className="close_icon"
                        onClick={() => {
                            props.setResult(false);
                        }}
                        sx={{ cursor: 'pointer' }}
                    />
                </DialogTitle>
                <DialogContent>
                    {/* <FormikProvider value={formik_update}>
                        <Box
                            component="form"
                            noValidate
                            onSubmit={formik_update.handleSubmit}
                            className="create_avatar"
                            sx={{
                                alignItems: 'baseline',
                                justifyContent: 'space-between',
                                padding: '24px',
                                borderRadius: '16px',
                                backgroundColor: '#fff',
                                marginBottom: '24px',
                            }}
                        >
                            <Box className="select_picture" sx={{}}>
                                <Field
                                    name="beneficiaryName"
                                    render={({ field }: any) => (
                                        <div>
                                            <InputBase
                                                className="field"
                                                {...field}
                                                required
                                                placeholder="Beneficiary Name"
                                                sx={{
                                                    fontSize: '14px',
                                                    width: '100%',
                                                    marginBottom: '12px',
                                                    lineHeight: '1.5',
                                                    fontWeight: 600,
                                                    color: '#000000',
                                                    padding: '10px 16px',
                                                    borderRadius: '20px',
                                                    backgroundColor: 'rgba(188, 188, 188, 0.1)',
                                                    flex: 1,
                                                    '&::placeholder': {
                                                        color: 'rgba(1, 2, 3, 0.5)',
                                                    },
                                                    border:
                                                        formik_update.touched.beneficiaryName &&
                                                        formik_update.errors.beneficiaryName
                                                            ? '1px solid red'
                                                            : '1px solid #bcbcbc1a',
                                                }}
                                                inputProps={{
                                                    style: {
                                                        padding: '0',
                                                    },
                                                }}
                                                onChange={(e) => {
                                                    formik_update.handleChange(e);
                                                }}
                                                value={formik_update.values.beneficiaryName}
                                            />
                                            {formik_update.touched.beneficiaryName &&
                                                formik_update.errors.beneficiaryName && (
                                                    <div
                                                        style={{
                                                            color: 'red',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {
                                                            formik_update.errors
                                                                .beneficiaryName as string
                                                        }{' '}
                                                    </div>
                                                )}
                                        </div>
                                    )}
                                />
                                <Field
                                    name="nickName"
                                    render={({ field }: any) => (
                                        <div>
                                            <InputBase
                                                className="field"
                                                {...field}
                                                required
                                                placeholder="Nick Name"
                                                sx={{
                                                    width: '100%',
                                                    fontSize: '14px',
                                                    marginBottom: '12px',
                                                    lineHeight: '1.5',
                                                    fontWeight: 600,
                                                    color: '#000000',
                                                    padding: '10px 16px',
                                                    borderRadius: '20px',
                                                    backgroundColor: 'rgba(188, 188, 188, 0.1)',
                                                    flex: 1,
                                                    '&::placeholder': {
                                                        color: 'rgba(1, 2, 3, 0.5)',
                                                    },
                                                    border:
                                                        formik_update.touched.nickName &&
                                                        formik_update.errors.nickName
                                                            ? '1px solid red'
                                                            : '1px solid #bcbcbc1a',
                                                }}
                                                inputProps={{
                                                    style: {
                                                        padding: '0',
                                                    },
                                                }}
                                                onChange={(e) => {
                                                    formik_update.handleChange(e);
                                                }}
                                                value={formik_update.values.nickName}
                                            />
                                            {formik_update.touched.nickName &&
                                                formik_update.errors.nickName && (
                                                    <div
                                                        style={{
                                                            color: 'red',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {formik_update.errors.nickName as string}{' '}
                                                    </div>
                                                )}
                                        </div>
                                    )}
                                />
                                <Field
                                    name="publicAddress"
                                    render={({ field }: any) => (
                                        <div>
                                            <InputBase
                                                className="field"
                                                {...field}
                                                required
                                                placeholder="Public Address"
                                                sx={{
                                                    marginBottom: '8px',
                                                    width: '100%',
                                                    fontSize: '14px',
                                                    lineHeight: '1.5',
                                                    fontWeight: 600,
                                                    color: '#000000',
                                                    padding: '10px 16px',
                                                    borderRadius: '20px',
                                                    backgroundColor: 'rgba(188, 188, 188, 0.1)',
                                                    flex: 1,
                                                    '&::placeholder': {
                                                        color: 'rgba(1, 2, 3, 0.5)',
                                                    },
                                                    border:
                                                        formik_update.touched.publicAddress &&
                                                        formik_update.errors.publicAddress
                                                            ? '1px solid red'
                                                            : '1px solid #bcbcbc1a',
                                                }}
                                                inputProps={{
                                                    style: {
                                                        padding: '0',
                                                    },
                                                }}
                                                onChange={(e) => {
                                                    formik_update.handleChange(e);
                                                }}
                                                value={formik_update.values.publicAddress}
                                            />
                                            {formik_update.touched.publicAddress &&
                                                formik_update.errors.publicAddress && (
                                                    <div
                                                        style={{
                                                            color: 'red',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {
                                                            formik_update.errors
                                                                .publicAddress as string
                                                        }{' '}
                                                    </div>
                                                )}
                                        </div>
                                    )}
                                />
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <Box
                                    className="picture"
                                    component="div"
                                    sx={{
                                        margin: '20px 0px',
                                        display: 'flex',
                                        gap: '10px',
                                        fontSize: '14px',
                                        alignItems: 'center',
                                        borderRadius: '20px',
                                        border: '2px solid rgba(25, 25, 25, 0.5)',
                                        padding: '6px 12px',
                                        cursor: 'pointer',
                                        fontWeight: '500',
                                        height: '26px',
                                        '&:hover': {
                                            borderColor: 'black',
                                        },
                                        width: 'max-content',
                                    }}
                                    onClick={handleOpenPopup}
                                >
                                    <Avatar
                                        alt={props.data.beneficiaryName}
                                        src={benPhoto}
                                        sx={{ width: '29px', height: '29px' }}
                                    />
                                    <span>Picture Selected</span>
                                </Box>
                            </Box>

                            <Button
                                className="confirm_btn"
                                sx={{
                                    color: '#FFFF',
                                    backgroundColor: '#191919',
                                    borderRadius: '20px',
                                    padding: '9px 20px',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    display: 'flex',
                                    alignItems: 'center',
                                    textTransform: 'capitalize',
                                    '&:hover': {
                                        backgroundColor: '#191919',
                                        color: '#fff',
                                        opacity: '0.6',
                                    },
                                }}
                                type="submit"
                            >
                                Confirm
                            </Button>
                        </Box>
                    </FormikProvider> */}

                    {/*  */}

                    <Box
                        component="form"
                        noValidate
                        onSubmit={formik_update.handleSubmit}
                        sx={{ mt: '1rem' }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                sx={{
                                    position: 'relative',
                                    marginBottom: '20px',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    color: '#000000',
                                    '& label': {
                                        top: '-5px',
                                        position: 'absolute',
                                        fontSize: '14px',
                                    },
                                    '&::placeholder': {
                                        color: 'rgba(1, 2, 3, 0.5)',
                                        fontSize: '14px',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        height: '40px', // Adjust the height as needed
                                    },
                                    '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                                        position: 'absolute',
                                        width: '70%',
                                        height: '1rem',
                                        fontSize: '14px',
                                    },
                                }}
                                    required
                                    placeholder="Beneficiary Name"
                                    variant="outlined"
                                    fullWidth
                                    id="beneficiaryName"
                                    label="Beneficiary Name"
                                    name="beneficiaryName"
                                    autoComplete="userName"
                                    onChange={formik_update.handleChange}
                                    value={formik_update.values.beneficiaryName}
                                    error={
                                        formik_update.touched.beneficiaryName &&
                                        Boolean(formik_update.errors.beneficiaryName)
                                    }
                                    // helperText={formik_update.touched.beneficiaryName && formik_update.errors.beneficiaryName}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                sx={{
                                    position: 'relative',
                                    marginBottom: '20px',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    color: '#000000',
                                    '& label': {
                                        top: '-5px',
                                        position: 'absolute',
                                        fontSize: '14px',
                                    },
                                    '&::placeholder': {
                                        color: 'rgba(1, 2, 3, 0.5)',
                                        fontSize: '14px',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        height: '40px', // Adjust the height as needed
                                    },
                                    '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                                        position: 'absolute',
                                        width: '70%',
                                        height: '1rem',
                                        fontSize: '14px',
                                    },
                                }}
                                    required
                                    placeholder="Nick Name"
                                    variant="outlined"
                                    fullWidth
                                    id="nickName"
                                    label="Nick Name"
                                    name="nickName"
                                    autoComplete="nickName"
                                    onChange={formik_update.handleChange}
                                    value={formik_update.values.nickName}
                                    error={
                                        formik_update.touched.nickName &&
                                        Boolean(formik_update.errors.nickName)
                                    }
                                    // helperText={formik_update.touched.beneficiaryName && formik_update.errors.beneficiaryName}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                sx={{
                                    position: 'relative',
                                    marginBottom: '12px',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    color: '#000000',
                                    '& label': {
                                        top: '-5px',
                                        position: 'absolute',
                                        fontSize: '14px',
                                    },
                                    '&::placeholder': {
                                        color: 'rgba(1, 2, 3, 0.5)',
                                        fontSize: '14px',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        height: '40px'
                                    },
                                    '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                                        position: 'absolute',
                                        width: '70%',
                                        height: '1rem',
                                        fontSize: '14px',
                                    },
                                }}
                                    required
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Public Address"
                                    id="publicAddress"
                                    label="Public Address"
                                    name="publicAddress"
                                    autoComplete="publicAddress"
                                    onChange={formik_update.handleChange}
                                    value={formik_update.values.publicAddress}
                                    error={
                                        formik_update.touched.publicAddress &&
                                        Boolean(formik_update.errors.publicAddress)
                                    }
                                    // helperText={formik_update.touched.beneficiaryName && formik_update.errors.beneficiaryName}
                                />
                            </Grid>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', ml: '16px' }}>
                                <Box
                                    className="picture"
                                    component="div"
                                    sx={{
                                        margin: '20px 0px',
                                        display: 'flex',
                                        gap: '10px',
                                        fontSize: '14px',
                                        alignItems: 'center',
                                        borderRadius: '20px',
                                        border: '2px solid rgba(25, 25, 25, 0.5)',
                                        padding: '6px 12px',
                                        cursor: 'pointer',
                                        fontWeight: '500',
                                        height: '26px',
                                        '&:hover': {
                                            borderColor: 'black',
                                        },
                                        width: 'max-content',
                                    }}
                                    onClick={handleOpenPopup}
                                >
                                    <Avatar
                                        alt={props.data.beneficiaryName}
                                        src={benPhoto}
                                        sx={{ width: '29px', height: '29px' }}
                                    />
                                    <span>Picture Selected</span>
                                </Box>
                            </Box>
                        </Grid>
                        <Button
                            className="confirm_btn"
                            sx={{
                                color: '#FFFF',
                                backgroundColor: '#191919',
                                borderRadius: '20px',
                                padding: '9px 20px',
                                fontSize: '14px',
                                fontWeight: '500',
                                display: 'flex',
                                alignItems: 'center',
                                textTransform: 'capitalize',
                                '&:hover': {
                                    backgroundColor: '#191919',
                                    color: '#fff',
                                    opacity: '0.6',
                                },
                            }}
                            type="submit"
                        >
                            Confirm
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <UploadPhotoPopup
                result={isPopupOpen}
                setResult={setPopupOpen}
                onBenPhotoChange={handleBenPhotoChange}
            />
        </React.Fragment>
    );
}

export default EditBeneficiaryPopup;
