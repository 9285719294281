import axios from 'axios';
import { BASE_URL } from '../../../config/Constants';
import { mainApi } from '../mainApiSlice';
import { toast } from 'react-hot-toast';

const GetLabels = async (id: number) => {
    return await axios.post(`${BASE_URL}/api/Label/GetLabelList?categoryID=${id}`);
};
const GetCreateLabelAPI = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        AddLabelList: builder.mutation({
            query: (data: any) => ({
                url: `${BASE_URL}/api/Label/AddLabel`,
                method: 'POST',
                body: data,
            }),
        }),
        AddWalletLabel: builder.mutation({
            query: (data: any) => ({
                url: `${BASE_URL}/api/WalletLabel/AddWalletTokenLabel`,
                method: 'POST',
                body: data,
            }),
        }),
        AddBeneficiaryLabel: builder.mutation({
            query: (data: any) => ({
                url: `${BASE_URL}/api/BeneficiaryLabel/AddBeneficiaryLabel`,
                method: 'POST',
                body: data,
            }),
        }),
        GetLabelList: builder.query({
            query: (categoryID: number) => ({
                url: `${BASE_URL}/api/Label/GetLabelList?categoryID=${categoryID}`,
                method: 'GET',
            }),
        }),
    }),
});

export { GetLabels };
export const {
    useAddLabelListMutation,
    useLazyGetLabelListQuery,
    useAddBeneficiaryLabelMutation,
    useAddWalletLabelMutation,
} = GetCreateLabelAPI;

