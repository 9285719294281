import { Box, Button, Grid, Typography } from '@mui/material';
import Footer from '../CommonLayout/Footer';
import { logo } from '../../config/Images';
import { useNavigate } from 'react-router-dom';
import { useAccount, useConnect, useDisconnect, useSignMessage } from 'wagmi';
import { injected } from '@wagmi/connectors';
import ApiUtils from '../../api/ApiUtils';

function ConnectWallet() {
    const { connectors, connectAsync } = useConnect();
    const { disconnectAsync } = useDisconnect();
    const { isConnected } = useAccount();
    const { signMessageAsync } = useSignMessage();
    const navigate = useNavigate();
    const handleAuth = async (connector: any) => {
        //disconnects the web3 provider if it's already active
        if (isConnected) {
            await disconnectAsync();
        }
        // enabling the web3 provider metamask
        const { accounts } = await connectAsync({ connector });
        const userData = { walletAddress: accounts[0], chainId: 1 };
        // making a post request to our 'request-message' endpoint
        const { message } = await ApiUtils.getMoralisMessageInfo(userData);
        // const message = data.message;

        // signing the received message via metamask
        const signature = await signMessageAsync({ message });
        const { token } = await ApiUtils.authenticateWithWallet({
            walletAddress: accounts[0],
            generatedMessage: message,
            signature: signature,
        });
        if (token) {
            sessionStorage.setItem('__COI_TOKEN__', token);
            navigate('/assets');
        }
    };
    return (
        <>
            <Box
                sx={{
                    background: '#ffffff',
                    borderRadius: '20px',
                    padding: ' 0rem 2rem 0rem 2rem',
                    height: '100vh',
                }}
            >
                <Box
                    className="upgrade"
                    textAlign={'center'}
                    sx={{ position: 'relative', borderRadius: '16px', top: '36px' }}
                >
                    <Grid
                        container
                        spacing={3}
                        sx={{
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '86px 24px',
                        }}
                    >
                        <Grid item lg={7} md={12} sm={12}>
                            <Typography
                                sx={{
                                    fontSize: '1.5rem',
                                    lineHeight: '1.75rem',
                                    fontWeight: '600',
                                    margin: '0px',
                                }}
                            >
                                Please, connect your wallet
                            </Typography>
                            <Typography
                                sx={{
                                    margin: '24px 0px',
                                    fontSize: '0.875rem',
                                    fontWeight: '400',
                                    lineHeight: '1.75',
                                }}
                            >
                                Please connect your wallet to see your supplies, borrowings, and
                                open positions.
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box className="blur_bg"></Box>
                </Box>

                <>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '0px',
                                position: 'absolute',
                                top: '30%',
                            }}
                        >
                            <Box>
                                <img
                                    className="logo"
                                    style={{
                                        width: '350px',
                                        // width: matches ? '60%' : '15%',
                                        marginRight: '50px',
                                        cursor: 'pointer',
                                    }}
                                    src={logo}
                                    alt="logo"
                                />
                            </Box>
                            {!window.ethereum && (
                                <Button
                                    onClick={() => {
                                        window.open('https://metamask.io/', '_blank');
                                    }}
                                    sx={{
                                        color: '#FFFF',
                                        backgroundColor: 'black',
                                        borderRadius: '30px',
                                        padding: '7px 30px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        fontSize: '18px',
                                        fontWeight: '500',
                                        transition: 'border-color 0.3s ease',
                                        textTransform: 'capitalize',
                                        '&:hover': {
                                            background: 'black',
                                            opacity: '0.6',
                                        },
                                    }}
                                >
                                    Install MetaMask & Refresh
                                </Button>
                            )}
                            {window.ethereum &&
                                connectors.length &&
                                connectors
                                    .filter((connector) => connector.name)
                                    .map((connector) => (
                                        <Button
                                            key={connector.name}
                                            onClick={() => handleAuth(connector)}
                                            sx={{
                                                color: '#FFFF',
                                                backgroundColor: 'black',
                                                borderRadius: '30px',
                                                padding: '7px 30px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                fontSize: '18px',
                                                fontWeight: '500',
                                                transition: 'border-color 0.3s ease',
                                                textTransform: 'capitalize',
                                                '&:hover': {
                                                    background: 'black',
                                                    opacity: '0.6',
                                                },
                                            }}
                                        >
                                            Connect with {connector.name}
                                        </Button>
                                    ))}
                        </Box>
                    </Box>
                </>
            </Box>
            <Footer></Footer>
        </>
    );
}

export default ConnectWallet;

