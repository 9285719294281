import * as yup from 'yup';

const ManageAccountValidationSchema = yup.object({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    email: yup.string().email('Invalid email address').required('Email is required'),
    mobile: yup.string().required('Contact Number is required'),
});

export { ManageAccountValidationSchema };

