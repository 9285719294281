import React, { useContext, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import {
    Box,
    Button,
    Grid,
    Menu,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import InputBase from '@mui/material/InputBase';
import Footer from '../CommonLayout/Footer';
import Slider from '@mui/material/Slider';
import TablePagination from '@mui/material/TablePagination';
import AssetsImage from '../../assets/images/eth.png';
import { Lable, search } from '../../config/Images';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import SelectArrow from '../../assets/images/dropdownArrow.svg';
import { useLazyGetLabelListQuery } from '../../services/Slices/apiSlice/GetCreateLabelAPI';
import AssetsRow from '../Common/AssetsRow';
import ApiUtils from '../../api/ApiUtils';
import { useLazyGetWalletTokensQuery } from '../../services/Slices/apiSlice/assetsAPI';
import {
    ConnectWalletContext,
    // CurrencyContext,
    CurrentUserContext,
    WalletContext,
} from '../CommonLayout/context';

function Assets() {
    // const currency = useContext(CurrencyContext);
    const currentUser = useContext(CurrentUserContext);

    const walletID = useContext(WalletContext);
    const connectWallet = useContext(ConnectWalletContext);
    const matches = useMediaQuery('(min-width:600px)');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorLabelEl, setAnchorLabalEl] = React.useState<null | HTMLElement>(null);
    // const [selectedItem, setSelectedItem] = React.useState<string>('');
    const handleChange = (event: any, newValue: any) => {
        setFilterData((prev) => ({
            ...prev,
            fromValue: newValue[0],
            toValue: newValue[1],
        }));
    };
    // const [openModal, setOpenModal] = React.useState(false);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClosed = () => {
        setAnchorLabalEl(null);
    };

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    // const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClicked = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorLabalEl(event.currentTarget);
    };
    const [labelList, setLabelList] = useState<any[]>([]);

    const [GetLabels, { data, isLoading, error }] = useLazyGetLabelListQuery();

    useEffect(() => {
        if (!data) {
            GetLabels(2);
        }
        if (data && !error) {
            setLabelList(data);
            setLabelRowData(data);
        }
    }, [data, isLoading, error, GetLabels]);
    const [rowData, setRowData] = useState<any>([]);
    const [defaultRowData, setDefaultRowData] = useState<any>([]);
    const [filterData, setFilterData] = useState({
        fromValue: 0,
        toValue: null,
        label: null,
    });
    const [GetWalletTokens, { data: benData, isLoading: benLoading, error: benError }] =
        useLazyGetWalletTokensQuery();

    useEffect(() => {
        (async () => {
            if (walletID) {
                await GetWalletTokens({ walletID: walletID, ...filterData });
                if (benData && !benError && !benLoading) {
                    setRowData([...benData]);
                    setDefaultRowData([...benData]);
                }
            }
        })();
    }, [walletID, connectWallet, benData, benLoading, benError, GetWalletTokens, filterData]);
    const handleUpdateAssetsData = () => {
        GetWalletTokens({ walletID: walletID, ...filterData });
        GetLabels(2);
    };
    const [searchData, setSearch] = useState('');
    const [searchLabel, setSearchLabel] = useState('');
    const [defaultLabelRowData, setLabelRowData] = useState<any>([]);
    useEffect(() => {
        if (searchLabel !== '') {
            setLabelList([]);
            for (let i = 0; i < defaultLabelRowData.length; i++) {
                if (
                    defaultLabelRowData[i].labelName
                        .toLowerCase()
                        .includes(searchLabel.toLowerCase())
                ) {
                    setLabelList((prev: any) => [...prev, defaultLabelRowData[i]]);
                }
            }
        } else {
            setLabelList([...defaultLabelRowData]);
        }
    }, [searchLabel, setLabelList, defaultLabelRowData]);
    const [selectedLabel, setSelectedLabel] = useState<any>(null);

    const handleCheckboxChange = (label: any) => {
        if (selectedLabel === label) {
            setSelectedLabel(null);
            setFilterData((prev: any) => ({ ...prev, label: null }));
        } else {
            setSelectedLabel(label);
            setFilterData((prev: any) => ({ ...prev, label: label.labelName }));
        }
    };
    useEffect(() => {
        if (searchData !== '') {
            setRowData([]);
            for (let i = 0; i < defaultRowData.length; i++) {
                let extraSearch = '';
                if (defaultRowData[i].associatedBeneficiariesModel?.length) {
                    defaultRowData[i].associatedBeneficiariesModel.map((item: any) => {
                        extraSearch += `/${item.beneficiaryName}/${item.nickName}`;
                    });
                }
                if (
                    `${defaultRowData[i].name + extraSearch}`
                        .toLowerCase()
                        .includes(searchData.toLowerCase())
                ) {
                    setRowData((prev: any) => [...prev, defaultRowData[i]]);
                }
            }
        } else {
            setRowData([...defaultRowData]);
        }
    }, [searchData, setRowData, defaultRowData]);
    const [walletBen, setWalletBen] = useState<any>([]);
    useEffect(() => {
        if (walletID) {
            const getData = async () => {
                const data = await ApiUtils.getAllBeneficiaryList();
                setWalletBen([...data]);
            };
            getData();
        }
    }, [walletID, setWalletBen]);
    const [value, setValue] = useState<any>([0, 100]); // Define and initialize value state

    return (
        <>
            <Box
                mb={3}
                className="profile-box"
                sx={{
                    borderRadius: '16px',
                    backgroundColor: 'rgb(255, 255, 255)',
                    boxShadow: 'rgba(153, 153, 153, 0.12) 0px 6px 20px 0px',
                    // padding: '0px 0px 20px 0px',
                }}
            >
                <Box sx={{ mr: 0 }}>
                    <Typography sx={{ mb: 0, fontWeight: 600, fontSize: '1rem' }}></Typography>
                    <Box
                        mb={matches ? 3 : 3}
                        display={matches ? 'flex' : 'block'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        gap={'16px'}
                        className="assets_wrap"
                        sx={{
                            boxShadow: 'rgba(153, 153, 153, 0.12) 0px 6px 20px 0px',
                            padding: '20px 24px',
                            borderRadius: '16px',
                            backgroundColor: 'rgb(255, 255, 255)',
                        }}
                    >
                        <Box
                            className="list_asset"
                            sx={{
                                fontWeight: 600,
                                fontSize: '21px',
                                mb: matches ? '0px' : '24px',
                            }}
                        >
                            Assets List
                        </Box>
                        {walletID && (
                            <Box className="header" sx={{ display: 'flex', gap: '16px' }}>
                                <Box
                                    sx={{
                                        flexGrow: 0,
                                        display: 'flex',
                                        padding: '6px 20px',
                                        borderRadius: '18px',
                                        backgroundColor: '#BCBCBC1A',
                                        cursor: 'pointer',
                                        color: 'black',
                                        alignItems: 'center',
                                        gap: '8px',
                                        mb: matches ? '0px' : '16px',
                                    }}
                                >
                                    <img src={search} alt="search"></img>
                                    <InputBase
                                        // as={InputBase}
                                        onChange={(e) => {
                                            setSearch(e.target.value);
                                        }}
                                        placeholder="Search for assets name"
                                        sx={{
                                            fontSize: '15px',
                                            lineHeight: '1.5',
                                            fontWeight: 600,
                                            color: 'black',
                                            flex: 1,
                                        }}
                                        inputProps={{
                                            sx: {
                                                padding: '0', // Adjust padding if necessary
                                                '::placeholder': {
                                                    color: 'black', // Set placeholder color to transparent
                                                },
                                                ':focus::placeholder': {
                                                    color: 'transparent', // Set placeholder color when focused
                                                },
                                            },
                                        }}
                                    />
                                </Box>

                                <Box
                                    className="value"
                                    onClick={handleClick}
                                    component="div"
                                    sx={{
                                        flexGrow: 0,
                                        display: 'flex',
                                        padding: '6px 20px',
                                        borderRadius: '18px',
                                        backgroundColor: '#BCBCBC1A',
                                        cursor: 'pointer',
                                        color: 'black',
                                        gap: '100px',
                                        alignItems: 'center',
                                        fontWeight: '500',
                                        justifyContent: 'space-between',
                                        height: '24px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '16px',
                                            alignItems: 'center',
                                            fontSize: '15px',
                                        }}
                                    >
                                        <img width={18} src={AssetsImage} alt="Percentage"></img>
                                        Percentage
                                    </Box>
                                    <img
                                        src={SelectArrow}
                                        alt="SelectArrow"
                                        width={'14px'}
                                        height={'14px'}
                                    ></img>
                                </Box>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <Box sx={{ width: '200px', padding: '16px 32px' }}>
                                        <Slider
                                            sx={{
                                                marginTop: '20px',
                                                color: 'black',
                                                '& .MuiSlider-thumb': {
                                                    '&:hover': {
                                                        boxShadow:
                                                            'rgba(25, 25, 25, 0.16) 0px 0px 0px 8px',
                                                    },
                                                },
                                            }}
                                            value={value}
                                            min={0}
                                            max={100}
                                            onChange={(event: Event, newValue: any) => {
                                                setValue(newValue as number);
                                            }}
                                            onChangeCommitted={handleChange}
                                            valueLabelDisplay="auto"
                                            aria-labelledby="non-linear-slider"
                                            marks={[
                                                {
                                                    value: 0,
                                                    label: '0%',
                                                },
                                                {
                                                    value: 100,
                                                    label: `100%`,
                                                },
                                            ]}
                                        />
                                    </Box>
                                </Menu>
                                <Box
                                    component="div"
                                    sx={{
                                        flexGrow: 0,
                                        display: 'flex',
                                        padding: '6px 20px',
                                        borderRadius: '18px',
                                        backgroundColor: '#BCBCBC1A',
                                        cursor: 'pointer',
                                        color: 'black',
                                        gap: '100px',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        fontWeight: '500',
                                        height: '24px',
                                    }}
                                    onClick={handleClicked}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '16px',
                                            alignItems: 'center',
                                            fontSize: '15px',
                                        }}
                                    >
                                        <img src={Lable} alt="lable"></img>
                                        Labels
                                    </Box>
                                    <img
                                        src={SelectArrow}
                                        alt="SelectArrow"
                                        width={'14px'}
                                        height={'14px'}
                                    ></img>
                                </Box>
                                <Menu
                                    anchorEl={anchorLabelEl}
                                    open={Boolean(anchorLabelEl)}
                                    onClose={handleClosed}
                                >
                                    <Box sx={{ padding: '14px 20px' }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: '8px',
                                                backgroundColor: '#BCBCBC1A',
                                                borderRadius: '16px',
                                                padding: '7px 16px',
                                                fontSize: '12px',
                                                marginBottom: '16px',
                                            }}
                                        >
                                            {/* Menu items */}
                                            <img src={search} alt="search"></img>
                                            <InputBase
                                                // value={searchLabel}
                                                placeholder="Search for label name"
                                                onChange={(e) => {
                                                    setSearchLabel(e.target.value);
                                                }}
                                                sx={{
                                                    fontSize: '15px',
                                                    lineHeight: '1.5',
                                                    fontWeight: 600,
                                                    color: 'black',
                                                    opacity: '1',
                                                    flex: 1,
                                                }}
                                                inputProps={{
                                                    sx: {
                                                        padding: '0', // Adjust padding if necessary
                                                        '::placeholder': {
                                                            color: 'black', // Set placeholder color to transparent
                                                        },
                                                        ':focus::placeholder': {
                                                            color: 'transparent', // Set placeholder color when focused
                                                        },
                                                    },
                                                }}
                                            />
                                        </Box>
                                        <FormGroup>
                                            {labelList &&
                                                labelList.length > 0 &&
                                                labelList.map((label, index) => {
                                                    return (
                                                        <Box
                                                            key={index}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <Checkbox
                                                                checked={selectedLabel === label}
                                                                onChange={() =>
                                                                    handleCheckboxChange(label)
                                                                }
                                                                // defaultChecked
                                                                sx={{
                                                                    '& .MuiSvgIcon-root': {
                                                                        color: 'black',
                                                                    },
                                                                }}
                                                            />{' '}
                                                            <Box
                                                                className="assets_span"
                                                                sx={{
                                                                    borderRadius: '16px',
                                                                    padding: '7px 20px',
                                                                    fontSize: '12px',
                                                                    fontWeight: '600',
                                                                    backgroundColor:
                                                                        selectedLabel === label
                                                                            ? 'black'
                                                                            : '#8649D333',
                                                                    color:
                                                                        selectedLabel === label
                                                                            ? '#FFFF'
                                                                            : '#8649D3',
                                                                    display: 'flex',
                                                                    gap: '8px',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        height: '8px',
                                                                        width: '8px',
                                                                        borderRadius: '50%',
                                                                        display: 'inline-block',
                                                                        backgroundColor:
                                                                            selectedLabel === label
                                                                                ? '#FFFF'
                                                                                : '#8649D3',
                                                                    }}
                                                                ></span>
                                                                {label.labelName}
                                                            </Box>
                                                        </Box>
                                                    );
                                                })}
                                        </FormGroup>
                                    </Box>
                                    {/* Add more menu items as needed */}
                                </Menu>
                            </Box>
                        )}
                    </Box>
                </Box>

                {walletID && (
                    <TableContainer
                        component={Paper}
                        sx={{
                            overflowX: 'auto',
                            '&::-webkit-scrollbar': {
                                height: '6px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                borderRadius: '3px',
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                borderRadius: '4px',
                            },
                        }}
                    >
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        width={100}
                                        sx={{ borderBottom: '1px solid black' }}
                                        align="center"
                                    >
                                        #
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontWeight: '600',
                                            borderBottom: '1px solid black',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                color: 'rgb(102, 102, 102)', // Change this to the desired color when hovering
                                            },
                                        }}
                                    >
                                        Name
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontWeight: '600',
                                            borderBottom: '1px solid black',
                                            cursor: 'pointer',
                                            textAlign: 'start',
                                            '&: hover': {
                                                color: 'rgb(102, 102, 102)',
                                            },
                                        }}
                                    >
                                        Quantity
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontWeight: '600',
                                            borderBottom: '1px solid black',
                                            cursor: 'pointer',
                                            textAlign: 'start',
                                            '&: hover': {
                                                color: 'rgb(102, 102, 102)',
                                            },
                                        }}
                                    >
                                        Beneficiaries
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            fontWeight: '600',
                                            borderBottom: '1px solid black',
                                            cursor: 'pointer',
                                            textAlign: 'start',
                                            '&: hover': {
                                                color: 'rgb(102, 102, 102)',
                                            },
                                        }}
                                    >
                                        Label
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            borderBottom: '1px solid black',
                                            alignItems: 'center',
                                        }}
                                    />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rowData &&
                                    rowData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row: any, index: any) => (
                                            <AssetsRow
                                                key={row.name}
                                                labelList={labelList}
                                                walletBen={walletBen}
                                                row={row}
                                                handleUpdateAssetsData={handleUpdateAssetsData}
                                            />
                                        ))}
                                {!rowData.length && (
                                    <TableRow>
                                        <TableCell colSpan={5}>
                                            <Typography
                                                color={'rgb(111,111,111)'}
                                                noWrap
                                                align="center"
                                                variant="h5"
                                            >
                                                No Data Found.
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                {/* {!walletID && (
                    <Box
                        sx={{
                            minHeight: '35vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography mb={2} sx={{ fontSize: '23px' }}>
                            <strong>
                                Connect a wallet{' '}
                                <span style={{ color: 'grey' }}>
                                    to start using the application
                                </span>
                            </strong>
                        </Typography>
                        <Box
                            sx={{
                                borderRadius: '20px',
                                color: '#FFFF',
                                backgroundColor: 'black',
                                width: 'max-content',
                                padding: '6px 20px',
                                cursor: 'pointer',
                                '&:hover': {
                                    opacity: '0.6',
                                },
                            }}
                            onClick={() => {
                                connectWallet.connect('metamask');
                            }}
                        >
                            Connect Wallet
                        </Box>
                    </Box>
                )} */}
                {/*  backgroun_image*/}
                {currentUser && !currentUser.isCustomer && (
                    <Box className="upgrade" textAlign={'center'}>
                        <Grid
                            container
                            spacing={3}
                            sx={{
                                textAlign: 'center',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Grid item lg={7} md={12} sm={12}>
                                <Typography
                                    sx={{
                                        fontSize: '1.5rem',
                                        lineHeight: '1.75rem',
                                        fontWeight: '600',
                                        margin: '0px',
                                    }}
                                >
                                    Choose a plan that is the best for you
                                </Typography>
                                <Typography
                                    sx={{
                                        margin: '24px 0px',
                                        fontSize: '0.875rem',
                                        fontWeight: '400',
                                        lineHeight: '1.75',
                                    }}
                                >
                                    Select the plan that fits your needs and your wallet! Enjoy our
                                    subscription service
                                </Typography>
                                <Button
                                    className="btn_opacity"
                                    style={{
                                        backgroundColor: '#191919',
                                        borderRadius: '20px',
                                        color: '#fff',
                                        padding: '6px 20px',
                                        textTransform: 'capitalize',
                                    }}
                                    onClick={() => window.open('/subscription', '_blank')}
                                >
                                    Subscription
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                )}

                <Box>
                    <TablePagination
                        component="div"
                        count={rowData.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            overflowX: 'auto',
                            '&::-webkit-scrollbar': {
                                height: '6px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                borderRadius: '4px',
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                borderRadius: '4px',
                            },
                        }}
                    />
                </Box>
            </Box>

            <Footer />
        </>
    );
}

export default Assets;
