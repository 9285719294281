import { Box } from '@mui/material';

const Footer = () => {
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box
                    className=" footer"
                    sx={{
                        color: '#666666',
                        textAlign: 'center',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '1.57',
                        position: 'absolute',
                        bottom: 0,
                    }}
                >
                    Copyright © {new Date().getFullYear()} Coin of Kin. All rights reserved.
                </Box>
            </Box>
        </>
    );
};

export default Footer;

