import axios from 'axios';
import CustomToast from '../components/Common/CustomToast';
import api from './Index';
import microserviceApi from './testMicroservice';
import { BASE_TEST_FUND_ADDRESS } from '../config/Constants';
import { toast } from 'react-hot-toast';

const ApiUtils = {
    authLogin: async function (params: any) {
        try {
            const response = await api.post('/api/User/Login', params);
            return response;
        } catch (error: any) {
            throw error.response;
        }
    },
    updateUserPassword: async function (params: any) {
        try {
            const response = await api.post('/api/User/UpdateUserPassword', params);
            return response;
        } catch (error: any) {
            throw error.response;
        }
    },
    forgotPassword: async function (params: any) {
        try {
            const response = await api.post('/api/User/ForgotPassword', params);
            return response;
        } catch (error: any) {
            throw error.response;
        }
    },
    resetPassword: async function (params: any) {
        try {
            const response = await api.post('/api/User/ResetPassword', params);
            return response;
        } catch (error: any) {
            throw error.response;
        }
    },
    getPlansList: async function () {
        try {
            const response = await api.get('/api/Plan/GetPlanList');
            return response;
        } catch (error: any) {
            throw error.response;
        }
    },
    getWalletToken: async function (
        walletID: string,
        fromValue: any = null,
        toValue: any = null,
        label: any = null
    ) {
        try {
            let url = `/api/Wallet/GetWalletTokens?walletID=${walletID}`;
            if (fromValue) {
                url += `&fromValue=${fromValue}`;
            }
            if (toValue) {
                url += `&toValue=${toValue}`;
            }
            if (label) {
                url += `&label=${label}`;
            }
            const response = await api.get(url);
            return response?.data;
        } catch (error: any) {
            throw error.response;
        }
    },

    getLabelList: async function () {
        try {
            const response = await api.get('/api/Label/GetLabelList');
            return response?.data;
        } catch (error: any) {
            throw error.response;
        }
    },

    addBeneficiary: async function (params: any) {
        try {
            const response = await api.post('/api/Beneficiary/AddBeneficiary', params);
            return response?.data;
        } catch (error: any) {
            throw error.response;
        }
    },
    addBenPhoto: async function (params: any) {
        try {
            const formData = new FormData();
            const blob = await fetch(`data:${params.file}`).then((res) => res.blob());
            formData.append('file', blob, 'filename.jpg');
            const response = await api.post(
                `/api/User/UploadBeneficiaryPhoto?beneficiaryID=${params.beneficiaryID}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            return response?.data;
        } catch (error: any) {
            throw error.response;
        }
    },
    addUserPhoto: async function (params: any) {
        try {
            const formData = new FormData();
            const blob = await fetch(`data:${params.file}`).then((res) => res.blob());
            formData.append('file', blob, 'filename.jpg');
            const response = await api.post(`/api/User/UploadUserPhoto`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response?.data;
        } catch (error: any) {
            throw error.response;
        }
    },

    addUser: async function (params: any) {
        try {
            const response = await api.post('/api/User/AddUser', params);
            return response?.data;
        } catch (error: any) {
            throw error.response;
        }
    },
    getCurrentUser: async function () {
        try {
            const response = await api.get('/api/User/GetCurrentUserInfo');
            return response?.data;
        } catch (error: any) {
            throw error.response;
        }
    },
    UpdateUser: async function (params: any) {
        try {
            const response = await api.post('/api/User/UpdateUser', params);
            return response?.data;
        } catch (error: any) {
            throw error.response;
        }
    },
    uploadUserPhoto: async function (params: any) {
        try {
            const formData = new FormData();
            const blob = await fetch(`data:${params.file}`).then((res) => res.blob());
            formData.append('file', blob, 'filename.jpg');
            const response = await api.post(`/api/User/UploadUserPhoto`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response?.data;
        } catch (error: any) {
            throw error.response;
        }
    },
    addBeneficiaryAsset: async function (params: any) {
        try {
            const response = await api.post('/api/BeneficiaryAsset/AddBeneficiaryAsset', params);
            return response?.data;
        } catch (error: any) {
            throw error.response;
        }
    },
    updateBeneficiaryAsset: async function (params: any) {
        try {
            const response = await api.post('/api/BeneficiaryAsset/UpdateBeneficiaryAsset', params);
            return response?.data;
        } catch (error: any) {
            throw error.response;
        }
    },
    deleteBeneficiaryAsset: async function (params: any) {
        try {
            const response = await api.post('/api/BeneficiaryAsset/DeleteBeneficiaryAsset', params);
            return response?.data;
        } catch (error: any) {
            throw error.response;
        }
    },
    addWallet: async function (params: any) {
        try {
            const response = await api.post('/api/Wallet/AddWallet', params);
            return response?.data;
        } catch (error: any) {
            throw error.response;
        }
    },
    updateBeneficiary: async function (params: any) {
        try {
            const response = await api.post('/api/Beneficiary/UpdateBeneficiary', params);
            CustomToast({ message: 'Beneficiary successfully updated', severity: 'success' });
            return response?.data;
        } catch (error: any) {
            CustomToast({ message: 'Something went wrong', severity: 'error' });
            throw error.response;
        }
    },
    updateWallet: async function (params: any) {
        try {
            const response = await api.post('/api/Wallet/UpdateWallet', params);
            CustomToast({ message: 'Wallet successfully Updated', severity: 'success' });
            return response?.data;
        } catch (error: any) {
            CustomToast({ message: 'Something went wrong', severity: 'error' });
            throw error.response;
        }
    },
    updateContractAsSubmitted: async function () {
        try {
            const response = await api.post('/api/Contracts/UpdateContractAsSubmitted');
            return response?.data;
        } catch (error: any) {
            throw error.response;
        }
    },
    updateContractAsCancelled: async function () {
        try {
            const response = await api.post('/api/Contracts/UpdateContractAsCancelled');
            return response?.data;
        } catch (error: any) {
            throw error.response;
        }
    },
    submitContract: async function () {
        try {
            const response = await api.post('/api/Contracts/CKSecureFund20');
            return response?.data;
        } catch (error: any) {
            CustomToast({ message: 'Something went wrong', severity: 'error' });
            throw error.response;
        }
    },
    updateBeneficiaryAssetList: async function (params: any) {
        try {
            const response = await api.post(
                '/api/BeneficiaryAsset/UpdateBeneficiaryAssetList',
                params
            );
            CustomToast({
                message: 'Beneficiary AssetList successfully updated',
                severity: 'success',
            });
            return response?.data;
        } catch (error: any) {
            CustomToast({ message: 'Something went wrong', severity: 'error' });
            throw error.response;
        }
    },
    addWalletAssets: async function (params: any) {
        try {
            const response = await api.post('/api/Wallet/AddWalletToken', params);
            return response?.data;
        } catch (error: any) {
            throw error.response;
        }
    },
    updateWalletAssets: async function (params: any) {
        try {
            const response = await api.post('/api/Wallet/UpdateTokenWallet', params);
            return response?.data;
        } catch (error: any) {
            throw error.response;
        }
    },
    getPaymentHistoryList: async function () {
        try {
            const response = await api.get('/api/PaymentHistory/GetPaymentHistoryList');
            return response?.data;
        } catch (error: any) {
            throw error.response;
        }
    },

    getBeneficiaryList: async function (
        fromValue: any = null,
        toValue: any = null,
        label: any = null
    ) {
        try {
            let url = '/api/Beneficiary/SearchBeneficiaryList';
            if (fromValue) {
                url += `&fromValue=${fromValue}`;
            }
            if (toValue) {
                url += `&toValue=${toValue}`;
            }
            if (label) {
                url += `&label=${label}`;
            }
            const response = await api.get(url);
            return response?.data;
        } catch (error: any) {
            throw error.response;
        }
    },

    getWalletBeneficiaryList: async function (
        walletID: string,
        fromValue: any = null,
        toValue: any = null,
        label: any = null
    ) {
        try {
            let url = '/api/Beneficiary/GetWalletBeneficiaryList?walletID=' + walletID;
            if (fromValue) {
                url += `&fromValue=${fromValue}`;
            }
            if (toValue) {
                url += `&toValue=${toValue}`;
            }
            if (label) {
                url += `&label=${label}`;
            }
            const response = await api.get(url);
            return response?.data;
        } catch (error: any) {
            throw error.response;
        }
    },

    getAllBeneficiaryList: async function () {
        try {
            const response = await api.get('/api/Beneficiary/GetBeneficiaryList');
            return response?.data;
        } catch (error: any) {
            throw error.response;
        }
    },

    getUserWallets: async function () {
        try {
            const response = await api.get('/api/Wallet/GetWalletList');
            return response?.data;
        } catch (error: any) {
            throw error.response;
        }
    },

    updateContract: async function (walletID: any) {
        try {
            const response = await api.post('/api/Wallet/UpdateContract' + `?walletId=${walletID}`);
            return response?.data;
        } catch (error: any) {
            throw error.response;
        }
    },
    deleteWallet: async function (walletData: any) {
        try {
            const response = await api.post('api/Wallet/DeleteWallet?walletId=' + walletData);
            return response?.status;
        } catch (error: any) {
            throw error.response;
        }
    },
    getManagePaymentLink: async function () {
        try {
            const response = await api.get('/api/Payment/ConsumerPortal');
            return response?.data;
        } catch (error: any) {
            throw error.response;
        }
    },
    getMoralisMessageInfo: async function (data: any) {
        try {
            const response = await api.post('/api/AccountSetting/GetMoralisMessageInfo', data);
            return response?.data;
        } catch (error: any) {
            throw error.response;
        }
    },
    authenticateWithWallet: async function (data: any) {
        try {
            const response = await api.post('/api/AccountSetting/AuthenticateWithWallet', data);
            return response?.data;
        } catch (error: any) {
            throw error.response;
        }
    },

    getFunds: async function (address: any) {
        try {
            return [(await microserviceApi.get('/Contracts/CKFund20/' + address)).data['result']];
        } catch (error: any) {
            throw error.response;
        }
    },
    // submitContract: async function () {
    //     try {
    //         const response = await api.post('/deploy/CKFund20');
    //         return response?.data;
    //     } catch (error: any) {
    //         throw error.response;
    //     }
    // },
    getContractStatus: async function (address: any) {
        try {
            return [
                (await microserviceApi.get('/contracts/deploy/status/' + address)).data['result'],
            ];
        } catch (error: any) {
            throw error.response;
        }
    },
};

export default ApiUtils;

