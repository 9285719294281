import * as yup from 'yup';

const UpdatePasswordValidationSchema = yup.object({
    oldPassword: yup.string().required('Old Password is required'),
    newPassword: yup
        .string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .test(
            'password-validation',
            'Password must contain at least one uppercase letter',
            (value) => /^(?=.*?[A-Z])/.test(value)
        )
        .test(
            'password-validation',
            'Password must contain at least one lowercase letter',
            (value) => /^(?=.*?[a-z])/.test(value)
        )
        .test('password-validation', 'Password must contain at least one number', (value) =>
            /^(?=.*?[0-9])/.test(value)
        )
        .test(
            'password-validation',
            'Password must contain at least one special character',
            (value) => /^(?=.*?[#?!@$%^&*-])/.test(value)
        ),
});

export { UpdatePasswordValidationSchema };

