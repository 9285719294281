import { useEffect, useState } from 'react';
import {
    useAccount,
    useBalance,
    useChains,
    useSendTransaction,
    useSwitchChain,
    useWaitForTransactionReceipt,
    useWriteContract,
} from 'wagmi';
import { hardhat } from '../../config/wagmi';
import ApiUtils from '../../api/ApiUtils';
import { useNavigate } from 'react-router-dom';
import { Address, formatEther } from 'viem';
import { Box, Button, Input, Modal, Slider, Stack } from '@mui/material';
import { Label } from '@mui/icons-material';
import { CKFund, CKFundBase, percToShares, sharesToPerc } from '../../services/web3';
type BalanceProps = {
    address: Address;
};

function Loading(props: any) {
    if (props.error) {
        return (
            <div
                style={{
                    color: 'red',
                    position: 'fixed',
                    width: '100vh',
                    textAlign: 'center',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: '0px',
                    left: '0px',
                }}
            >
                Error: {props.error}
            </div>
        );
    }

    if (props.hidden) {
        return null;
    }

    return (
        <div
            style={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                color: 'white',
                zIndex: 1000,
                position: 'fixed',
                textAlign: 'center',
                fontSize: '2rem',
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: '0px',
                left: '0px',
                right: '0px',
                bottom: '0px',
            }}
        >
            In Progress...
        </div>
    );
}

function Balance(props: BalanceProps) {
    const { address } = props;

    const { data: balance } = useBalance({ address });

    if (!balance) {
        return <div>Loading...</div>;
    }

    const valueInEth = formatEther(balance.value);

    return (
        <div>
            <p>Your address: {address}</p>
            <p>
                Balance: {valueInEth} {balance?.symbol}
            </p>
        </div>
    );
}

function AddressPill(props: any) {
    return (
        <small
            style={{
                backgroundColor: 'lightgrey',
                borderRadius: '1rem',
                padding: '0.3rem',
                display: 'inline-block',
                flexDirection: 'row',
            }}
        >
            {props.address.substring(0, 6)}
        </small>
    );
}

type BeneficiaryProps = {
    address: Address;
    share: number;
    added: boolean;
    canRemove: boolean;
    handleUpdate: (beneficiary: any) => void;
    handleRemove: (address: Address) => void;
};

function Beneficiary(props: BeneficiaryProps) {
    const [share, setShare] = useState(props.share);

    const handleShareChange = (e: any, v: any) => {
        if (v < 0 || v > 100) {
            return;
        }

        setShare(v);
        props.handleUpdate({ share: v });
    };

    const handleAddressChange = (e: any) => {
        props.handleUpdate({ address: e.target.value });
    };

    useEffect(() => {
        setShare(props.share);
    }, [props.share]);

    return (
        <Box>
            <Stack direction="row" spacing={2} alignItems="center">
                <Input
                    type="text"
                    defaultValue={props.address}
                    disabled={!props.added}
                    onChange={handleAddressChange}
                />

                <Stack direction="row" spacing={2} minWidth={300} alignItems={'center'}>
                    <Slider
                        value={props.share}
                        onChange={handleShareChange}
                        aria-label="Default"
                        valueLabelDisplay="auto"
                        step={1}
                        marks
                        min={0}
                        max={100}
                    />
                    <Input
                        type="number"
                        value={props.share}
                        onChange={(e) => handleShareChange(e, Number(e.target.value))}
                    />
                </Stack>

                {props.canRemove && (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => props.handleRemove(props.address)}
                    >
                        Remove
                    </Button>
                )}
            </Stack>
        </Box>
    );
}

type TransferProps = {
    address: Address;
    isWithdraw: boolean;
    open: boolean;
    onClose: () => void;
};

function TransferModal(props: any) {
    const [amount, setAmount] = useState(0);

    const { writeContract, isError, isSuccess, isPending } = useWriteContract();

    const handleDeposit = () => {
        if (props.isWithdraw) {
            // TODO: weird eslint bug that gives "Expected 0 arguments ..."
            (writeContract as any)(CKFund.withdraw({ fundAddress: props.address, amount }));
        } else {
            // TODO: weird eslint bug that gives "Expected 0 arguments ..."
            (writeContract as any)(CKFund.deposit({ fundAddress: props.address, amount }));
        }
    };

    useEffect(() => {
        if (isSuccess) {
            props.onClose();
            window.location.reload();
        }
    }, [isSuccess]);

    return (
        <>
            <Modal
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem',
                        boxShadow: 24,
                        p: 4,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    <small>Amount</small>
                    <Input
                        type="number"
                        onChange={(e) => setAmount(Number(e.target.value))}
                        defaultValue={0}
                    />
                    <Button variant="contained" color="primary" onClick={handleDeposit}>
                        {props.isWithdraw ? 'Withdraw' : 'Deposit'}
                    </Button>
                </Box>
            </Modal>
            <Loading hidden={!isPending} /> 
        </>
    );
}

type BeneficiaryListProps = {
    fundAddress: Address;
    beneficiaries: { address: Address; share: number }[];
};

function BeneficiaryList(props: BeneficiaryListProps) {
    const { fundAddress } = props;

    const propsSharesInPerc = sharesToPerc(props.beneficiaries).map((beneficiary) => ({
        ...beneficiary,
        added: false,
    }));

    const [beneficiaries, setBeneficiaries] = useState(propsSharesInPerc);
    const [isDraft, setIsDraft] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [removingAddress, setRemovingAddress] = useState<any>(false);
    const [invalidShares, setInvalidShares] = useState(false);
    const { writeContract, isError, isSuccess, isPending, error } = useWriteContract();

    const updateBeneficiary = (index: number, beneficiary: any) => {
        console.log(beneficiary);
        const newBeneficiaries = [...beneficiaries];
        newBeneficiaries[index] = { ...newBeneficiaries[index], ...beneficiary };
        setBeneficiaries(newBeneficiaries);
        setIsDraft(true);
    };

    const removeBeneficiary = (index: number, address: Address) => {
        const newBeneficiaries = [...beneficiaries];
        if (!newBeneficiaries[index].added) {
            setRemovingAddress(address);
        } else {
            setRemovingAddress(false);
            setIsAdding(false);
        }

        newBeneficiaries.splice(index, 1);

        setBeneficiaries(newBeneficiaries);
        setIsDraft(true);
    };

    const addBeneficiary = () => {
        const uniqueAddress = ('0x' + Math.random().toString(36).substring(7)) as Address;

        setBeneficiaries([...beneficiaries, { address: uniqueAddress, share: 0, added: true }]);
        setIsDraft(true);
        setIsAdding(true);
    };

    const handleCancel = () => {
        setIsDraft(false);
        setIsAdding(false);
        setRemovingAddress(false);
        setBeneficiaries([...propsSharesInPerc]);
    };

    const handleConfirm = () => {
        const sharesOriginalValues = percToShares(beneficiaries);
        console.log(sharesOriginalValues);
        // TODO: add more checks and handling edge cases

        // check if need to remove, add or update
        if (isAdding) {
            // add
            const addedBeneficiary = beneficiaries.find((beneficiary) => beneficiary.added);
            if (!addedBeneficiary) {
                return;
            }

            const params = CKFundBase.addBeneficiary({
                fundAddress,
                newBeneficiaryAddress: addedBeneficiary.address,
                beneficiariesAndShares: sharesOriginalValues,
            });

            // TODO: weird eslint bug that gives "Expected 0 arguments ..."
            (writeContract as any)(params);
        } else if (removingAddress) {
            // remove
            console.log('Removing Beneficiary');
            const params = CKFundBase.removeBeneficiary({
                fundAddress,
                beneficiaryAddress: removingAddress,
                beneficiariesAndShares: sharesOriginalValues,
            });

            (writeContract as any)(params);
        } else {
            // update
            console.log('Updating Beneficiaries');
            const params = CKFundBase.updateBeneficiaries({
                fundAddress,
                beneficiariesAndShares: sharesOriginalValues,
            });

            (writeContract as any)(params);
        }

    };

    console.log(error);

    useEffect(() => {
        const sum = beneficiaries.reduce((acc, beneficiary) => acc + beneficiary.share, 0);
        setInvalidShares(
            sum !== 100 || beneficiaries.some((beneficiary) => beneficiary.share == 0)
        );
    }, [beneficiaries]);

    useEffect(() => {
        if (isSuccess) {
            window.location.reload();
        }
    }
    , [isSuccess]);

    const action = isAdding
        ? 'Adding Beneficiary'
        : removingAddress
        ? 'Removing Beneficiary'
        : false;

    return (
        <Box>
            <h2>Beneficiaries</h2>
            <small style={{ color: 'grey' }}>{action}</small>
            {beneficiaries.map((beneficiary, index) => (
                <Beneficiary
                    key={beneficiary.address}
                    {...beneficiary}
                    handleUpdate={(share: number) => updateBeneficiary(index, share)}
                    handleRemove={() => removeBeneficiary(index, beneficiary.address)}
                    canRemove={
                        (!isAdding && !removingAddress && beneficiaries.length > 1) ||
                        beneficiary.added
                    }
                />
            ))}

            {!isAdding && !removingAddress && (
                <Button variant="contained" color="primary" onClick={addBeneficiary}>
                    Add Beneficiary
                </Button>
            )}

            {isDraft && (
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleConfirm}
                        disabled={invalidShares}
                    >
                        Save
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleCancel}>
                        Cancel
                    </Button>
                    {invalidShares && (
                        <small style={{ color: 'red' }}>
                            Shares must sum to 100% and be non-zero
                        </small>
                    )}
                </div>
            )}

        <p style={{color:"grey", padding:"1rem"}}>
            <small style={{fontWeight:"bold"}}>DEBUG ONLY, actual shares: </small>
            {JSON.stringify(percToShares(beneficiaries))}
        </p>
        <Loading hidden={!isPending} />
        </Box>
    );
}

type AssetProps = {
    address: Address;
    type: string;
    issuer: Address;
    beneficiaries: { address: Address; share: number }[];
    endTime: string;
    maxBeneficiaries: number;
    totalPayments: number;
    token: { type: string };
    balance: number;
};

function Asset(props: AssetProps) {
    const [modalMode, setModalMode] = useState<any>(undefined);
    const handleModalClose = () => setModalMode(undefined);

    return (
        <Box
            sx={{
                border: '1px solid black',
                padding: '1rem',
            }}
        >
            <details>
                <summary
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '1rem',
                        justifyContent: 'space-between',
                    }}
                >
                    <h2>{props.token.type}</h2>
                    <small>
                        Liquidates on {new Date(Date.parse(props.endTime)).toLocaleDateString()}
                    </small>
                    <span>
                        Beneficiaries{' '}
                        {props.beneficiaries.map((beneficiary, index) => (
                            <AddressPill key={beneficiary.address} address={beneficiary.address} />
                        ))}
                    </span>
                    <span
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '1rem',
                            alignItems: 'center',
                        }}
                    >
                        Deposited Balance{' '}
                        <b>
                            {formatEther(BigInt(props.balance))} {props.token.type}
                        </b>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setModalMode('withdraw')}
                        >
                            Withdraw
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setModalMode('deposit')}
                        >
                            Deposit
                        </Button>
                    </span>
                    <small>Click to view details</small>
                </summary>
                <hr />
                <BeneficiaryList beneficiaries={props.beneficiaries} fundAddress={props.address} />
            </details>
            <TransferModal
                open={modalMode !== undefined}
                onClose={handleModalClose}
                address={props.address}
                isWithdraw={modalMode === 'withdraw'}
            />
        </Box>
    );
}

type AssetsProps = {
    address: Address;
};

function Assets(props: AssetsProps) {
    const { address } = props;

    const [assets, setAssets] = useState<any[]>([]);

    useEffect(() => {
        ApiUtils.getFunds({ address }).then((response) => {
            setAssets(response);
        });
    }, []);

    return (
        <>
            <h1>Assets</h1>
            {assets.map((asset, index) => (
                <Asset key={index} {...asset} />
            ))}
        </>
    );
}

function AssetsMockup() {
    const navigate = useNavigate();
    const { switchChain } = useSwitchChain();
    const { isConnected, address } = useAccount();

    useEffect(() => {
        switchChain({ chainId: hardhat.id });
    }, [switchChain]);

    useEffect(() => {
        if (!isConnected) {
            navigate('/login');
        }
    }, [isConnected, navigate]);

    if (!isConnected || !address || address === undefined) {
        return (
            <div>
                <h1>Connect your wallet</h1>
            </div>
        );
    }

    return (
        <>
            <Balance address={address} />
            <Assets address={address} />
        </>
    );
}

export default AssetsMockup;

