import axios from 'axios';
import { BASE_BASE_TEST_MICOSERVICE_URL } from '../config/Constants';
// import {store} from "../redux/store";

const instance = axios.create({
    baseURL: BASE_BASE_TEST_MICOSERVICE_URL,
    headers: {
        // Accept: 'multipart/form-data',
        'Content-Type': 'application/json',
        // 'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
    },
    withCredentials: false
});

export default instance;

