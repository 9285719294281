import { Box, Menu, MenuItem, Grid, Typography, Button } from '@mui/material';
import Slider from '../Common/Slider';
import { SettingIcon, SelectArrow } from '../../config/Images';
import Footer from '../CommonLayout/Footer';
import { redirect, useLocation, useNavigate } from 'react-router-dom';
import ApiUtils from '../../api/ApiUtils';
import toast from 'react-hot-toast';
import { useContext, useEffect, useState } from 'react';
import { CurrencyContext, CurrentUserContext, WalletContext } from '../CommonLayout/context';
import CustomToast from '../Common/CustomToast';
function SplitAssets() {
    const navigate = useNavigate();
    const currency = useContext(CurrencyContext);
    const currentUser = useContext(CurrentUserContext);
    const walletID = useContext(WalletContext);
    const location = useLocation();
    const [benID, setBenID] = useState('');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [disableBTN, setDisableBTN] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [userAssets, setUserAssets] = useState<any>([]);

    useEffect(() => {
        if (location && location.state && walletID && location.state.benID) {
            setBenID(location.state.benID);
            const getData = async () => {
                const data = await ApiUtils.getWalletToken(walletID);
                setUserAssets([...data]);
            };
            getData();
        } else {
            redirect('/assets');
        }
    }, [location, setUserAssets]);
    const updateData: any = [];
    const handleAssignAssets = async () => {
        try {
            if (showErr) {
                setDisableBTN(true);
            } else {
                const resp = await ApiUtils.updateBeneficiaryAssetList([...updateData]);
                navigate('/assets', { state: { ...location.state } });
            }
        } catch (error) {
            console.log(error);
        }
    };
    const [showErr, setShowError] = useState(false);
    const handleValueChange = (asset: any, value: any) => {
        setDisableBTN(false);
        let tmpErr = false;
        for (let i = 0; i < userAssets.length; i++) {
            let total = 0;
            for (let j = 0; j < userAssets[i]?.associatedBeneficiariesModel?.length; j++) {
                if (userAssets[i].associatedBeneficiariesModel[j].beneficiaryID != benID) {
                    total += userAssets[i].associatedBeneficiariesModel[j].percentageGiven;
                }
            }
            if (total + value > 100) {
                tmpErr = true;
            }
        }
        if (tmpErr) {
            setShowError(true);
        } else {
            setShowError(false);
            updateData.push({
                beneficiaryId: benID,
                walletTokenId: asset.id,
                percentageGiven: value,
            });
        }
    };

    return (
        <>
            <Box
                mb={3}
                className="profile-box"
                sx={{
                    borderRadius: '16px',
                    backgroundColor: 'rgb(255, 255, 255)',
                    boxShadow: 'rgba(153, 153, 153, 0.12) 0px 6px 20px 0px',
                    padding: '0px 0px 20px 0px',
                }}
            >
                <Box
                    className="create"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '20px 24px',
                        boxShadow: 'rgba(153, 153, 153, 0.12) 0px 6px 20px 0px',
                        borderRadius: '16px',
                        backgroundColor: 'rgb(255, 255, 255)',
                    }}
                >
                    <Box
                        className="create_beneficiary"
                        sx={{
                            fontWeight: 700,
                            fontSize: '21px',
                            display: 'flex',
                            gap: '12px',
                            alignItems: 'center',
                        }}
                    >
                        Split Assets
                        <span style={{ fontSize: '11px', fontWeight: '400' }}>
                            NFTs cannot be split
                        </span>
                    </Box>

                    {/* <Box
                        className="header"
                        sx={{
                            backgroundColor: 'rgba(188, 188, 188, 0.1)',
                            color: 'black',
                            padding: '9px 20px',
                            borderRadius: '20px',
                            cursor: 'pointer',
                            display: 'flex',
                        }}
                        onClick={handleClick}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: '50px',
                                fontSize: '15px',
                                fontWeight: '500',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '8px',
                                    // justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <img src={SettingIcon} alt="settingIcon"></img>
                                Settings
                            </Box>
                            <Box>
                                <img
                                    src={SelectArrow}
                                    alt="SelectArrow"
                                    width={'14px'}
                                    height={'14px'}
                                ></img>
                            </Box>
                        </Box>
                    </Box>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <Box
                            sx={{
                                padding: '16px 20px',
                                // width: '100%',
                                minWidth: '200px',
                                boxShadow: 'none',
                            }}
                        >
                            <MenuItem onClick={handleClose}>Test</MenuItem>
                        </Box>
                    </Menu> */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'right',
                            marginRight: '2rem',
                        }}
                    >
                        <Box
                            className="confirm"
                            sx={{
                                maxWidth: '50px',
                                color: '#00000',
                                backgroundColor: '#D3D3D3',
                                borderRadius: '20px',
                                padding: '10px 30px',
                                fontSize: '14px',
                                fontWeight: '500',
                                // display: 'flex',
                                // justifyContent: 'left',
                                alignItems: 'center',
                                cursor: 'pointer',
                                // float: 'inline-end',
                                margin: '0rem 0rem 0 1rem',
                                // marginLeft: 'auto',
                                '&:hover': {
                                    backgroundColor: 'rgba(25, 25, 25, 0.02)',
                                },
                            }}
                            onClick={() => {
                                navigate('/assets');
                            }}
                        >
                            Cancel
                        </Box>
                        <Box
                            className="confirm"
                            sx={{
                                maxWidth: '50px',
                                color: '#FFFF',
                                backgroundColor: 'black',
                                borderRadius: '20px',
                                padding: '10px 30px',
                                fontSize: '14px',
                                fontWeight: '500',
                                // display: 'flex',
                                // justifyContent: 'left',
                                alignItems: 'center',
                                cursor: 'pointer',
                                // float: 'inline-end',
                                margin: '0rem 0rem 0 1rem',
                                // marginLeft: 'auto',
                                '&:hover': {
                                    opacity: '0.6',
                                },
                            }}
                            onClick={() => {
                                handleAssignAssets();
                            }}
                        >
                            Confirm
                        </Box>
                    </Box>
                    {disableBTN && (
                        <Typography
                            sx={{
                                float: 'inline-end',
                                fontWeight: '600',
                                margin: '2rem 2rem 0 2rem',
                            }}
                        >
                            {'Note: Assets Distribution can not be more than 100%'}
                        </Typography>
                    )}
                </Box>
                {userAssets &&
                    userAssets.map((asset: any) => {
                        return (
                            <UserSlider
                                asset={asset}
                                handleValueChange={handleValueChange}
                                benID={benID}
                            />
                        );
                    })}
            </Box>
            {currentUser && !currentUser.isCustomer && (
                <Box sx={{ marginTop: '2rem' }} className="upgrade" textAlign={'center'}>
                    <Grid
                        container
                        spacing={3}
                        sx={{
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Grid item lg={7} md={12} sm={12}>
                            <Typography
                                sx={{
                                    fontSize: '1.5rem',
                                    lineHeight: '1.75rem',
                                    fontWeight: '600',
                                    margin: '0px',
                                }}
                            >
                                Choose a plan that is the best for you
                            </Typography>
                            <Typography
                                sx={{
                                    margin: '24px 0px',
                                    fontSize: '0.875rem',
                                    fontWeight: '400',
                                    lineHeight: '1.75',
                                }}
                            >
                                Select the plan that fits your needs and your wallet! Enjoy our
                                subscription service
                            </Typography>
                            <Button
                                className="btn_opacity"
                                style={{
                                    backgroundColor: '#191919',
                                    borderRadius: '20px',
                                    color: '#fff',
                                    padding: '6px 20px',
                                    textTransform: 'capitalize',
                                }}
                                onClick={() => window.open('/subscription', '_blank')}
                            >
                                Subscription
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            )}

            <Footer />
        </>
    );
}
const UserSlider = (props: any) => {
    const { asset, handleValueChange, benID } = props;
    let data: any = {};
    console.log('PROPS', props);
    if (asset.associatedBeneficiariesModel && asset.associatedBeneficiariesModel?.length) {
        for (let i = 0; i < asset.associatedBeneficiariesModel.length; i++) {
            if (benID == asset.associatedBeneficiariesModel[i].beneficiaryID) {
                data = asset.associatedBeneficiariesModel[i];
            }
        }
    }

    return (
        <>
            <Box
                className="split"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '20px 24px',
                    borderRadius: '16px',
                }}
            >
                <Box mt={2}>
                    <Typography
                        align="center"
                        sx={{
                            minWidth: '10rem',
                            backgroundColor: '#00000014',
                            borderRadius: '16px',
                            fontWeight: '500',
                            padding: '4px 12px',
                        }}
                        id="demo-simple-select-label"
                    >
                        {asset.name} &rarr; {asset.balance}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Slider
                    value={data.percentageGiven ? data.percentageGiven : 0}
                    onChangeCallBack={(value: any) => {
                        handleValueChange(asset, value);
                    }}
                    balance={asset.balance}
                    title={asset.name}
                />
            </Box>
        </>
    );
};
export default SplitAssets;

