import { Alert, AlertTitle, Typography } from '@mui/material';
import toast from 'react-hot-toast';

export default function CustomToast(props: any) {
    const { severity, message } = props;
    return toast.custom(
        (t: any) => (
            <Alert
                sx={{ zIndex: 99999999999999 }}
                variant="filled"
                severity={severity}
                onClose={async (e) => {
                    toast.remove(t.id);
                }}
            >
                {message}
            </Alert>
        ),
        {
            duration: 6000,
            position: 'top-right',
        }
    );
}

