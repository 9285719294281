import Web3 from 'web3';
import ESCROW_ABI_JSON from './ESCROW_ABI.json';
import TOKEN_JSON from './TOKEN.json';
import ApiUtils from '../../api/ApiUtils';
const web3 = new Web3(window.ethereum);

const ESCROW_ABI: any = ESCROW_ABI_JSON;
const TOKEN_ABI: any = TOKEN_JSON;
const ESCROW_ADDRESS = '0x8261fA2c97daa2Da5663f34c31515cDc21b6E38a';

const escrowContract = new web3.eth.Contract(ESCROW_ABI, ESCROW_ADDRESS);
const createTokenContract = async (tokenAddress: string) => {
    return await new web3.eth.Contract(TOKEN_ABI, tokenAddress);
};
const tokenContract = new web3.eth.Contract(TOKEN_ABI, ESCROW_ADDRESS);
const createTask = async (
    amountInWei: string,
    executionTime: Date,
    publicAddress: any,
    tokenAddress: any,
    cryptoAddress: any
) => {
    const data = await escrowContract.methods
        .createUserTask(
            amountInWei,
            Math.floor(executionTime.getTime() / 1000),
            publicAddress,
            tokenAddress
        )
        .send({
            from: cryptoAddress,
            value: amountInWei,
        });
    return data;
};
const cancelTask = async (taskContractId: any, cryptoAddress: string, benData: any) => {
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    const taskIndex: bigint = BigInt(taskContractId); // Replace with the actual task index
    await escrowContract.methods.cancelTask(taskIndex).send({ from: cryptoAddress });
};
const approve = async (tokenAddress: string, walletAddress: string) => {
    const contract = await createTokenContract(tokenAddress);
    const spenderAddress = ESCROW_ADDRESS; // This should be the spender address (e.g., escrow contract address)
    const maxUint256 =
        '115792089237316195423570985008687907853269984665640564039457584007913129639935'; // Maximum uint256 value

    try {
        const resp = await contract.methods
            .approve(spenderAddress, maxUint256)
            .send({ from: walletAddress });
        return resp;
    } catch (error) {
        console.error('Error approving tokens:', error);
        throw error;
    }
};

const allowance = async (tokenAddress: string, walletAddress: string) => {
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    const contract = await createTokenContract(tokenAddress);
    const resp = await contract.methods.allowance(walletAddress, ESCROW_ADDRESS).call();
    return resp;
};
export { web3, escrowContract, createTask, cancelTask, approve, allowance };

