import {
    Autocomplete,
    Avatar,
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Slider,
    TextField,
    Typography,
} from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import ApiUtils from '../../api/ApiUtils';
import { BASE_IMG_URL } from '../../config/Constants';
import { WalletContext } from '../CommonLayout/context';
import UserImage from '../../assets/images/eth.png';

function BeneficiaryPopup(props: any) {
    // const walletTokData = [...props.walletTok];
    const [showDrop, setShowDrop] = useState(false);
    const [disableBTN, setDisableBTN] = useState(false);

    const handleToggleDrop = () => {
        setShowDrop((prev) => !prev);
    };
    const handleUpdate = async () => {
        try {
            let callAPI = true;
            for (let j = 0; j < assetsData.length; j++) {
                let tokenDistribution: any = [];
                let tempPercent = 0;
                const benData = assetsData[j];
                for (let i = 0; i < walletTokData.length; i++) {
                    if (walletTokData[i].id == String(benData.walletTokenID).toLowerCase()) {
                        tokenDistribution = [
                            ...(walletTokData[i].associatedBeneficiariesModel || []),
                        ];
                    }
                }
                for (let i = 0; i < tokenDistribution.length; i++) {
                    tempPercent = tempPercent + tokenDistribution[i].percentageGiven;
                }
                if (tempPercent + assetsData[j].percentageGiven > 100) {
                    callAPI = false;
                    setDisableBTN(true);
                }
            }
            if (callAPI) {
                const resp = await ApiUtils.updateBeneficiaryAssetList(assetsData);
                await props.handleUpdate();
                await props.setResult(false);
            }
        } catch (error) {
            console.log(error);
        }
    };
    // ONCLOSE DROPDOWN
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setShowDrop(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const [assetsData, setAssetsData] = useState<any>(props.data);
    const [walletTokData, setWalletTokData] = useState<any>(props.walletTok || []);
    useEffect(() => {
        setAssetsData(props.data);
    }, [props.data]);
    useEffect(() => {
        setWalletTokData(props.walletTok);
    }, [props.walletTok]);
    return (
        <React.Fragment>
            <Dialog maxWidth="md" fullWidth={true} open={props.result}>
                <DialogTitle
                    className="asset_popup"
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: '23px',
                        fontWeight: '600',
                        alignItems: 'center',
                    }}
                >
                    <Box className="asset_popup" sx={{ display: 'flex', gap: '24px' }}>
                        <Box
                            className="assets_wrap_popup"
                            sx={{ fontSize: '24px', fontWeight: '600' }}
                        >
                            {props.row.beneficiaryName} ({props.row.nickName})
                        </Box>
                        <Box sx={{ display: 'flex', gap: '8px' }}>
                            <Box
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: '600',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {props.row.balanceUSD &&
                                    `(USD ${parseFloat(props.row.balanceUSD).toFixed(4)})`}
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                        {showDrop && (
                            <div ref={dropdownRef}>
                                <AutoCompleteRow
                                    alreadyAssign={assetsData}
                                    walletBen={props.walletTok}
                                    keyName={'name'}
                                    labelName={'Assets'}
                                    apiFor={'add-assets'}
                                    benID={props.row.id}
                                    setPopUpdate={setAssetsData}
                                />
                            </div>
                        )}
                        <Box
                            onClick={handleToggleDrop}
                            className="add_beneficiary"
                            sx={{
                                color: '#4EC17B',
                                border: '2px solid rgba(78, 193, 123, 0.5)',
                                fontSize: '14px',
                                padding: '6px 20px',
                                borderRadius: '20px',
                                cursor: 'pointer',
                                fontWeight: '500',
                                '&:hover': {
                                    border: '2px solid rgb(78, 193, 123)',
                                },
                            }}
                        >
                            Add Assets
                        </Box>
                        <CloseIcon
                            className="close_icon"
                            onClick={() => {
                                props.setResult(false);
                            }}
                            sx={{ cursor: 'pointer' }}
                        />
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Typography sx={{ margin: '16px 0px', fontSize: '14px', fontWeight: '500' }}>
                        Assets List:
                    </Typography>
                    {assetsData?.map((ben: any, index: any) => (
                        <>
                            <AssetsRow
                                key={index}
                                ben={ben}
                                handleValueChange={(value: any, benData: any) => {
                                    setDisableBTN(false);
                                    setAssetsData((prev: any) => {
                                        const tempData: any = [];
                                        for (let i = 0; i < prev.length; i++) {
                                            const element = prev[i];
                                            if (element.id == benData.id) {
                                                tempData.push({
                                                    ...prev[i],
                                                    percentageGiven: value,
                                                });
                                            } else {
                                                tempData.push({
                                                    ...prev[i],
                                                });
                                            }
                                        }
                                        return [...tempData];
                                    });
                                }}
                            />
                        </>
                    ))}
                </DialogContent>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        gap: '8px',
                        padding: '20px 24px',
                    }}
                >
                    {disableBTN && (
                        <Typography mt={'5px'}>
                            {'Note: Assets Distribution can not be more than 100%'}
                        </Typography>
                    )}
                    <Box
                        sx={{
                            fontWeight: '500',
                            fontSize: '14px',
                            padding: '8px 20px',
                            borderRadius: '20px',
                            border: '2px solid rgba(25, 25, 25, 0.5)',
                            cursor: 'pointer',
                            transition: 'border-color 0.3s ease',
                            '&:hover': {
                                borderColor: 'black',
                            },
                        }}
                        onClick={() => {
                            props.setResult(false);
                        }}
                    >
                        Cancel
                    </Box>
                    <Box
                        sx={{
                            fontWeight: '500',
                            fontSize: '14px',
                            padding: '8px 20px',
                            borderRadius: '20px',
                            border: '2px solid rgba(25, 25, 25, 0.5)',
                            backgroundColor: 'black',
                            color: '#FFFF',
                            cursor: 'pointer',
                            '&:hover': {
                                opacity: '0.6',
                            },
                        }}
                        onClick={handleUpdate}
                    >
                        Confirm
                    </Box>
                </Box>
            </Dialog>
        </React.Fragment>
    );

    function AssetsRow(props: any) {
        const [value, setValue] = useState<any>(props.ben.percentageGiven);

        const handleChange = async (event: Event, newValue: number | number[]) => {
            if (typeof newValue === 'number') {
                setValue(newValue);
            }
        };

        const handleCommitChange = (event: any, value: any) => {
            if (typeof value === 'number') {
                props.handleValueChange(value, props.ben);
            }
        };
        return (
            <>
                <Divider sx={{ my: 2 }} />
                <Box
                    className="asset_popup"
                    sx={{ display: 'flex', gap: '40px', alignItems: 'center' }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            height: '100%',
                            padding: '6px 8px',
                            border: '2px solid rgb(36, 152, 211)',
                            borderRadius: '30px',
                            alignItems: 'center',
                            // width: 'max-content',
                            fontSize: '12px',
                            fontWeight: '600',
                            width: '98px',
                        }}
                    >
                        <Box>
                            <img
                                src={UserImage}
                                alt="UserImage"
                                style={{
                                    objectFit: 'contain',
                                }}
                                width={'30px'}
                                height={'30px'}
                            />
                        </Box>
                        <Typography ml={1}>{props.ben.name}</Typography>
                    </Box>
                    <Box className="slider_popup" sx={{ width: '57%' }}>
                        <Slider
                            sx={{
                                color: 'black',
                                '& .MuiSlider-thumb': {
                                    '&:hover': {
                                        boxShadow: 'rgba(25, 25, 25, 0.16) 0px 0px 0px 8px',
                                    },
                                },
                            }}
                            value={value}
                            min={0}
                            max={100}
                            onChange={handleChange}
                            onChangeCommitted={handleCommitChange}
                            valueLabelDisplay="auto"
                            aria-labelledby="non-linear-slider"
                            marks={[
                                {
                                    value: 0,
                                    label: '0%',
                                },
                                {
                                    value: 100,
                                    label: '100%',
                                },
                            ]}
                        />
                    </Box>
                    <Box
                        sx={{
                            maxWidth: '20%',
                            display: 'flex',
                            alignItems: 'center',
                            // height: '70%',
                            // justifyContent: 'space-between',
                            gap: '18px',
                        }}
                    >
                        <Box sx={{ width: '73px' }}>
                            <TextField
                                value={value}
                                id="filled-number"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e: any) => {
                                    if (e.target.value >= 0 && e.target.value <= 100) {
                                        handleChange(e, parseFloat(e.target.value));
                                    }
                                }}
                                onBlur={(e: any) => {
                                    handleCommitChange(e, parseFloat(e.target.value));
                                }}
                                // variant="filled"

                                className="input_wrapper"
                                sx={{
                                    '& input[type="number"]': {
                                        padding: '8px 14px',
                                        alignItems: 'center',
                                        fontSize: '12px',
                                    },
                                }}
                                inputProps={{
                                    min: 0, // Minimum value
                                    max: 100, // Maximum value
                                }}
                            />
                        </Box>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: '400',
                                color: '#666666',
                            }}
                        >
                            {`${parseFloat(props.ben.percentageBalanceUSD).toFixed(4)}`} USD
                        </Typography>
                    </Box>
                </Box>
            </>
        );
    }
}
function AutoCompleteRow(props: any) {
    const { labelName, keyName, walletBen, apiFor, benID, setPopUpdate, alreadyAssign } = props;
    const [ids, setIds] = useState<any>(null);
    const arrayColumn = (array: any) => {
        return array?.map((item: any) =>
            item['walletTokenId'] ? item['walletTokenId'] : item['walletTokenID']
        );
    };
    const assignedBenId = arrayColumn(alreadyAssign);
    useEffect(() => {
        if (ids && ids != '') {
            const data = {
                ...ids,
                beneficiaryID: benID,
                walletTokenID: ids.id,
                percentageGiven: 0,
                id: null,
            };
            setPopUpdate((prev: any) => {
                let addData = [];
                let existData = false;
                for (let i = 0; i < prev.length; i++) {
                    if (prev[i].walletTokenID.toLowerCase() === ids.id) {
                        existData = true;
                    }
                }
                if (!existData) {
                    addData = [data, ...prev];
                } else {
                    addData = [...prev];
                }
                return addData;
            });
        }
    }, [ids]);
    // useEffect(() => {
    //     if (ids && ids != '') {
    //         if (apiFor == 'add-beneficiaries') {
    //             navigate('/split-assets', {
    //                 state: { walletID: walletID, benID: ids },
    //             });
    //         }
    //         if (apiFor == 'add-assets') {
    //             navigate('/split-assets', {
    //                 state: { walletID: walletID, benID: benID },
    //             });
    //         }
    //     }
    // }, [ids]);
    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            onChange={(event: any, newValue: string | null) => {
                walletBen.map((item: any) => {
                    if (item[keyName] == newValue) {
                        setIds(item);
                    }
                });
            }}
            options={walletBen
                .map((item: any) => {
                    if (assignedBenId && assignedBenId.indexOf(item.id) != -1) {
                        return undefined;
                    } else {
                        return item[keyName];
                    }
                })
                .filter((item: any) => item !== undefined)}
            sx={{ width: 200 }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={labelName}
                    sx={{
                        position: 'relative',
                        width: '200px',
                        fontSize: '14px',
                        fontWeight: 600,
                        color: '#000000',
                        '& label': {
                            top: '-5px',
                            position: 'absolute',
                            fontSize: '14px',
                        },
                        '&::placeholder': {
                            color: 'rgba(1, 2, 3, 0.5)',
                            fontSize: '14px',
                        },
                        '& .MuiOutlinedInput-root': {
                            height: '40px', // Adjust the height as needed
                        },
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                            height: '6px', // Adjust the height as needed
                        },
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-input.internal-autofill-selected':
                            {
                                height: '6px', // Adjust the height as needed
                            },
                        '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                            position: 'absolute',
                            width: '70%',
                            height: '1rem',
                            fontSize: '14px',
                        },
                    }}
                />
            )}
        />
    );
}

export default BeneficiaryPopup;

