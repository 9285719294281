import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useState } from 'react';
import { Typography } from '@mui/material';

export default function MinimumDistanceSlider(props: any) {
    const marks = [
        {
            value: 0,
            label: '0%',
        },
        {
            value: 100,
            label: '100%',
        },
    ];

    const [value, setValue] = useState<any>(props.value | 0);
    const handleChange = (event: Event, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            setValue(newValue);
        }
    };

    const handleChangeCommit = (event: any, newValue: any) => {
        if (typeof newValue === 'number') {
            props.onChangeCallBack(newValue);
        }
    };
    React.useEffect(() => {
        props.onChangeCallBack(0);
    }, []);

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                mt: 5,
            }}
        >
            <Box sx={{ display: 'flex', width: '92%', justifyContent: 'space-between' }}>
                <Typography style={{ fontSize: '11px' }}>{`0 ${props.title}`}</Typography>
                <Typography style={{ fontSize: '11px' }}>
                    {((props.balance * value) / 100).toFixed(4)}&nbsp;{props.title}
                </Typography>
            </Box>
            <Box sx={{ width: '90%', px: 2 }}>
                <Slider
                    sx={{
                        color: 'black',
                        '& .MuiSlider-thumb': {
                            '&:hover': {
                                boxShadow: 'rgba(25, 25, 25, 0.16) 0px 0px 0px 8px',
                            },
                        },
                    }}
                    getAriaLabel={() => 'Minimum distance'}
                    value={value}
                    onChange={handleChange}
                    onChangeCommitted={handleChangeCommit}
                    valueLabelDisplay="on"
                    disableSwap
                    marks={marks}
                />
            </Box>
        </Box>
    );
}

