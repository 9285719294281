import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    Autocomplete,
    Avatar,
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Slider,
    TextField,
    Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BeneficiaryImage from '../../assets/images/asset_img.png';
import CloseIcon from '@mui/icons-material/Close';
import ApiUtils from '../../api/ApiUtils';
import { BASE_IMG_URL } from '../../config/Constants';

function AssetPopup(props: any) {
    const [showDrop, setShowDrop] = useState(false);
    const [disableBTN, setDisableBTN] = useState(false);
    const handleToggleDrop = () => {
        setShowDrop((prev) => !prev);
    };
    const [assetsData, setAssetsData] = useState<any>(props.data);
    useEffect(() => {
        setAssetsData(props.data);
    }, [props.data]);
    const handleUpdate = async () => {
        try {
            if (totalPercentage > 100) {
                setDisableBTN(true);
            } else {
                const updateData = [];
                for (let i = 0; i < assetsData.length; i++) {
                    updateData.push({
                        id: assetsData[i].id,
                        beneficiaryId: assetsData[i].beneficiaryID,
                        walletTokenId: assetsData[i].walletTokenID,
                        percentageGiven: assetsData[i].percentageGiven,
                    });
                }
                const resp = await ApiUtils.updateBeneficiaryAssetList(updateData);
                await props.handleUpdate();
                await props.setResult(false);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setShowDrop(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const [totalPercentage, setTotalPercentage] = useState(0);
    return (
        <React.Fragment>
            <Dialog maxWidth="md" fullWidth={true} open={props.result}>
                <DialogTitle
                    className="asset_popup"
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box className="asset_popup" sx={{ display: 'flex', gap: '24px' }}>
                        <Box
                            className="assets_wrap_popup"
                            sx={{ fontSize: '24px', fontWeight: '600' }}
                        >
                            {props.row.name}
                        </Box>
                        <Box sx={{ display: 'flex', gap: '8px' }}>
                            <Box
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: '600',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {`${parseFloat(props.row.balance).toFixed(4)}`} {props.row.name}
                            </Box>
                            <Box
                                sx={{
                                    fontSize: '14px',
                                    color: '#666666',
                                    fontWeight: '400',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {`(USD ${parseFloat(props.row.balanceUSD).toFixed(4)})`}
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                        {showDrop && (
                            <div ref={dropdownRef}>
                                <AutoCompleteRow
                                    walletBen={props.walletBen}
                                    keyName={'beneficiaryName'}
                                    labelName={'Beneficiary'}
                                    setPopUpdate={setAssetsData}
                                    walletTokenId={props.row.id}
                                    alreadyAssign={assetsData}
                                />
                            </div>
                        )}
                        <Box
                            onClick={handleToggleDrop}
                            className="add_beneficiary"
                            sx={{
                                color: '#4EC17B',
                                border: '2px solid rgba(78, 193, 123, 0.5)',
                                fontSize: '14px',
                                padding: '6px 20px',
                                borderRadius: '20px',
                                cursor: 'pointer',
                                fontWeight: '500',
                                '&:hover': {
                                    border: '2px solid rgb(78, 193, 123)',
                                },
                            }}
                        >
                            Add Beneficiary
                        </Box>
                        <CloseIcon
                            className="close_icon"
                            onClick={() => {
                                props.setResult(false);
                            }}
                            sx={{ cursor: 'pointer' }}
                        />
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ fontSize: '14px', fontWeight: '500', margin: '16px 0px' }}>
                        Beneficiaries:
                    </Box>
                    {assetsData?.map((ben: any, index: any) => (
                        <>
                            <BeneficiariesRow
                                key={index}
                                ben={ben}
                                handleValueChange={(value: any, benData: any) => {
                                    setAssetsData((prev: any) => {
                                        const updateState = [];
                                        let tempPerc = 0;
                                        setTotalPercentage(0);
                                        for (let i = 0; i < prev.length; i++) {
                                            if (prev[i].beneficiaryID == benData.beneficiaryID) {
                                                tempPerc = tempPerc + parseFloat(value);
                                                updateState.push({
                                                    ...prev[i],
                                                    percentageGiven: value,
                                                });
                                            } else {
                                                tempPerc =
                                                    tempPerc + parseFloat(prev[i].percentageGiven);
                                                updateState.push({ ...prev[i] });
                                            }
                                        }
                                        setTotalPercentage(tempPerc);
                                        setDisableBTN(false);
                                        return updateState;
                                    });
                                }}
                            />
                        </>
                    ))}
                </DialogContent>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        gap: '8px',
                        padding: '20px 24px',
                    }}
                >
                    {disableBTN && (
                        <Typography mt={'5px'}>
                            {'Note: Assets Distribution can not be more than 100%'}
                        </Typography>
                    )}

                    <Box
                        className="cancel_btn"
                        sx={{
                            fontWeight: '500',
                            fontSize: '14px',
                            padding: '8px 20px',
                            borderRadius: '20px',
                            border: '2px solid rgba(25, 25, 25, 0.5)',
                            cursor: 'pointer',
                            transition: 'border-color 0.3s ease',
                            '&:hover': {
                                borderColor: 'black',
                            },
                        }}
                        onClick={() => {
                            props.setResult(false);
                        }}
                    >
                        Cancel
                    </Box>
                    <Box
                        sx={{
                            fontWeight: '500',
                            fontSize: '14px',
                            padding: '8px 20px',
                            borderRadius: '20px',
                            border: '2px solid rgba(25, 25, 25, 0.5)',
                            backgroundColor: 'black',
                            color: '#FFFF',
                            cursor: 'pointer',
                            '&:hover': {
                                opacity: '0.6',
                            },
                        }}
                        onClick={handleUpdate}
                        // onClick={() => {
                        //     handleUpdate();
                        //     navigate('/split-assets');
                        // }}
                    >
                        Confirm
                    </Box>
                </Box>
            </Dialog>
        </React.Fragment>
    );
    function BeneficiariesRow(props: any) {
        console.log('BEN PROPS', props);
        const [value, setValue] = useState<any>(props.ben.percentageGiven);

        const handleChange = (event: Event, newValue: number | number[]) => {
            if (typeof newValue === 'number') {
                setValue(newValue);
            }
        };

        const handleCommitChange = (event: any, value: any) => {
            if (typeof value === 'number') {
                props.handleValueChange(value, props.ben);
            }
        };

        return (
            <>
                <Divider sx={{ my: 2 }} />
                <Box
                    className="asset_popup"
                    sx={{ display: 'flex', gap: '40px', alignItems: 'center' }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            height: '100%',
                            padding: '6px 8px',
                            border: '2px solid rgb(36, 152, 211)',
                            borderRadius: '30px',
                            alignItems: 'center',
                            // width: 'max-content',
                            fontSize: '12px',
                            fontWeight: '600',
                            width: '98px',
                        }}
                    >
                        <Box>
                            <Avatar
                                src={BASE_IMG_URL + props.ben.photoFileName}
                                alt={props.ben.beneficiaryName}
                                sx={{ width: '29px', height: '29px' }}
                            />
                        </Box>
                        <Typography ml={1}>{props.ben.beneficiaryName}</Typography>
                    </Box>

                    <Box className="slider_popup" sx={{ width: '57%' }}>
                        <Slider
                            sx={{
                                color: 'black',
                                '& .MuiSlider-thumb': {
                                    '&:hover': {
                                        boxShadow: 'rgba(25, 25, 25, 0.16) 0px 0px 0px 8px',
                                    },
                                },
                            }}
                            value={value}
                            min={0}
                            max={100}
                            onChange={handleChange}
                            onChangeCommitted={handleCommitChange}
                            valueLabelDisplay="auto"
                            aria-labelledby="non-linear-slider"
                            marks={[
                                {
                                    value: 0,
                                    label: '0%',
                                },
                                {
                                    value: 100,
                                    label: '100%',
                                },
                            ]}
                        />
                    </Box>
                    <Box
                        sx={{
                            maxWidth: '20%',
                            display: 'flex',
                            alignItems: 'center',
                            // height: '70%',
                            // justifyContent: 'space-between',
                            gap: '18px',
                        }}
                    >
                        <Box sx={{ width: '73px' }}>
                            <TextField
                                value={value}
                                id="filled-number"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e: any) => {
                                    if (e.target.value >= 0 && e.target.value <= 100) {
                                        handleChange(e, parseFloat(e.target.value));
                                    }
                                }}
                                onBlur={(e: any) => {
                                    handleCommitChange(e, parseFloat(e.target.value));
                                }}
                                className="input_wrapper"
                                sx={{
                                    '& input[type="number"]': {
                                        padding: '8px 14px',
                                        alignItems: 'center',
                                        fontSize: '12px',
                                        borderRadius: '16px',
                                    },
                                }}
                                inputProps={{
                                    min: 0, // Minimum value
                                    max: 100, // Maximum value
                                }}
                                // variant="filled"
                            />
                        </Box>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: '400',
                                color: '#666666',
                            }}
                        >
                            {`${parseFloat(props.ben.percentageBalanceUSD).toFixed(4)}`} USD
                        </Typography>
                    </Box>
                </Box>
            </>
        );
    }
}

function AutoCompleteRow(props: any) {
    const { labelName, keyName, walletBen, setPopUpdate, walletTokenId, alreadyAssign } = props;
    const [ids, setIds] = useState<any>(null);
    const arrayColumn = (array: any) => {
        return array?.map((item: any, index: any) =>
            item['beneficiaryId'] ? item['beneficiaryId'] : item['beneficiaryID']
        );
    };
    const assignedBenId = arrayColumn(alreadyAssign);
    useEffect(() => {
        if (ids && ids != '') {
            const data = {
                ...ids,
                beneficiaryID: ids.id,
                walletTokenID: walletTokenId,
                percentageGiven: 0,
                id: null,
            };
            setPopUpdate((prev: any) => {
                let addData = [];
                let existData = false;
                for (let i = 0; i < prev.length; i++) {
                    if (prev[i].beneficiaryID === ids.id) {
                        existData = true;
                    }
                }
                if (!existData) {
                    addData = [data, ...prev];
                } else {
                    addData = [...prev];
                }
                return addData;
            });
        }
    }, [ids]);
    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            onChange={(event: any, newValue: string | null) => {
                walletBen.map((item: any, index: any) => {
                    if (item[keyName] == newValue) {
                        setIds(item);
                    }
                });
            }}
            options={walletBen
                .map((item: any, index: any) => {
                    if (assignedBenId.indexOf(item.id) === -1) {
                        return item[keyName];
                    } else {
                        return undefined;
                    }
                })
                .filter((item: any) => item !== undefined)}
            sx={{ width: 200 }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={labelName}
                    sx={{
                        position: 'relative',
                        width: '200px',
                        fontSize: '14px',
                        fontWeight: 600,
                        color: '#000000',
                        '& label': {
                            top: '-5px',
                            position: 'absolute',
                            fontSize: '14px',
                        },
                        '&::placeholder': {
                            color: 'rgba(1, 2, 3, 0.5)',
                            fontSize: '14px',
                        },
                        '& .MuiOutlinedInput-root': {
                            height: '40px', // Adjust the height as needed
                        },
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                            height: '6px', // Adjust the height as needed
                        },
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-input.internal-autofill-selected':
                            {
                                height: '6px', // Adjust the height as needed
                            },
                        '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                            position: 'absolute',
                            width: '70%',
                            height: '1rem',
                            fontSize: '14px',
                        },
                    }}
                />
            )}
        />
    );
}

export default AssetPopup;

