import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { logo } from '../../config/Images';
import { Stack, useMediaQuery } from '@mui/material';
import { useFormik } from 'formik';
import { ResetPasswordValidationSchema } from '../../validations/ResetPassValidation';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ApiUtils from '../../api/ApiUtils';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CustomToast from '../Common/CustomToast';

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            Copyright © {new Date().getFullYear()} Coin of Kin. All rights reserved.
        </Typography>
    );
}

export default function ResetPassword() {
    const matches = useMediaQuery('(max-width:500px)');
    const [searchParams, setSearchParams] = useSearchParams();
    const [showPassword, setShowPassword] = useState(false);

    const token = searchParams.get('token');
    if (!token) {
        window.location.href = '/login';
    }
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const formik = useFormik({
        initialValues: {
            resetPassword: '',
        },
        validationSchema: ResetPasswordValidationSchema,
        onSubmit: async (values) => {
            try {
                const userLogin = await ApiUtils.resetPassword({
                    token: token,
                    password: values.resetPassword,
                });
                if (userLogin?.data?.token) {
                    CustomToast({
                        message: 'Password rest successfully. Please login with new password!',
                        severity: 'success',
                    });
                    window.location.href = '/login';
                } else {
                    CustomToast({
                        message: 'Some thing went wrong please retry!',
                        severity: 'error',
                    });
                    window.location.href = '/login';
                }
            } catch (error) {
                CustomToast({
                    message: 'Something went wrong.',
                    severity: 'error',
                });
            }
        },
    });

    return (
        <Stack className="container">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: matches ? 0 : 6,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <img
                    className="logo"
                    style={{
                        width: '250px',
                        // width: matches ? '60%' : '15%',
                        marginRight: '50px',
                        cursor: 'pointer',
                    }}
                    src={logo}
                    alt="logo"
                />
                <Typography component="h1" variant="h5">
                    Reset Password
                </Typography>
                <Box
                    component="form"
                    noValidate
                    onSubmit={formik.handleSubmit}
                    sx={{ mt: '1rem', width: '40%' }}
                >
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="resetPassword"
                            label="Add password"
                            type={showPassword ? 'text' : 'password'}
                            id="resetPassword"
                            autoComplete="reset-password"
                            onChange={formik.handleChange}
                            value={formik.values.resetPassword}
                            error={
                                formik.touched.resetPassword && Boolean(formik.errors.resetPassword)
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            sx={{ opacity: '0.6' }}
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? (
                                                <VisibilityOff fontSize="small" />
                                            ) : (
                                                <Visibility fontSize="small" />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            helperText={formik.touched.resetPassword && formik.errors.resetPassword}
                        />
                    </Grid>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{
                            height: '45px',
                            mt: matches ? 0 : 3,
                            mb: 2,
                        }}
                    >
                        Submit
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link href="/signup" variant="body2">
                                Don't have an account? Sign up
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 5 }} />
        </Stack>
    );
}

