import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import {
    useAddBeneficiaryLabelMutation,
    useAddLabelListMutation,
    useAddWalletLabelMutation,
} from '../../services/Slices/apiSlice/GetCreateLabelAPI';
import { Box } from '@mui/material';

const filter = createFilterOptions<LabelOption>();

interface LabelOption {
    labelId: string;
    labelName: string;
    description: string;
    dateCreated: string;
    dateUpdated: string | null;
    categoryId: number;
}

const AutoCompleteLabel = (props: any) => {
    const { labelData, labelType, sentID, handleUpdate } = props;
    const [value, setValue] = useState<LabelOption | null>(null);
    const [createLabelValue, setCreateLabelValue] = useState('');
    const [addLabelBTN, setAddLabelBTN] = useState(false);
    const [addLabel] = useAddLabelListMutation();
    const [addBenLabel] = useAddBeneficiaryLabelMutation();
    const [addWalLabel] = useAddWalletLabelMutation();
    const handleLabelSelected = async (newValue: LabelOption | null) => {
        setValue(newValue);
        if (newValue && newValue.labelName) {
            //connect it with beneficary or assets
            if (labelType) {
                if (labelType == 1) {
                    //ben
                    await addBenLabel({
                        beneficiaryId: sentID,
                        labelId: newValue.labelId,
                    });
                }
                if (labelType == 2) {
                    //wallet
                    await addWalLabel({
                        walletTokenId: sentID,
                        labelId: newValue.labelId,
                    });
                }
                handleUpdate();
            }
        }
    };
    const handleCreateLabel = async () => {
        const resp: any = await addLabel({
            labelName: createLabelValue,
            description: createLabelValue,
            categoryId: labelType,
        });
        if (resp && labelType && resp.data) {
            if (labelType == 1) {
                //ben
                await addBenLabel({
                    beneficiaryId: sentID,
                    labelId: resp.data.labelId,
                });
            }
            if (labelType == 2) {
                //wallet
                await addWalLabel({
                    walletTokenId: sentID,
                    labelId: resp.data.labelId,
                });
            }
            handleUpdate();
        }
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                {/* <Autocomplete */}
                {/* <Autocomplete
                key={Math.random()}
                value={value}
                onChange={(event, newValue) => handleLabelSelected(newValue as LabelOption | null)}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;

                    const isExisting = options.some((option) => inputValue == option.labelName);
                    if (inputValue !== '' && !isExisting) {
                        setAddLabelBTN(true);
                        setCreateLabelValue(inputValue);
                        // filtered.push({
                        //     labelName: inputValue,
                        //     description: inputValue,
                        //     labelId: '',
                        //     dateCreated: '',
                        //     dateUpdated: null,
                        //     categoryId: 0,
                        // });
                    } else {
                        setAddLabelBTN(false);
                    }

                    return filtered;
                }}
                selectOnFocus
                handleHomeEndKeys
                id="label-autocomplete"
                options={labelData}
                getOptionLabel={(option) =>
                    typeof option === 'string' ? option : option.labelName
                }
                renderOption={(props, option) => <li {...props}>{option.labelName}</li>}
                sx={{
                    width: 200,
                    position: 'relative',
                }}
                freeSolo
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Assign Label"
                        sx={{
                            position: 'relative',
                            fontSize: '14px',
                            '& label': {
                                top: '-11px',
                                position: 'absolute',
                                fontSize: '14px',
                            },
                            '& .MuiOutlinedInput-root': {
                                height: '30px', // Adjust the height as needed
                            },
                            '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                                position: 'absolute',
                                width: '70%',
                                height: '1rem',
                                fontSize: '14px',
                            },
                        }}
                    />
                )}
            /> */}
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={labelData} // Use your LabelOption array here
                    getOptionLabel={(option: LabelOption) => option.labelName} // Specify the label property
                    onChange={(event, newValue) =>
                        handleLabelSelected(newValue as LabelOption | null)
                    }
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;

                        const isExisting = options.some((option) => inputValue == option.labelName);
                        if (inputValue !== '' && !isExisting) {
                            setAddLabelBTN(true);
                            setCreateLabelValue(inputValue);
                            // filtered.push({
                            //     labelName: inputValue,
                            //     description: inputValue,
                            //     labelId: '',
                            //     dateCreated: '',
                            //     dateUpdated: null,
                            //     categoryId: 0,
                            // });
                        } else {
                            setAddLabelBTN(false);
                        }

                        return filtered;
                    }}
                    sx={{
                        width: 200,
                        position: 'relative',
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Assign Label"
                            sx={{
                                position: 'relative',
                                fontSize: '14px',
                                '& label': {
                                    top: '-11px',
                                    position: 'absolute',
                                    fontSize: '14px',
                                },
                                '& .MuiOutlinedInput-root': {
                                    height: '30px', // Adjust the height as needed
                                },
                                '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                                    position: 'absolute',
                                    width: '70%',
                                    height: '1rem',
                                    fontSize: '14px',
                                },
                            }}
                        />
                    )}
                />
                <Box
                // sx={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}
                >
                    {addLabelBTN && (
                        <Box
                            onClick={handleCreateLabel}
                            sx={{
                                // position: 'absolute',
                                whiteSpace: 'nowrap',
                                marginLeft: '10px',
                                textAlign: 'center',
                                flexGrow: 0,
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '12px',
                                fontWeight: '500',
                                lineHeight: '1.75',
                                height: '30px',
                                padding: '0px 10px',
                                borderRadius: '20px',
                                backgroundColor: 'transparent',
                                border: '2px solid rgba(78, 193, 123, 0.5)',
                                cursor: 'pointer',
                                color: 'rgba(78, 193, 123)',
                                transition: 'border-color 0.3s ease',
                                '&:hover': {
                                    borderColor: '2px solid rgba(78, 193, 123)',
                                },
                            }}
                        >
                            Add Label
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    );
};
export default AutoCompleteLabel;

