import Assets from '../components/Pages/Assets';
import AssetsMockup from '../components/Pages/AssetsMockup';
import Beneficiaries from '../components/Pages/Beneficiaries';
import ManageAccount from '../components/Pages/ManageAccount';
import Plans from '../components/Pages/Plans';
import CreateBeneficiary from '../components/Pages/CreateBeneficiary';
import AccountSettings from '../components/Pages/AccountSettings';
import Help from '../components/Pages/Help';
import PaymentHistory from '../components/Pages/PaymentHistory';
import SignUp from '../components/Pages/Signup';
import SignIn from '../components/Pages/Signin';
import ForgotPassword from '../components/Pages/ForgetPassword';
import SplitAssets from '../components/Pages/SplitAssets';
import ResetPassword from '../components/Pages/ResetPassword';
import UpdatePassword from '../components/Pages/UpdatePassword';
import PricingPage from '../components/Pages/PricePage';
import ConnectWallet from '../components/Pages/ConnectWallet';

const commonProtectedRoutes = [
    { path: '/beneficiaries', component: Beneficiaries },
    { path: '/manage-account', component: ManageAccount },
    { path: '/assets', component: Assets },
    { path: '/help', component: Help },
    // { path: '/payment-history', component: PaymentHistory },
    { path: '/subscription', component: PricingPage },
    { path: '/create-beneficiary', component: CreateBeneficiary },
    { path: '/account-settings', component: AccountSettings },
    { path: '/split-assets', component: SplitAssets },
    { path: '/update-password', component: UpdatePassword },
];
const publicRoutes: any = [
    { path: '/', component: ConnectWallet },
    // { path: '/signup', component: SignUp },
    // { path: '/connect-wallet', component: ConnectWallet },
    { path: '/login', component: ConnectWallet },
    { path: '/assets-mockup', component: AssetsMockup },
    { path: '/forgot-password', component: ForgotPassword },
    { path: '/reset-password', component: ResetPassword },
];
export { commonProtectedRoutes, publicRoutes };

