import {
    Box,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { UpdatePasswordValidationSchema } from '../../validations/UpdatePassword';
import ApiUtils from '../../api/ApiUtils';
import CustomToast from '../Common/CustomToast';
import Footer from '../CommonLayout/Footer';

interface User {
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
}

function UpdatePassword() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
        },
        validationSchema: UpdatePasswordValidationSchema,
        onSubmit: async (values) => {
            try {
                const userLogin = await ApiUtils.updateUserPassword({
                    oldPassword: values.oldPassword,
                    newPassword: values.newPassword,
                });
                if (userLogin?.data?.isSucceed) {
                    CustomToast({
                        message: 'Password updated successful! Please login again.',
                        severity: 'success',
                    });
                    sessionStorage.removeItem('__COI_TOKEN__');
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                } else {
                    CustomToast({
                        message: 'Please check your old password.',
                        severity: 'error',
                    });
                }
            } catch (error) {
                CustomToast({
                    message: 'Unauthorized Access.',
                    severity: 'error',
                });
            }
        },
    });

    return (
        <>
            <Box className="primary-box" sx={{ maxWidth: '30rem', mx: 'auto' }}>
                <Typography
                    className="manage-account"
                    mb={3}
                    variant="h5"
                    sx={{ fontWeight: 700, fontSize: '24px' }}
                >
                    Update password
                </Typography>
                <Box component="form" noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                    <Box
                        component="form"
                        noValidate
                        onSubmit={formik.handleSubmit}
                        sx={{ mt: '1rem' }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    variant="outlined"
                                    fullWidth
                                    id="oldPassword"
                                    label="Old Password"
                                    name="oldPassword"
                                    autoComplete="oldPassword"
                                    onChange={formik.handleChange}
                                    value={formik.values.oldPassword}
                                    error={
                                        formik.touched.oldPassword &&
                                        Boolean(formik.errors.oldPassword)
                                    }
                                    helperText={
                                        formik.touched.oldPassword && formik.errors.oldPassword
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="newPassword"
                                    label="New Password"
                                    id="newPassword"
                                    type={showPassword ? 'text' : 'password'}
                                    autoComplete="new-password"
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                    value={formik.values.newPassword}
                                    error={
                                        formik.touched.newPassword &&
                                        Boolean(formik.errors.newPassword)
                                    }
                                    helperText={
                                        formik.touched.newPassword && formik.errors.newPassword
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    size="small"
                                                    sx={{ opacity: '0.6' }}
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? (
                                                        <Visibility fontSize="small" />
                                                    ) : (
                                                        <VisibilityOff fontSize="small" />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '8px',
                            justifyContent: 'end',
                            marginTop: '2rem',
                        }}
                    >
                        <Button
                            sx={{
                                backgroundColor: '#000000',
                                color: '#FFFF',
                                padding: '6px 12px',
                                '&:hover': {
                                    backgroundColor: '#000000',
                                    opacity: '0.6',
                                },
                            }}
                            type="submit"
                        >
                            Update
                        </Button>
                        <Button
                            sx={{ backgroundColor: '#D3D3D3' }}
                            onClick={() => {
                                navigate('/account-settings');
                            }}
                        >
                            Back
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Footer />
        </>
    );
}

export default UpdatePassword;

