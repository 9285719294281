import { Box, Dialog, DialogContent, DialogTitle, Divider } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Avatars from '../../assets/images/avatars.png';
import Avatar2 from '../../assets/images/avatar2.76aea921824e609d81bbe7ebc59b11a5.png';
import Avatar3 from '../../assets/images/avatar3.73ab20fc1efc49594ad0b8a1b4715e02.png';
import Avatar4 from '../../assets/images/avatar4.b8dd5f61765d636ae42361fe1961c001.png';
import Avatar5 from '../../assets/images/avatar5.63115c3678244d98cfad7ea8d9be8c7b.png';
import Avatar6 from '../../assets/images/avatar6.7cb6b47dd062c4cfecf293749b7cecb0.png';
import Avatar7 from '../../assets/images/avatar7.b179f9a2b741ef294cedf77bd0fac4b2.png';
import Avatar8 from '../../assets/images/avatar8.4dc0db45fbd8fbb50351bd073c9952ec.png';
import Avatar9 from '../../assets/images/avatar9.b8d63a80923c4ceaf546cca61a764a17.png';
import Avatar10 from '../../assets/images/avatar10.590b38a4762f555595d9b2ccb2380fe7.png';
import Avatar11 from '../../assets/images/avatar11.598ac38f6a40baed8283b2f741626367.png';
import Avatar12 from '../../assets/images/avatar12.6a53c6b87e2569c3a47c5abc6618bb55.png';
import Avatar13 from '../../assets/images/avatar13.e0848e909d5e33d92b4ca9507076e2bc.png';
import Avatar14 from '../../assets/images/avatar14.4653d915e299689f9acf1cb9e6616d6c.png';
import Avatar15 from '../../assets/images/avatar15.5aa111c99a5adc0af1855126e071a1a3.png';
import Avatar16 from '../../assets/images/avatar16.3400e09d4864b06131b436507e9dddaa.png';
import Avatar17 from '../../assets/images/avatar17.a6aa63eade64be6993738a1cb64c7ed4.png';
import Avatar18 from '../../assets/images/avatar18.5d53c0f88abaef9f11497b0aad8e8a58.png';

function UploadPhotoPopup(props: any) {
    const avatarSources = [
        Avatars,
        Avatar2,
        Avatar3,
        Avatar4,
        Avatar5,
        Avatar6,
        Avatar7,
        Avatar8,
        Avatar9,
        Avatar10,
        Avatar11,
        Avatar12,
        Avatar13,
        Avatar14,
        Avatar15,
        Avatar16,
        Avatar17,
        Avatar18,
    ];
    const [selectedAvatar, setSelectedAvatar] = useState<number | null>(null);
    const handleAvatarClick = async (index: number) => {
        const imageUrl = avatarSources[index];
        try {
            // Fetch the image as a Blob
            const response = await fetch(imageUrl);
            const blob = await response.blob();

            // Convert the Blob to Base64
            const base64String = await new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => resolve(reader.result as string);
                reader.onerror = (error) => reject(error);
            });

            // Update state with selected avatar and Base64 representation
            setSelectedAvatar(index);
            setBenPhoto(base64String);
        } catch (error) {
            console.error('Error fetching or converting the image:', error);
        }
    };

    const [BenPhoto, setBenPhoto] = useState<any>(null);

    const handleOpenPopup = () => {
        props.setResult(true);
    };

    const handleClosePopup = () => {
        props.setResult(false);
    };

    // create avatar
    const [openAvatar, setOpenAvatar] = useState(false);
    const [currentPage, setCurrentPage] = useState('avatar');

    const handleClosedPopup = () => {
        setOpenAvatar(false);
    };

    const handlePageChange = (page: string) => {
        setCurrentPage(page);
    };

    return (
        <React.Fragment>
            <Dialog open={props.result} maxWidth="md" fullWidth={true}>
                <DialogTitle
                    // className="avatar_responsive"
                    sx={{ padding: '5px 0px 0px 0px' }}
                >
                    <Box
                        className="avatar_responsive"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: '30%',
                            alignItems: 'center',
                            transition: '0.3s ease-in',
                        }}
                    >
                        <Box
                            className="avatar_wrapper"
                            onClick={() => handlePageChange('avatar')}
                            sx={{
                                fontSize: '18px',
                                fontWeight: '600',
                                cursor: 'pointer',
                                color: currentPage === 'avatar' ? 'black' : '#666666',
                                transition: 'text-decoration 0.3s ease',
                                borderBottom: currentPage === 'avatar' ? '2px solid black' : 'none',
                                padding: '12px 10px',
                            }}
                        >
                            Avatar
                        </Box>
                        {/* <Box
                            className="avatar_wrapper"
                            onClick={() => handlePageChange('upload')}
                            sx={{
                                fontSize: '18px',
                                fontWeight: '600',
                                display: 'flex',
                                cursor: 'pointer',
                                color: currentPage === 'upload' ? 'black' : '#666666',
                                transition: 'text-decoration 0.3s ease',
                                borderBottom: currentPage === 'upload' ? '2px solid black' : 'none',
                                padding: '12px 10px',
                            }}
                        >
                            Upload
                        </Box> */}
                    </Box>
                    <Box
                        className="close_svg"
                        sx={{
                            position: 'absolute',
                            right: '30px',
                            top: '20px',
                        }}
                    >
                        <CloseIcon
                            onClick={() => {
                                props.setResult(false);
                            }}
                            sx={{ cursor: 'pointer' }}
                        />
                    </Box>
                </DialogTitle>
                <Divider sx={{}} />
                <DialogContent>
                    {currentPage === 'avatar' && (
                        <Box sx={{ margin: '0 60px' }}>
                            <Box
                                className="avatar"
                                sx={{
                                    display: 'flex',
                                    gap: '46px',
                                    marginBottom: '24px',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                }}
                            >
                                {avatarSources.map((avatarSrc, index) => (
                                    <img
                                        width={70}
                                        key={index}
                                        src={avatarSrc}
                                        alt={`avatar${index + 1}`}
                                        onClick={() => handleAvatarClick(index)}
                                        style={{
                                            borderWidth: '2px',
                                            borderStyle: 'solid',
                                            borderColor:
                                                selectedAvatar === index ? '#000' : '#E8E8E8',
                                            cursor: 'pointer',
                                            borderRadius: '20px',
                                            transition: 'text-decoration 0.2s ease',
                                        }}
                                        className="avatar_img"
                                    />
                                ))}
                            </Box>
                            <Box
                                sx={{
                                    color: 'white',
                                    backgroundColor: 'black',
                                    width: 'auto',
                                    padding: '10px 20px',
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    cursor: 'pointer',
                                    maxWidth: 'max-content',
                                    marginLeft: 'auto',
                                    borderRadius: '20px',
                                    marginTop: '24px',
                                    '&:hover': {
                                        opacity: '0.6',
                                    },
                                }}
                                onClick={() => {
                                    props.setResult(false);
                                    props.onBenPhotoChange(BenPhoto);
                                    handleClosePopup();
                                }}
                            >
                                Select
                            </Box>
                        </Box>
                    )}
                    {/* {currentPage === 'upload' && (
                        <DropFileInput
                            onFileChange={(files: any) => {
                                props.onBenPhotoChange(files);
                            }}
                            onClick={handleClosePopup}
                        />
                    )} */}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default UploadPhotoPopup;

