import React, { useEffect, useRef, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import Menu from '@mui/material/Menu';
import { Avatar, Dialog, DialogContent, DialogTitle, Tooltip, useMediaQuery } from '@mui/material';
import {
    logo,
    setting,
    downArrow,
    drawerIcon,
    mailIcon,
    beneficiariesIcon,
    assetsIcon,
} from '../../config/Images';
import { useNavigate } from 'react-router-dom';
import { Button, MenuItem } from '@mui/material';
import Container from '@mui/material/Container';
import ToggleSwitch from '../Common/ToggleSwitch';
import ApiUtils from '../../api/ApiUtils';
import { BASE_IMG_URL, MORALIS_KEY } from '../../config/Constants';
import HeaderDrawer from '../Common/HeaderDrawer';
import CustomToast from '../Common/CustomToast';
import { useAccount, useWriteContract } from 'wagmi';
import {
    useAddWalletAssetMutation,
    useLazyGetWalletTokensQuery,
    useUpdateBeneficiaryAssetListMutation,
    useUpdateWalletAssetMutation,
} from '../../services/Slices/apiSlice/assetsAPI';
import { cancelTask, createTask, escrowContract, web3, approve, allowance } from './web3';

const settings = [
    {
        name: 'Help',
        path: '/help',
    },
    {
        name: 'Account Settings',
        path: '/account-settings',
    },
    {
        name: 'Subscription',
        path: '/subscription',
    },
    // {
    //     name: 'Payment History',
    //     path: '/payment-history',
    // },
];

const drawerWidth = 280;
const pages = [
    { name: 'Assets', path: '/assets', icon: <img src={assetsIcon} alt="assetsIcon"></img> },
    {
        name: 'Beneficiaries',
        path: '/beneficiaries',
        icon: <img src={beneficiariesIcon} alt="assetsIcon"></img>,
    },
];

declare global {
    interface Window {
        ethereum?: any; // Adjust the type based on the actual structure of the object
        solana?: {
            connect: () => Promise<void>;
            publicKey: Promise<{ toString: () => string }>;
            isPhantom?: boolean;
        };
    }
}
export default function Header(props: any) {
    const { writeContract, isError, isSuccess, isPending, error, reset } = useWriteContract();
    const { isConnected, address } = useAccount();
    const matches = useMediaQuery('(max-width:1280px)');
    const datePickerRef = useRef<HTMLDivElement | null>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate();

    const [anchorElSetting, setAnchorElSetting] = React.useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleOpenSettingMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElSetting(event.currentTarget);
    };
    const handleCloseSettingMenu = () => {
        setAnchorElSetting(null);
    };

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };
    const addDays = function (date: any, days: number) {
        const now = new Date(date);
        const startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        startDate.setDate(startDate.getDate() + days);
        return startDate;
    };
    // const removeDays = function (date: any, days: number) {
    //     const startDate = new Date(date);
    //     startDate.setDate(startDate.getDate() - days);
    //     return startDate;
    // };
    const [selectedDate, setSelectedDate] = useState<any>(null);

    const [openDate, setOpenDate] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [cancel, setCancel] = useState(false);
    // const [confirmConnect, setConfirmConnect] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleOpenContract = () => {
        setAnchorEl(null);
    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    function formatDate(date: Date): string {
        // Get the day, month, and year
        const day: number = date.getDate();
        const month: number = date.getMonth() + 1; // Months are zero-based
        const year: number = date.getFullYear();

        // Pad single-digit day or month with a leading zero
        const formattedDay: string = day < 10 ? '0' + day : String(day);
        const formattedMonth: string = month < 10 ? '0' + month : String(month);

        // Create the formatted date string in "mm/dd/yyyy" format
        const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;

        return formattedDate;
    }

    // API===================================>>>>>>>>>>>>>>>>>>>>>>>>>
    const [benData, setBenData] = useState<any>({
        walletName: 'string',
        balance: 0,
        currency: 'string',
        lastUpdated: '2024-01-08T15:57:20.759Z',
        isActive: true,
        notes: 'string',
        cryptoAddress: 'string',
        description: 'string',
        walletTypeId: 0,
        dateCreated: '2024-01-08T15:57:20.759Z',
        dateUpdated: '2024-01-08T15:57:20.759Z',
        executionTransferStartDate: null,
        executionTransferEndDate: null,
    });
    const updateWalletDates = async () => {
        try {
            // it is creating diffrence of 1 day if i use new Date().isoString
            const year = selectedDate.getFullYear();
            const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
            const day = String(selectedDate.getDate()).padStart(2, '0');
            const hours = String(selectedDate.getHours()).padStart(2, '0');
            const minutes = String(selectedDate.getMinutes()).padStart(2, '0');
            const seconds = String(selectedDate.getSeconds()).padStart(2, '0');
            const localIsoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
            const updateWallet = await ApiUtils.updateWallet({
                ...selectedWallet,
                executionTransferStartDate: localIsoString,
                executionTransferEndDate: localIsoString,
            });
            await getUserWalletAndSet();
        } catch (error) {
            console.log(error);
        }
    };
    const cancelWalletDates = async () => {
        try {
            const updateWallet = await ApiUtils.updateWallet({
                ...selectedWallet,
                executionTransferStartDate: null,
                executionTransferEndDate: null,
            });
            await getUserWalletAndSet();
            setSelectedDate(null);
        } catch (error) {
            console.log(error);
        }
    };

    const [selectedWallet, setSelectedWallet] = useState<any>(null);

    const [currentUser, setCurrentUser] = useState<any | null>(null);
    // const { address } = useAccount();
    // const {
    //     data: balance,
    //     isLoading,
    //     error,
    // } = useBalance({
    //     address: address, // Replace with your wallet address
    // });

    const getUserDetails = async () => {
        const userData = await ApiUtils.getCurrentUser();
        setCurrentUser(userData);
        await props.setCurrentUser(userData);
        const data = await ApiUtils.getUserWallets();
        let accountExist: any;
        if (ethereum) {
            ethereum.request({ method: 'eth_accounts' }).then(async (accounts: string[]) => {
                if (accounts.length > 0) {
                    const balance = await ethereum.request({
                        method: 'eth_getBalance',
                        params: [accounts[0], 'latest'],
                    });
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].cryptoAddress == accounts[0]) {
                            accountExist = data[i];
                            break;
                        }
                    }
                    if (!accountExist) {
                        await ApiUtils.addWallet({
                            ...benData,
                            balance: balance ? parseFloat(balance.formatted) : 0,
                            cryptoAddress: accounts[0],
                            walletTypeId: 1,
                            currency: 'ETH',
                        });
                    }
                } else {
                    navigate('/login');
                }
                await getUserWalletAndSet();
            });
        }
    };
    const ethereum = (window as any).ethereum;
    useEffect(() => {
        getUserDetails();
        props.setFetchCurrentUser(() => getUserDetails);
    }, []);
    const getUserWalletAndSet = async () => {
        const data = await ApiUtils.getUserWallets();

        if (ethereum) {
            // Initial check for the current account
            ethereum.request({ method: 'eth_accounts' }).then((accounts: string[]) => {
                if (accounts.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].cryptoAddress == accounts[0]) {
                            setSelectedWallet({ ...data[i] });
                            break;
                        }
                    }
                } else {
                    navigate('/login');
                }
            });
            // .catch(navigate('/login'));
        } else {
            navigate('/login');
        }
    };
    const fetchTokenFromMoralis = async (address: string) => {
        try {
            const headers = new Headers();
            headers.set('accept', 'application/json');
            if (MORALIS_KEY) {
                headers.set('X-API-Key', MORALIS_KEY);
            }
            const data: any = await fetch(
                `https://deep-index.moralis.io/api/v2.2/wallets/${address}/tokens?chain=bsc`,
                {
                    headers: headers,
                }
            );
            if (data.status === 200) {
                // WE HAVE TO FILTER DATA BCZ MORALIS SENDING SOME SPAM DATA AS WELL.
                return await data.json();
            } else {
                CustomToast({
                    message: 'Please try after some times!',
                    severity: 'error',
                });
                sessionStorage.removeItem('__COI_TOKEN__');
                navigate('/login');
            }
        } catch (e) {
            CustomToast({
                message: 'Something went wrong!',
                severity: 'error',
            });
            sessionStorage.removeItem('__COI_TOKEN__');
            navigate('/login');
        }
    };
    const [addWalletAssets]: any = useAddWalletAssetMutation();
    const [updateWalletAssets] = useUpdateWalletAssetMutation();
    const [updateWalletAssetsListAPI] = useUpdateBeneficiaryAssetListMutation();
    const [GetWalletTokens, { data: tokenData, isLoading: benLoading, error: benError }] =
        useLazyGetWalletTokensQuery();
    const fetchWalletData = async (walletID: string) => {
        return await GetWalletTokens({ walletID: selectedWallet.id });
    };
    const [defaultRun, setDefaultRun] = useState(true);
    useEffect(() => {
        (async () => {
            if (selectedWallet && selectedWallet.id && !benLoading && defaultRun) {
                // update wallet balance
                await fetchWalletData(selectedWallet.id).then(async (res: any) => {
                    const { result } = await fetchTokenFromMoralis(selectedWallet.cryptoAddress);
                    const resPonseData = res.data;
                    for (let i = 0; i < result.length; i++) {
                        try {
                            if (
                                result[i]?.possible_spam == false &&
                                result[i]?.verified_contract == true
                            ) {
                                const singleToken = result[i];
                                let tokenExist;
                                for (let j = 0; j < resPonseData.length; j++) {
                                    if (resPonseData[j].tokenAddress == singleToken.token_address) {
                                        tokenExist = resPonseData[j];
                                    }
                                }
                                if (tokenExist && tokenExist?.id) {
                                    if (
                                        singleToken.balance_formatted != tokenExist.balance ||
                                        singleToken.native_token != tokenExist.nativeToken ||
                                        singleToken.decimals != tokenExist.tokenDecimal
                                    ) {
                                        const updateAssets = await updateWalletAssets({
                                            id: tokenExist.id,
                                            walletToken1: singleToken?.logo,
                                            balance: singleToken?.balance_formatted,
                                            tokenTypeId: 1,
                                            dateCreated: '2024-01-11T17:53:28.960Z',
                                            dateUpdated: '2024-01-11T17:53:28.960Z',
                                            walletId: selectedWallet.id,
                                            name: singleToken.symbol,
                                            tokenDecimal: singleToken?.decimals,
                                            nativeToken: singleToken?.native_token,
                                        });
                                        setSelectedWallet((prev: any) => ({ ...prev }));
                                    }
                                }
                                if (
                                    typeof tokenExist == 'undefined' &&
                                    singleToken?.token_address
                                ) {
                                    const addAsset = await addWalletAssets({
                                        walletToken1: singleToken?.logo || '',
                                        balance: singleToken?.balance_formatted,
                                        tokenTypeId: 1,
                                        dateCreated: '2024-01-11T17:53:28.960Z',
                                        dateUpdated: '2024-01-11T17:53:28.960Z',
                                        walletId: selectedWallet.id,
                                        tokenAddress: singleToken?.token_address,
                                        name: singleToken.symbol,
                                        tokenDecimal: singleToken?.decimals,
                                        nativeToken: singleToken?.native_token,
                                    });
                                    setSelectedWallet((prev: any) => ({ ...prev }));
                                }
                            }
                        } catch (e) {
                            console.log('ERROR WHILE ADDING OR UPDATING WALLET TOKEN', e);
                            continue;
                        }
                    }
                });
                setDefaultRun(false);
            }
        })();
    }, [selectedWallet]);
    useEffect(() => {
        if (selectedWallet && selectedWallet.id) {
            props.setWallet(() => selectedWallet.id);
            props.setConnectWallet(() => ({ ...selectedWallet }));
        }
    }, [selectedWallet]);
    useEffect(() => {
        //Implementing the setInterval method
        const interval = setInterval(() => {
            setDefaultRun(true);
            setSelectedWallet((prev: any) => ({ ...prev }));
        }, 60000);

        //Clearing the interval
        return () => clearInterval(interval);
    }, []);
    const maxDate = () => {
        const currentDate = new Date();

        // Add one year to the current date
        currentDate.setFullYear(currentDate.getFullYear() + 1);
        return currentDate;
    };

    const handleClickOutside = (event: any) => {
        if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
            // Clicked outside of the DatePicker, close it
            setOpenDate(false);
        }
    };
    useEffect(() => {
        // Add event listener when component mounts
        document.addEventListener('click', handleClickOutside);

        // Remove event listener when component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    useEffect(() => {
        const handleAccountsChanged = (accounts: string[]) => {
            if (accounts.length > 0) {
                window.location.reload();
            } else {
                navigate('/login');
            }
        };

        const ethereum = (window as any).ethereum;

        if (ethereum) {
            // Initial check for the current account
            ethereum
                .request({ method: 'eth_accounts' })
                .then((accounts: string[]) => {
                    if (accounts.length > 0) {
                        console.log(accounts[0]);
                    }
                })
                .catch(console.error);

            // Listen for account changes
            ethereum.on('accountsChanged', handleAccountsChanged);

            // Cleanup
            return () => {
                ethereum.removeListener('accountsChanged', handleAccountsChanged);
            };
        } else {
            console.error('MetaMask extension not detected');
        }
    }, []);
    const [updateAssetsList, setUpdateAssetsList] = useState<any>([]);
    const updateAssetsListAPI = async () => {
        await updateWalletAssetsListAPI(updateAssetsList).then(() => {
            setUpdateAssetsList([]);
        });
    };
    useEffect(() => {
        if (updateAssetsList.length) {
            updateAssetsListAPI();
        }
    }, [updateAssetsList.length]);
    const calculateAmount = async (amount: number, tokenDecimals: number) => {
        const multiplier = Math.pow(10, tokenDecimals);
        return amount * multiplier;
    };
    const handleSubmitContract = async () => {
        if (
            !selectedWallet?.executionTransferStartDate ||
            selectedWallet?.executionTransferStartDate < new Date().toISOString()
        ) {
            CustomToast({
                message: 'Please select valid execution date!',
                severity: 'error',
            });
            return true;
        }
        try {
            const dataForSubmit = await ApiUtils.getWalletToken(selectedWallet.id);
            if (dataForSubmit) {
                const assetsForSubmit = dataForSubmit;
                if (assetsForSubmit?.length) {
                    for (let i = 0; i < assetsForSubmit?.length; i++) {
                        const tokenAddress = assetsForSubmit[i].tokenAddress;
                        const executionTime = new Date(selectedWallet.executionTransferStartDate);
                        if (assetsForSubmit[i].associatedBeneficiariesModel?.length) {
                            for (
                                let j = 0;
                                j < assetsForSubmit[i].associatedBeneficiariesModel?.length;
                                j++
                            ) {
                                const submitBen =
                                    assetsForSubmit[i].associatedBeneficiariesModel[j];
                                const amount: number =
                                    (parseFloat(submitBen.percentageGiven) *
                                        parseFloat(assetsForSubmit[i].balance)) /
                                    100;
                                // const amountInWei = web3.utils.toWei(amount, 'ether'); // Assuming USDC has 6 decimals
                                console.log(assetsForSubmit[i]);
                                const amountInWei = await calculateAmount(
                                    amount,
                                    assetsForSubmit[i].tokenDecimal
                                ); // Assuming USDC has 6 decimals
                                if (!submitBen.taskContractId && amount != 0) {
                                    if (assetsForSubmit[i]?.nativeToken == false) {
                                        const allowData = await allowance(
                                            assetsForSubmit[i].tokenAddress,
                                            selectedWallet.cryptoAddress
                                        );
                                        if (!allowData) {
                                            const approveData = await approve(
                                                assetsForSubmit[i].tokenAddress,
                                                selectedWallet.cryptoAddress
                                            );
                                            await createTask(
                                                amountInWei.toString(),
                                                executionTime,
                                                submitBen.publicAddress,
                                                tokenAddress,
                                                selectedWallet.cryptoAddress
                                            ).then(async (res) => {
                                                setUpdateAssetsList((prev: any) => [
                                                    ...prev,
                                                    {
                                                        id: submitBen.id,
                                                        beneficiaryId: submitBen.beneficiaryID,
                                                        walletTokenId: submitBen.walletTokenID,
                                                        percentageGiven: submitBen.percentageGiven,
                                                        taskContractId:
                                                            res.transactionIndex.toString(),
                                                        taskAmountGiven: amountInWei.toString(),
                                                        taskPercentageGiven:
                                                            submitBen.percentageGiven,
                                                        taskDateCreated: new Date().toISOString(),
                                                    },
                                                ]);
                                            });
                                        }
                                    } else {
                                        await createTask(
                                            amountInWei.toString(),
                                            executionTime,
                                            submitBen.publicAddress,
                                            tokenAddress,
                                            selectedWallet.cryptoAddress
                                        ).then(async (res) => {
                                            setUpdateAssetsList((prev: any) => [
                                                ...prev,
                                                {
                                                    id: submitBen.id,
                                                    beneficiaryId: submitBen.beneficiaryID,
                                                    walletTokenId: submitBen.walletTokenID,
                                                    percentageGiven: submitBen.percentageGiven,
                                                    taskContractId: res.transactionIndex.toString(),
                                                    taskAmountGiven: amountInWei.toString(),
                                                    taskPercentageGiven: submitBen.percentageGiven,
                                                    taskDateCreated: new Date().toISOString(),
                                                },
                                            ]);
                                        });
                                    }
                                }
                            }
                        }
                    }
                    await ApiUtils.updateContractAsSubmitted();
                    const updateWallet = await ApiUtils.updateWallet({
                        ...selectedWallet,
                        submitContract: true,
                    });
                    await getUserWalletAndSet();

                    CustomToast({
                        message: 'Contract Submitted successfully!',
                        severity: 'success',
                    });
                } else {
                    CustomToast({
                        message: 'No assets data found!',
                        severity: 'error',
                    });
                }
            } else {
                console.log('FROM ELSE');
                CustomToast({
                    message: 'Something went wrong!',
                    severity: 'error',
                });
            }
            setConfirm(false);
        } catch (e) {
            console.log('RUN EEE', e);
            CustomToast({
                message: 'Something went wrong!',
                severity: 'error',
            });
            setConfirm(false);
        }
    };

    const handleCancelContract = async () => {
        try {
            const dataForSubmit = await ApiUtils.getWalletToken(selectedWallet.id);
            if (dataForSubmit) {
                const assetsForSubmit = dataForSubmit;
                if (assetsForSubmit?.length) {
                    for (let i = 0; i < assetsForSubmit?.length; i++) {
                        if (assetsForSubmit[i].associatedBeneficiariesModel?.length) {
                            for (
                                let j = 0;
                                j < assetsForSubmit[i].associatedBeneficiariesModel?.length;
                                j++
                            ) {
                                const submitBen =
                                    assetsForSubmit[i].associatedBeneficiariesModel[j];
                                if (submitBen.taskContractId) {
                                    await cancelTask(
                                        submitBen.taskContractId,
                                        selectedWallet.cryptoAddress,
                                        submitBen
                                    ).then(() => {
                                        updateAssetsList.push({
                                            id: submitBen.id,
                                            beneficiaryId: submitBen.beneficiaryID,
                                            taskContractId: '',
                                            taskAmountGiven: 0,
                                            walletTokenId: submitBen.walletTokenID,
                                            percentageGiven: submitBen.percentageGiven,
                                            taskPercentageGiven: 0,
                                        });
                                    });
                                    break;
                                }
                            }
                        }
                    }
                    await ApiUtils.updateContractAsCancelled();
                    const updateWallet = await ApiUtils.updateWallet({
                        ...selectedWallet,
                        submitContract: false,
                    });
                    await getUserWalletAndSet();

                    CustomToast({
                        message: 'Contract Cancelled Successfully!',
                        severity: 'success',
                    });
                } else {
                    CustomToast({
                        message: 'No assets data found!',
                        severity: 'error',
                    });
                }
            } else {
                CustomToast({
                    message: 'Something went wrong!',
                    severity: 'error',
                });
            }
            setCancel(false);
        } catch (e) {
            CustomToast({
                message: 'Something went wrong!',
                severity: 'error',
            });
            setCancel(false);
        }
    };

    return (
        <>
            <AppBar position="sticky" sx={{ backgroundColor: '#FFF', boxShadow: 'none' }}>
                <Container
                    maxWidth="xl"
                    // sx={{ maxWidth: '1553px !important' }}
                >
                    <Toolbar sx={{ justifyContent: 'space-between' }} disableGutters>
                        {/* <Grid item xs={10}> */}
                        <Box sx={{ display: 'flex', alignItems: 'center', width: { md: '90%' } }}>
                            <img
                                className="logo"
                                style={{
                                    marginRight: matches ? 10 : 50,
                                    cursor: 'pointer',
                                    width: matches ? 150 : 160,
                                    height: 80,
                                }}
                                onClick={() => {
                                    navigate('/assets');
                                }}
                                src={logo}
                                alt="logo"
                            />
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    display: {
                                        xs: 'none',
                                        sm: 'none',
                                        md: 'none',
                                        lg: 'flex',
                                        xl: 'flex',
                                    },
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Box sx={{ display: 'flex' }}>
                                    {pages.map((page: any, index: any) => (
                                        <Button
                                            key={page.path}
                                            onClick={() => {
                                                navigate(page.path);
                                            }}
                                            className={
                                                window.location.pathname === page.path
                                                    ? 'active-button'
                                                    : ''
                                            }
                                            sx={{
                                                fontWeight: '600',
                                                px: 2,
                                                py: 3,
                                                color: '#666666',
                                                display: 'block',
                                                fontSize: '18px',
                                                textTransform: 'capitalize',
                                                borderRadius: '0px',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(25, 25, 25, 0.02)',
                                                },
                                            }}
                                        >
                                            {page.name}
                                        </Button>
                                    ))}
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '10px',
                                    }}
                                >
                                    {selectedWallet && !selectedWallet.submitContract && (
                                        <Box
                                            sx={{
                                                textTransform: 'capitalize',
                                                textAlign: 'center',
                                                flexGrow: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                padding: '10px 15px',
                                                borderRadius: '20px',
                                                backgroundColor: 'black',
                                                cursor: 'pointer',
                                                color: 'white',
                                                transition: 'border-color 0.3s ease',
                                                '&:hover': {
                                                    opacity: '0.6',
                                                },
                                                width: '119px',
                                            }}
                                            onClick={() => setConfirm(true)}
                                        >
                                            Submit Contract
                                        </Box>
                                    )}
                                    {selectedWallet && selectedWallet.submitContract && (
                                        <Box
                                            sx={{
                                                textTransform: 'capitalize',
                                                textAlign: 'center',
                                                flexGrow: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                padding: '10px 15px',
                                                borderRadius: '20px',
                                                backgroundColor: 'black',
                                                cursor: 'pointer',
                                                color: 'white',
                                                transition: 'border-color 0.3s ease',
                                                '&:hover': {
                                                    opacity: '0.6',
                                                },
                                                width: '119px',
                                            }}
                                            onClick={() => setCancel(true)}
                                        >
                                            Cancel Active Contract
                                        </Box>
                                    )}

                                    {selectedWallet && selectedWallet !== '' && (
                                        <Box
                                            sx={{
                                                flexGrow: 0,
                                                display: 'flex',
                                                padding: '10px 15px',
                                                borderRadius: '20px',
                                                backgroundColor: 'transparent',
                                                border: '2px solid rgba(78, 193, 123, 0.5)',
                                                cursor: 'pointer',
                                                color: 'black',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                gap: '8px',
                                                '&:hover': {
                                                    backgroundColor: 'rgb(78 193 123 / 2%)',
                                                    border: '2px solid #4ec17b',
                                                },
                                            }}
                                            // onClick={handleOpenMenu}
                                        >
                                            <Typography
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '8px',
                                                    lineHeight: 'unset',
                                                }}
                                            >
                                                <Box
                                                    style={{
                                                        height: '8px',
                                                        width: '8px',
                                                        borderRadius: '50%',
                                                        backgroundColor: 'rgb(78, 193, 123)',
                                                    }}
                                                ></Box>
                                                {selectedWallet.cryptoAddress.slice(0, 6) +
                                                    '...' +
                                                    selectedWallet.cryptoAddress.slice(-5)}
                                            </Typography>
                                            {/* <Box>
                                                <img
                                                    src={SelectArrow}
                                                    alt="SelectArrow"
                                                    width={'14px'}
                                                    height={'14px'}
                                                ></img>
                                            </Box> */}
                                        </Box>
                                    )}

                                    {selectedWallet && !selectedWallet.submitContract ? (
                                        <Box ref={datePickerRef}>
                                            <DatePicker
                                                wrapperClassName="custom-date-picker"
                                                onInputClick={() => setOpenDate((value) => !value)}
                                                minDate={addDays(new Date(), 1)}
                                                maxDate={maxDate()}
                                                // value={addDays(dateValue, 4)}
                                                selected={selectedDate}
                                                disabledKeyboardNavigation
                                                monthsShown={1}
                                                onChange={async (value) => {
                                                    if (value) {
                                                        setSelectedDate(value);
                                                    }
                                                }}
                                                open={openDate}
                                                // inline
                                                customInput={
                                                    <Box
                                                        sx={{
                                                            textAlign: 'center',
                                                            flexGrow: 0,
                                                            display: 'flex',
                                                            fontSize: '14px',
                                                            fontWeight: '500',
                                                            alignItems: 'center',
                                                            padding: '10px 15px',
                                                            borderRadius: '20px',
                                                            backgroundColor: 'rgb(211,211,211)',
                                                            cursor: 'pointer',
                                                            color: 'black',
                                                            transition:
                                                                'background-color 0.3s ease',
                                                            ':hover': {
                                                                backgroundColor:
                                                                    'rgba(25, 25, 25, 0.02)',
                                                            },
                                                            width: 'max-content',
                                                        }}
                                                    >
                                                        {selectedWallet?.executionTransferStartDate
                                                            ? `${formatDate(
                                                                  new Date(
                                                                      selectedWallet?.executionTransferStartDate
                                                                  )
                                                              )}`
                                                            : 'Execution Date'}
                                                    </Box>
                                                }
                                            >
                                                <Box
                                                    sx={
                                                        {
                                                            // padding: '12px',
                                                            // display: 'flex',
                                                            // gap: '100px',
                                                            // width: '100%',
                                                            // justifyContent: 'space-between',
                                                        }
                                                    }
                                                >
                                                    <Box
                                                        sx={{
                                                            marginLeft: '15px',
                                                            marginRight: '15px',
                                                        }}
                                                    >
                                                        {(selectedDate ||
                                                            selectedWallet?.executionTransferStartDate) && (
                                                            <>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: '14px',
                                                                        fontWeight: '600',
                                                                    }}
                                                                >
                                                                    *Execution Date can vary by 1
                                                                    day maximum.
                                                                </Typography>
                                                            </>
                                                        )}
                                                    </Box>

                                                    <Box
                                                        sx={{
                                                            display: 'inline-flex',
                                                            gap: '10px',
                                                            alignItems: 'center',
                                                            marginTop: '10px',
                                                            justifyContent: 'center',
                                                            width: '100%',
                                                            marginBottom: '10px',
                                                        }}
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                borderRadius: '10px',
                                                                maxHeight: '2.5rem',
                                                                textTransform: 'capitalize',
                                                                '&:hover': {
                                                                    opacity: '0.6',
                                                                },
                                                            }}
                                                            onClick={() => {
                                                                setOpenDate(false);
                                                                updateWalletDates();
                                                            }}
                                                        >
                                                            {selectedWallet?.executionTransferStartDate
                                                                ? 'Update Execution'
                                                                : 'Set Execution'}
                                                        </Button>
                                                        {selectedWallet?.executionTransferStartDate && (
                                                            <Button
                                                                variant="outlined"
                                                                sx={{
                                                                    borderRadius: '10px',
                                                                    maxHeight: '2.5rem',
                                                                    textTransform: 'capitalize',
                                                                }}
                                                                onClick={() => {
                                                                    cancelWalletDates();
                                                                    setOpenDate(false);
                                                                }}
                                                            >
                                                                Cancel Execution
                                                            </Button>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </DatePicker>
                                        </Box>
                                    ) : (
                                        <Tooltip title="Current Active Smart Contract Execution Date">
                                            <Box
                                                sx={{
                                                    textAlign: 'center',
                                                    flexGrow: 0,
                                                    display: 'flex',
                                                    fontSize: '14px',
                                                    fontWeight: '500',
                                                    alignItems: 'center',
                                                    padding: '10px 15px',
                                                    borderRadius: '20px',
                                                    backgroundColor: 'rgb(211,211,211)',
                                                    cursor: 'pointer',
                                                    color: 'black',
                                                    transition: 'background-color 0.3s ease',
                                                    ':hover': {
                                                        backgroundColor: 'rgba(25, 25, 25, 0.02)',
                                                    },
                                                    width: 'max-content',
                                                }}
                                            >
                                                {`${formatDate(
                                                    new Date(
                                                        selectedWallet?.executionTransferStartDate
                                                    )
                                                )}`}
                                            </Box>
                                        </Tooltip>
                                    )}
                                    <Button
                                        onClick={() => {
                                            navigate('/create-beneficiary');
                                        }}
                                        sx={{
                                            textTransform: 'capitalize',
                                            textAlign: 'center',
                                            flexGrow: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            lineHeight: 'unset',
                                            padding: '10px 15px',
                                            borderRadius: '20px',
                                            backgroundColor: 'black',
                                            color: 'white',
                                            transition: 'border-color 0.3s ease',
                                            '&:hover': {
                                                backgroundColor: 'black',
                                                opacity: '0.6',
                                            },
                                            width: 'max-content',
                                        }}
                                    >
                                        Create Beneficiary
                                    </Button>
                                    {!currentUser?.isCustomer && (
                                        <Box
                                            onClick={() => {
                                                window.open('/subscription', '_blank');
                                            }}
                                            sx={{
                                                flexGrow: 0,
                                                display: 'flex',
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                alignItems: 'center',
                                                padding: '10px 15px',
                                                borderRadius: '20px',
                                                backgroundColor: 'rgb(211,211,211)',
                                                cursor: 'pointer',
                                                color: 'black',
                                                transition: 'background-color 0.3s ease',
                                                ':hover': {
                                                    backgroundColor: 'rgba(25, 25, 25, 0.02)',
                                                },
                                            }}
                                        >
                                            Subscription
                                        </Box>
                                    )}
                                    <ToggleSwitch setCurrency={props.setCurrency} />
                                </Box>
                            </Box>
                        </Box>
                        {/* </Grid> */}

                        {/* <Grid item xs={4}> */}
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                            <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title="Open setting">
                                    <IconButton
                                        id={'setting'}
                                        onClick={handleOpenSettingMenu}
                                        sx={{ p: 0 }}
                                    >
                                        <img src={downArrow} alt="downArrow"></img>
                                        <img src={setting} alt="setting"></img>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '35px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElSetting}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElSetting)}
                                    onClose={handleCloseSettingMenu}
                                >
                                    {settings.map((setting: any, index: any) => (
                                        <MenuItem
                                            key={index}
                                            onClick={async () => {
                                                if (setting.path == '/subscription') {
                                                    if (currentUser && currentUser.isCustomer) {
                                                        ApiUtils.getManagePaymentLink().then(
                                                            (res) => {
                                                                window.open(res, '_blank');
                                                            }
                                                        );
                                                    } else {
                                                        window.open(setting.path, '_blank');
                                                    }
                                                } else {
                                                    navigate(setting.path);
                                                }
                                                setAnchorElSetting(null);
                                            }}
                                            sx={{ padding: '8px 16px' }}
                                        >
                                            <Typography
                                                textAlign="center"
                                                sx={{
                                                    fontSize: '0.875rem',
                                                    color: '#191919',
                                                    fontWeight: 600,
                                                    '&:hover': { backgroundColor: '#f6f9fc' },
                                                }}
                                            >
                                                {setting.name}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>

                            <Box
                                sx={{
                                    flexGrow: 1,
                                    display: {
                                        xs: 'flex',
                                        sm: 'flex',
                                        md: 'flex',
                                        lg: 'none',
                                        xl: 'none',
                                    },
                                }}
                            >
                                <IconButton
                                    className="drawer_icon"
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleDrawerToggle}
                                    color="inherit"
                                >
                                    <img src={drawerIcon} alt="drawerIcon"></img>
                                </IconButton>
                            </Box>

                            <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title="Profile">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        {currentUser?.photo ? (
                                            <Avatar
                                                style={{ width: '34px', height: '34px' }}
                                                src={BASE_IMG_URL + currentUser.photo}
                                                alt="profile"
                                            />
                                        ) : (
                                            <Avatar style={{ width: '34px', height: '34px' }}>
                                                {currentUser?.firstName &&
                                                    currentUser?.firstName.charAt(0).toUpperCase()}
                                            </Avatar>
                                        )}
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    <Box sx={{ padding: '32px' }}>
                                        <Typography
                                            sx={{
                                                fontSize: '1.125rem',
                                                fontWeight: '600',
                                                lineHeight: '1.6rem',
                                            }}
                                        >
                                            User Profile
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                paddingTop: '24px',
                                                paddingBottom: '24px',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {currentUser?.photo ? (
                                                <Avatar
                                                    style={{ width: '95px', height: '95px' }}
                                                    src={BASE_IMG_URL + currentUser.photo}
                                                    alt="profile"
                                                />
                                            ) : (
                                                <Avatar style={{ width: '95px', height: '95px' }}>
                                                    {currentUser?.firstName &&
                                                        currentUser?.firstName
                                                            .charAt(0)
                                                            .toUpperCase()}
                                                </Avatar>
                                            )}
                                            <Box sx={{ marginLeft: '16px' }}>
                                                <Typography
                                                    sx={{ fontSize: '0.875rem', fontWeight: '600' }}
                                                >
                                                    {`${currentUser?.firstName} ${currentUser?.lastName}`}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: '0.875rem',
                                                        fontWeight: '400',
                                                        color: '#666666',
                                                    }}
                                                >
                                                    {currentUser?.mobile}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: '0.875rem',
                                                        fontWeight: '400',
                                                        color: '#666666',
                                                        display: 'flex',
                                                        gap: '8px',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <img src={mailIcon} alt="mailIcon"></img>
                                                    {currentUser?.emailId}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <hr
                                            style={{
                                                borderColor: '#e5eaef',
                                                borderStyle: 'solid',
                                                margin: '0px',
                                                borderWidth: '0px 0px thin',
                                            }}
                                        />
                                        {!currentUser?.isCustomer && (
                                            <Box
                                                className="profile_modal"
                                                sx={{
                                                    position: 'relative',
                                                    overflow: 'hidden',
                                                    backgroundColor: '#bcbcbc66',
                                                    borderRadius: '16px',
                                                    padding: '24px',
                                                    marginBottom: '24px',
                                                    marginTop: '16px',
                                                    width: '263px',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '50%',
                                                        marginBottom: '16px',
                                                        lineHeight: '1.6rem',
                                                    }}
                                                >
                                                    <Box>
                                                        <Typography
                                                            sx={{
                                                                fontSize: '18px',
                                                                fontWeight: '600',
                                                                marginBottom: '16px',
                                                            }}
                                                        >
                                                            Unlimited Access
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                backgroundColor: 'black',
                                                                color: '#FFFF',
                                                                padding: '6px 20px',
                                                                borderRadius: '20px',
                                                                display: 'flex',
                                                                fontWeight: '500',
                                                                justifyContent: 'center',
                                                                fontSize: '14px',
                                                                width: 'max-content',
                                                                cursor: 'pointer',
                                                                '&:hover': {
                                                                    opacity: '0.6',
                                                                },
                                                            }}
                                                            onClick={() => {
                                                                window.open(
                                                                    '/subscription',
                                                                    '_blank'
                                                                );
                                                                handleCloseUserMenu();
                                                            }}
                                                        >
                                                            Subscription
                                                        </Box>
                                                    </Box>
                                                    <Box>
                                                        <img
                                                            src="https://coins-of-kin.vercel.app/static/media/unlimited-bg.8074f14d98768f2b3895.png"
                                                            alt="img"
                                                            className="profile_img"
                                                            style={{
                                                                position: 'absolute',
                                                                right: '0',
                                                                top: '0',
                                                                height: '100%',
                                                            }}
                                                        ></img>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )}
                                        <Box
                                            sx={{
                                                fontSize: '14px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                lineHeight: '1.75',
                                                padding: '6px 20px',
                                                borderRadius: '20px',
                                                fontWeight: '500',
                                                border: '2px solid rgba(25, 25, 25, 0.5)',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    border: '2px solid black',
                                                },
                                            }}
                                            onClick={() => {
                                                sessionStorage.removeItem('__COI_TOKEN__');
                                                navigate('/login');
                                                handleCloseUserMenu();
                                            }}
                                        >
                                            Logout
                                        </Box>
                                    </Box>
                                </Menu>
                            </Box>
                        </Box>
                        {/* </Grid> */}
                    </Toolbar>
                </Container>
            </AppBar>

            <HeaderDrawer
                openToggle={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
                updateWalletDates={updateWalletDates}
                cancelWalletDates={cancelWalletDates}
                setCurrency={props.setCurrency}
                setWallet={props.setWallet}
                selectedWallet={selectedWallet}
                // setConfirmConnect={setConfirmConnect}
                // userWallet={userWallet}
                open={open}
                handleClose={() => {
                    setConfirm(false);
                }}
                anchorEl={anchorEl}
                handleOpenMenu={handleOpenMenu}
                setAnchorEl={setAnchorEl}
                setSelectedWallet={setSelectedWallet}
                setConfirm={setConfirm}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                addDays={addDays}
                maxDate={maxDate}
                formatDate={formatDate}
                // datePickerRef={datePickerRef}
            />

            <Dialog maxWidth="md" fullWidth={false} open={confirm}>
                <DialogTitle>Confirm contract</DialogTitle>
                <DialogContent>
                    <Box>
                        <Typography>
                            Are you sure you want to submit contract, and you have to pay x gas fee
                            ?
                        </Typography>{' '}
                    </Box>
                    <Box sx={{ marginTop: '2rem', float: 'right', gap: '10px', display: 'flex' }}>
                        <Box
                            sx={{
                                textAlign: 'center',
                                flexGrow: 0,
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '14px',
                                fontWeight: '500',
                                height: '25px',
                                padding: '6px 20px',
                                borderRadius: '20px',
                                backgroundColor: 'black',
                                border: '2px solid rgba(25, 25, 25, 0.5)',
                                cursor: 'pointer',
                                color: 'white',
                                transition: 'border-color 0.3s ease',
                                '&:hover': {
                                    opacity: '0.6',
                                },
                            }}
                            onClick={handleSubmitContract}
                            className="confirm_contract"
                        >
                            YES
                        </Box>
                        <Box
                            sx={{
                                textAlign: 'center',
                                flexGrow: 0,
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '14px',
                                fontWeight: '500',
                                height: '25px',
                                padding: '6px 20px',
                                borderRadius: '20px',
                                backgroundColor: 'transparent',
                                border: '2px solid rgba(25, 25, 25, 0.5)',
                                cursor: 'pointer',
                                color: 'black',
                                transition: 'border-color 0.3s ease',
                                '&:hover': {
                                    borderColor: 'black',
                                },
                            }}
                            onClick={() => {
                                setConfirm(false);
                            }}
                        >
                            NO
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            <Dialog maxWidth="md" fullWidth={false} open={cancel}>
                <DialogTitle>Confirm Contract Cancellation</DialogTitle>
                <DialogContent>
                    <Box>
                        <Typography>
                            Are you sure you want to cancel active contract, and you have to pay x
                            gas fee ?
                        </Typography>{' '}
                    </Box>
                    <Box sx={{ marginTop: '2rem', float: 'right', gap: '10px', display: 'flex' }}>
                        <Box
                            sx={{
                                textAlign: 'center',
                                flexGrow: 0,
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '14px',
                                fontWeight: '500',
                                height: '25px',
                                padding: '6px 20px',
                                borderRadius: '20px',
                                backgroundColor: 'black',
                                border: '2px solid rgba(25, 25, 25, 0.5)',
                                cursor: 'pointer',
                                color: 'white',
                                transition: 'border-color 0.3s ease',
                                '&:hover': {
                                    opacity: '0.6',
                                },
                            }}
                            onClick={handleCancelContract}
                            className="confirm_contract"
                        >
                            YES
                        </Box>
                        <Box
                            sx={{
                                textAlign: 'center',
                                flexGrow: 0,
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '14px',
                                fontWeight: '500',
                                height: '25px',
                                padding: '6px 20px',
                                borderRadius: '20px',
                                backgroundColor: 'transparent',
                                border: '2px solid rgba(25, 25, 25, 0.5)',
                                cursor: 'pointer',
                                color: 'black',
                                transition: 'border-color 0.3s ease',
                                '&:hover': {
                                    borderColor: 'black',
                                },
                            }}
                            onClick={() => {
                                setCancel(false);
                            }}
                        >
                            NO
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}

