import React, { useState, useEffect, useContext } from 'react';
import Typography from '@mui/material/Typography';
import {
    Box,
    Button,
    Checkbox,
    FormGroup,
    Grid,
    Menu,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InputBase from '@mui/material/InputBase';
import Footer from '../CommonLayout/Footer';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import Divider from '@mui/material/Divider';
import SelectArrow from '../../assets/images/dropdownArrow.svg';
import ApiUtils from '../../api/ApiUtils';
import Avatars from '../../assets/images/avatars.png';
import { useFormik } from 'formik';
import Avatar2 from '../../assets/images/avatar2.76aea921824e609d81bbe7ebc59b11a5.png';
import Avatar3 from '../../assets/images/avatar3.73ab20fc1efc49594ad0b8a1b4715e02.png';
import Avatar4 from '../../assets/images/avatar4.b8dd5f61765d636ae42361fe1961c001.png';
import Avatar5 from '../../assets/images/avatar5.63115c3678244d98cfad7ea8d9be8c7b.png';
import Avatar6 from '../../assets/images/avatar6.7cb6b47dd062c4cfecf293749b7cecb0.png';
import Avatar7 from '../../assets/images/avatar7.b179f9a2b741ef294cedf77bd0fac4b2.png';
import Avatar8 from '../../assets/images/avatar8.4dc0db45fbd8fbb50351bd073c9952ec.png';
import Avatar9 from '../../assets/images/avatar9.b8d63a80923c4ceaf546cca61a764a17.png';
import Avatar10 from '../../assets/images/avatar10.590b38a4762f555595d9b2ccb2380fe7.png';
import Avatar11 from '../../assets/images/avatar11.598ac38f6a40baed8283b2f741626367.png';
import Avatar12 from '../../assets/images/avatar12.6a53c6b87e2569c3a47c5abc6618bb55.png';
import Avatar13 from '../../assets/images/avatar13.e0848e909d5e33d92b4ca9507076e2bc.png';
import Avatar14 from '../../assets/images/avatar14.4653d915e299689f9acf1cb9e6616d6c.png';
import Avatar15 from '../../assets/images/avatar15.5aa111c99a5adc0af1855126e071a1a3.png';
import Avatar16 from '../../assets/images/avatar16.3400e09d4864b06131b436507e9dddaa.png';
import Avatar17 from '../../assets/images/avatar17.a6aa63eade64be6993738a1cb64c7ed4.png';
import Avatar18 from '../../assets/images/avatar18.5d53c0f88abaef9f11497b0aad8e8a58.png';
import { search, Picture, Lable } from '../../config/Images';
import { CreateBenValidationSchema } from '../../validations/CreateBenValidationSchema';
import CloseIcon from '@mui/icons-material/Close';
import { useLazyGetLabelListQuery } from '../../services/Slices/apiSlice/GetCreateLabelAPI';
import AllBeneficiariesRow from '../Common/AllBeneficiariesRow';
import { useLazyGetAllBeneficiariesQuery } from '../../services/Slices/apiSlice/assetsAPI';
import { CurrencyContext, CurrentUserContext, WalletContext } from '../CommonLayout/context';
import CustomToast from '../Common/CustomToast';

function Assets() {
    const navigate = useNavigate();
    const matches = useMediaQuery('(min-width:600px)');
    const [openPopup, setOpenPopup] = useState(false);

    const handleOpenPopup = () => {
        setOpenPopup(true);
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };
    // create avatar
    const [openAvatar, setOpenAvatar] = useState(false);
    const [currentPage, setCurrentPage] = useState('avatar');

    const handleClosedPopup = () => {
        setOpenAvatar(false);
    };

    const handlePageChange = (page: string) => {
        setCurrentPage(page);
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const avatarSources = [
        Avatars,
        Avatar2,
        Avatar3,
        Avatar4,
        Avatar5,
        Avatar6,
        Avatar7,
        Avatar8,
        Avatar9,
        Avatar10,
        Avatar11,
        Avatar12,
        Avatar13,
        Avatar14,
        Avatar15,
        Avatar16,
        Avatar17,
        Avatar18,
    ];

    const [selectedAvatar, setSelectedAvatar] = useState<number | null>(null);
    const handleAvatarClick = async (index: number) => {
        const imageUrl = avatarSources[index];
        try {
            // Fetch the image as a Blob
            const response = await fetch(imageUrl);
            const blob = await response.blob();

            // Convert the Blob to Base64
            const base64String = await new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => resolve(reader.result as string);
                reader.onerror = (error) => reject(error);
            });

            // Update state with selected avatar and Base64 representation
            setSelectedAvatar(index);
            setBenPhoto(base64String);
        } catch (error) {
            console.error('Error fetching or converting the image:', error);
        }
    };

    const [BenPhoto, setBenPhoto] = useState<any>(null);

    const currency = useContext(CurrencyContext);
    const currentUser = useContext(CurrentUserContext);
    const walletID = useContext(WalletContext);
    const formik = useFormik({
        initialValues: {
            beneficiaryName: '',
            nickName: '',
            publicAddress: '',
        },
        validationSchema: CreateBenValidationSchema,
        onSubmit: async (values: any) => {
            try {
                const data = await ApiUtils.addBeneficiary(values);
                // Add logic to handle image upload/capture
                if (BenPhoto) {
                    const photoData = await ApiUtils.addBenPhoto({
                        beneficiaryID: data.id,
                        file: BenPhoto,
                    });
                }
                CustomToast({ message: 'Beneficiary successfully created.', severity: 'success' });
                navigate('/split-assets', {
                    state: { benID: data.id, walletID: walletID },
                });
            } catch (error) {
                CustomToast({ message: 'Error adding user. Please try again.', severity: 'error' });
                console.error('Error adding user:', error);
            }
        },
    });
    const [rowData, setRowData] = useState<any>([]);
    const [value, setValue] = useState<any>([0, 100]);
    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number);
        // Other logic
    };
    // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorLabelEl, setAnchorLabalEl] = React.useState<null | HTMLElement>(null);
    // const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    //     setAnchorEl(event.currentTarget);
    // };
    const handleClicked = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorLabalEl(event.currentTarget);
    };
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };
    const handleClosed = () => {
        setAnchorLabalEl(null);
    };
    const [labelList, setLabelList] = useState<any[]>();
    const [GetLabels, { data, isLoading, error }] = useLazyGetLabelListQuery();

    useEffect(() => {
        if (!isLoading && !data) {
            GetLabels(1);
        }
        if (data && !error) {
            setLabelList(data);
            setLabelRowData(data);
        }
    }, [data, isLoading, error, GetLabels]);
    const [walletTok, setWalletTok] = useState<any>([]);
    useEffect(() => {
        if (walletID) {
            const getData = async () => {
                const data = await ApiUtils.getWalletToken(walletID);
                setWalletTok([...data]);
            };
            getData();
        }
    }, [walletID, setWalletTok]);
    type Label = any;

    const [selectedLabel, setSelectedLabel] = useState<Label | null>(null);
    const [defaultRowData, setDefaultRowData] = useState<any>([]);

    const [searchData, setSearch] = useState('');
    const [searchLabel, setSearchLabel] = useState('');
    const [defaultLabelRowData, setLabelRowData] = useState<any>([]);
    useEffect(() => {
        if (searchLabel != '') {
            setLabelList([]);
            for (let i = 0; i < defaultLabelRowData.length; i++) {
                if (
                    defaultLabelRowData[i].labelName
                        .toLowerCase()
                        .includes(searchLabel.toLowerCase())
                ) {
                    setLabelList((prev: any) => [...prev, defaultLabelRowData[i]]);
                }
            }
        } else {
            setLabelList([...defaultLabelRowData]);
        }
    }, [searchLabel, setLabelList, defaultLabelRowData]);
    const [GetAllBeneficiaries, { data: benData, isLoading: benLoading, error: benError }] =
        useLazyGetAllBeneficiariesQuery();

    const [filterData, setFilterData] = useState({
        fromValue: 0,
        toValue: null,
        label: null,
    });
    useEffect(() => {
        GetAllBeneficiaries(filterData);
        if (benData && !benError) {
            setRowData([...benData]);
            setDefaultRowData([...benData]);
        }
    }, [benData, benLoading, benError, GetAllBeneficiaries, filterData]);
    const handleUpdateBenData = () => {
        GetAllBeneficiaries(filterData);
        GetLabels(1);
    };
    const handleCheckboxChange = (label: any) => {
        if (selectedLabel === label) {
            setSelectedLabel(null);
            setFilterData((prev: any) => ({ ...prev, label: null }));
        } else {
            setSelectedLabel(label);
            setFilterData((prev: any) => ({ ...prev, label: label.labelName }));
        }
    };
    useEffect(() => {
        if (searchData != '') {
            setRowData([]);
            for (let i = 0; i < defaultRowData.length; i++) {
                if (
                    defaultRowData[i].beneficiaryName
                        .toLowerCase()
                        .includes(searchData.toLowerCase()) ||
                    defaultRowData[i].nickName.toLowerCase().includes(searchData.toLowerCase())
                ) {
                    setRowData((prev: any) => [...prev, defaultRowData[i]]);
                }
            }
        } else {
            setRowData([...defaultRowData]);
        }
    }, [searchData, setRowData, defaultRowData]);

    return (
        <>
            <Box sx={{ backgroundColor: '#fff', borderRadius: '16px' }}>
                <Box
                    className="profile-box"
                    sx={{
                        borderRadius: '16px',
                        backgroundColor: 'rgb(255, 255, 255)',
                        boxShadow: 'rgba(153, 153, 153, 0.12) 0px 6px 20px 0px',
                        padding: '20px 24px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box className="create_beneficiary" sx={{ fontWeight: 700, fontSize: '21px' }}>
                        Create&nbsp;Beneficiary
                    </Box>
                    {/* <Box
                        className="setting_wrap"
                        sx={{
                            backgroundColor: 'rgba(188, 188, 188, 0.1)',
                            color: 'black',
                            height: '40px',
                            borderRadius: '20px',
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                        onClick={handleClick}
                    >
                        <Box
                            sx={{
                                flexGrow: 0,
                                display: 'flex',
                                padding: '6px 20px',
                                borderRadius: '18px',
                                cursor: 'pointer',
                                color: 'black',
                                gap: matches ? '100px' : '0px',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                fontWeight: '500',
                                width: matches ? '0%' : '100%',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '8px',
                                    alignItems: 'center',
                                    fontSize: '15px',
                                    fontWeight: '500',
                                }}
                            >
                                <img src={SettingIcon} alt="settingIcon"></img>
                                Settings
                            </Box>
                            <Box>
                                <img
                                    src={SelectArrow}
                                    alt="SelectArrow"
                                    width={'14px'}
                                    height={'14px'}
                                ></img>
                            </Box>
                        </Box>
                    </Box>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <Box
                            sx={{
                                padding: '10px 0px',
                                // width: '100%',
                                minWidth: '200px',
                                boxShadow: 'none',
                            }}
                        >
                            <MenuItem onClick={handleClose}>Test</MenuItem>
                            <MenuItem onClick={handleClose}>Test</MenuItem>
                        </Box>
                    </Menu> */}
                </Box>

                <Dialog open={openPopup} onClose={handleClosedPopup} maxWidth="md" fullWidth={true}>
                    <DialogTitle
                        // className="avatar_responsive"
                        sx={{ padding: '5px 0px 0px 0px' }}
                    >
                        <Box
                            className="avatar_responsive"
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                gap: '30%',
                                alignItems: 'center',
                                transition: '0.3s ease-in',
                            }}
                        >
                            <Box
                                className="avatar_wrapper"
                                onClick={() => handlePageChange('avatar')}
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: '600',
                                    cursor: 'pointer',
                                    color: currentPage === 'avatar' ? 'black' : '#666666',
                                    transition: 'text-decoration 0.3s ease',
                                    borderBottom:
                                        currentPage === 'avatar' ? '2px solid black' : 'none',
                                    padding: '12px 10px',
                                }}
                            >
                                Avatar
                            </Box>
                            {/* <Box
                                className="avatar_wrapper"
                                onClick={() => handlePageChange('upload')}
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: '600',
                                    display: 'flex',
                                    cursor: 'pointer',
                                    color: currentPage === 'upload' ? 'black' : '#666666',
                                    transition: 'text-decoration 0.3s ease',
                                    borderBottom:
                                        currentPage === 'upload' ? '2px solid black' : 'none',
                                    padding: '12px 10px',
                                }}
                            >
                                Upload
                            </Box> */}
                        </Box>
                        <Box
                            className="close_svg"
                            sx={{
                                position: 'absolute',
                                right: '30px',
                                top: '20px',
                            }}
                        >
                            <CloseIcon onClick={handleClosePopup} sx={{ cursor: 'pointer' }} />
                        </Box>
                    </DialogTitle>
                    <Divider sx={{}} />

                    <DialogContent>
                        {currentPage === 'avatar' && (
                            <Box sx={{ margin: '0 60px' }}>
                                <Box
                                    className="avatar"
                                    sx={{
                                        display: 'flex',
                                        gap: '46px',
                                        marginBottom: '24px',
                                        justifyContent: 'center',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    {/* {base64Avatar && (
                                        <img
                                            src={base64Avatar}
                                            alt={`selected-avatar`}
                                            style={{
                                                width: '100px', // Adjust the width as needed
                                                height: '100px', // Adjust the height as needed
                                            }}
                                        />
                                    )} */}
                                    {avatarSources.map((avatarSrc: any, index: any) => (
                                        <img
                                            width={70}
                                            key={index}
                                            src={avatarSrc}
                                            alt={`avatar${index + 1}`}
                                            onClick={() => handleAvatarClick(index)}
                                            style={{
                                                borderWidth: '2px',
                                                borderStyle: 'solid',
                                                borderColor:
                                                    selectedAvatar === index ? '#000' : '#E8E8E8',
                                                cursor: 'pointer',
                                                borderRadius: '20px',
                                                transition: 'text-decoration 0.2s ease',
                                            }}
                                            className="avatar_img"
                                        />
                                    ))}
                                </Box>
                                <Box
                                    onClick={handleClosePopup}
                                    sx={{
                                        color: 'white',
                                        backgroundColor: 'black',
                                        width: 'auto',
                                        padding: '10px 20px',
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        cursor: 'pointer',
                                        maxWidth: 'max-content',
                                        marginLeft: 'auto',
                                        borderRadius: '20px',
                                        marginTop: '24px',
                                        '&:hover': {
                                            opacity: '0.6',
                                        },
                                    }}
                                >
                                    Select
                                </Box>
                            </Box>
                        )}
                        {/* {currentPage === 'upload' && (
                            <DropFileInput
                                onFileChange={(files: any) => {
                                    setBenPhoto(files);
                                    handleClosePopup();
                                }}
                                onClick={handleClosePopup}
                            />
                        )} */}
                    </DialogContent>
                </Dialog>

                {/* <FormikProvider value={formik}> */}
                <Box
                    component="form"
                    noValidate
                    onSubmit={formik.handleSubmit}
                    className="create_avatar"
                    sx={{
                        display: 'flex',
                        alignItems: 'baseline',
                        justifyContent: 'space-between',
                        padding: '24px',
                        borderRadius: '16px',
                        backgroundColor: '#fff',
                        marginBottom: '24px',
                    }}
                >
                    <Box
                        className="select_picture"
                        sx={{
                            display: 'inline-flex',
                            gap: '24px',
                            alignItems: 'center',
                        }}
                    >
                        <TextField
                            sx={{
                                position: 'relative',
                                width: '240px',
                                fontSize: '14px',
                                fontWeight: 600,
                                color: '#000000',
                                '& label': {
                                    top: '-5px',
                                    position: 'absolute',
                                    fontSize: '14px',
                                },
                                '&::placeholder': {
                                    color: 'rgba(1, 2, 3, 0.5)',
                                    fontSize: '14px',
                                },
                                '& .MuiOutlinedInput-root': {
                                    height: '40px', // Adjust the height as needed
                                },
                                '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                                    height: '6px', // Adjust the height as needed
                                },
                                '& .MuiOutlinedInput-root .MuiOutlinedInput-input.internal-autofill-selected':
                                    {
                                        height: '6px', // Adjust the height as needed
                                    },
                                '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                                    position: 'absolute',
                                    width: '70%',
                                    height: '1rem',
                                    fontSize: '14px',
                                },
                            }}
                            required
                            placeholder="Beneficiary Name"
                            variant="outlined"
                            fullWidth
                            id="beneficiaryName"
                            label="Beneficiary Name"
                            name="beneficiaryName"
                            autoComplete="userName"
                            onChange={formik.handleChange}
                            value={formik.values.beneficiaryName}
                            error={
                                formik.touched.beneficiaryName &&
                                Boolean(formik.errors.beneficiaryName)
                            }
                            // helperText={formik_update.touched.beneficiaryName && formik_update.errors.beneficiaryName}
                        />

                        {/* <Field
                                name="beneficiaryName"
                                render={({ field }: any) => (
                                    <div>
                                        <InputBase
                                            className="field"
                                            {...field}
                                            required
                                            placeholder="Beneficiary Name"
                                            sx={{
                                                fontSize: '14px',
                                                width: '240px',
                                                marginBottom: '8px',
                                                lineHeight: '1.5',
                                                fontWeight: 600,
                                                color: '#000000',
                                                padding: '10px 16px',
                                                borderRadius: '20px',
                                                backgroundColor: 'rgba(188, 188, 188, 0.1)',
                                                flex: 1,
                                                '&::placeholder': {
                                                    color: 'rgba(1, 2, 3, 0.5)',
                                                },
                                                border:
                                                    formik.touched.beneficiaryName &&
                                                    formik.errors.beneficiaryName
                                                        ? '1px solid red'
                                                        : '1px solid #bcbcbc1a',
                                            }}
                                            inputProps={{
                                                style: {
                                                    padding: '0',
                                                },
                                            }}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                            }}
                                            value={formik.values.beneficiaryName}
                                        />
                                        {formik.touched.beneficiaryName &&
                                            formik.errors.beneficiaryName && (
                                                <div
                                                    style={{
                                                        color: 'red',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    {formik.errors.beneficiaryName as string}{' '}
                                                </div>
                                            )}
                                    </div>
                                )}
                            /> */}

                        <TextField
                            sx={{
                                position: 'relative',
                                width: '240px',
                                fontSize: '14px',
                                fontWeight: 600,
                                color: '#000000',
                                '& label': {
                                    top: '-5px',
                                    position: 'absolute',
                                    fontSize: '14px',
                                },
                                '&::placeholder': {
                                    color: 'rgba(1, 2, 3, 0.5)',
                                    fontSize: '14px',
                                },
                                '& .MuiOutlinedInput-root': {
                                    height: '40px', // Adjust the height as needed
                                },
                                '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                                    position: 'absolute',
                                    width: '70%',
                                    height: '1rem',
                                    fontSize: '14px',
                                },
                            }}
                            required
                            placeholder="Nick Name"
                            variant="outlined"
                            fullWidth
                            id="nickName"
                            label="Nick Name"
                            name="nickName"
                            autoComplete="nickName"
                            onChange={formik.handleChange}
                            value={formik.values.nickName}
                            error={formik.touched.nickName && Boolean(formik.errors.nickName)}
                            // helperText={formik_update.touched.beneficiaryName && formik_update.errors.beneficiaryName}
                        />
                        {/* <Field
                            name="nickName"
                            render={({ field }: any) => (
                                <div>
                                    <InputBase
                                        className="field"
                                        {...field}
                                        required
                                        placeholder="Nick Name"
                                        sx={{
                                            width: '240px',
                                            fontSize: '14px',
                                            marginBottom: '8px',
                                            lineHeight: '1.5',
                                            fontWeight: 600,
                                            color: '#000000',
                                            padding: '10px 16px',
                                            borderRadius: '20px',
                                            backgroundColor: 'rgba(188, 188, 188, 0.1)',
                                            flex: 1,
                                            '&::placeholder': {
                                                color: 'rgba(1, 2, 3, 0.5)',
                                            },
                                            border:
                                                formik.touched.nickName && formik.errors.nickName
                                                    ? '1px solid red'
                                                    : '1px solid #bcbcbc1a',
                                        }}
                                        inputProps={{
                                            style: {
                                                padding: '0',
                                            },
                                        }}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                        }}
                                        value={formik.values.nickName}
                                    />
                                    {formik.touched.nickName && formik.errors.nickName && (
                                        <div
                                            style={{
                                                color: 'red',
                                                fontSize: '14px',
                                            }}
                                        >
                                            {formik.errors.nickName as string}{' '}
                                        </div>
                                    )}
                                </div>
                            )}
                        /> */}

                        <TextField
                            sx={{
                                position: 'relative',
                                width: '240px',
                                fontSize: '14px',
                                fontWeight: 600,
                                color: '#000000',
                                '& label': {
                                    top: '-5px',
                                    position: 'absolute',
                                    fontSize: '14px',
                                },
                                '&::placeholder': {
                                    color: 'rgba(1, 2, 3, 0.5)',
                                    fontSize: '14px',
                                },
                                '& .MuiOutlinedInput-root': {
                                    height: '40px', // Adjust the height as needed
                                },
                                '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                                    position: 'absolute',
                                    width: '70%',
                                    height: '1rem',
                                    fontSize: '14px',
                                },
                            }}
                            required
                            placeholder="Public Address"
                            variant="outlined"
                            fullWidth
                            id="publicAddress"
                            label="Public Address"
                            name="publicAddress"
                            autoComplete="publicAddress"
                            onChange={formik.handleChange}
                            value={formik.values.publicAddress}
                            error={
                                formik.touched.publicAddress && Boolean(formik.errors.publicAddress)
                            }
                            // helperText={formik_update.touched.beneficiaryName && formik_update.errors.beneficiaryName}
                        />

                        {/* <Field
                            name="publicAddress"
                            render={({ field }: any) => (
                                <div>
                                    <InputBase
                                        className="field"
                                        {...field}
                                        required
                                        placeholder="Public Address"
                                        sx={{
                                            marginBottom: '8px',
                                            width: '240px',
                                            fontSize: '14px',
                                            lineHeight: '1.5',
                                            fontWeight: 600,
                                            color: '#000000',
                                            padding: '10px 16px',
                                            borderRadius: '20px',
                                            backgroundColor: 'rgba(188, 188, 188, 0.1)',
                                            flex: 1,
                                            '&::placeholder': {
                                                color: 'rgba(1, 2, 3, 0.5)',
                                            },
                                            border:
                                                formik.touched.publicAddress &&
                                                formik.errors.publicAddress
                                                    ? '1px solid red'
                                                    : '1px solid #bcbcbc1a',
                                        }}
                                        inputProps={{
                                            style: {
                                                padding: '0',
                                            },
                                        }}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                        }}
                                        value={formik.values.publicAddress}
                                    />
                                    {formik.touched.publicAddress &&
                                        formik.errors.publicAddress && (
                                            <div
                                                style={{
                                                    color: 'red',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                {formik.errors.publicAddress as string}{' '}
                                            </div>
                                        )}
                                </div>
                            )}
                        /> */}
                        <Box
                            className="picture"
                            component="div"
                            sx={{
                                display: 'flex',
                                gap: '10px',
                                fontSize: '14px',
                                alignItems: 'center',
                                borderRadius: '20px',
                                border: '2px solid rgba(25, 25, 25, 0.5)',
                                padding: '6px 20px',
                                cursor: 'pointer',
                                fontWeight: '500',
                                height: '26px',
                                '&:hover': {
                                    borderColor: 'black',
                                },
                            }}
                            onClick={handleOpenPopup}
                        >
                            {BenPhoto ? (
                                <>
                                    <img
                                        src={BenPhoto}
                                        alt="selected-avatar"
                                        style={{ width: '20px', height: '20px' }}
                                    />
                                    <span>Picture Selected</span>
                                </>
                            ) : (
                                <>
                                    <img
                                        src={Picture}
                                        alt="picture"
                                        style={{ width: '20px', height: '20px' }}
                                    />
                                    <span>Select Picture</span>
                                </>
                            )}
                        </Box>
                    </Box>
                    <Button
                        className="confirm_btn"
                        sx={{
                            color: '#FFFF',
                            backgroundColor: '#191919',
                            borderRadius: '20px',
                            padding: '5px 20px',
                            fontSize: '14px',
                            fontWeight: '500',
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            textTransform: 'capitalize',
                            '&:hover': {
                                backgroundColor: '#191919',
                                color: '#fff',
                                opacity: '0.6',
                            },
                        }}
                        type="submit"
                    >
                        Confirm
                    </Button>
                </Box>
                {/* </FormikProvider> */}
            </Box>

            <Box sx={{ backgroundColor: '#fff', borderRadius: '16px' }}>
                <Box sx={{ mr: 0 }}>
                    <div className="abcd">
                        <Box
                            className="assets_wrap"
                            mb={matches ? 3 : 3}
                            display={matches ? 'flex' : 'block'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            gap={'16px'}
                            sx={{
                                boxShadow: 'rgba(153, 153, 153, 0.12) 0px 6px 20px 0px',
                                padding: '20px 24px',
                                borderRadius: '16px',
                                backgroundColor: 'rgb(255, 255, 255)',
                            }}
                        >
                            <Box
                                className="list_asset"
                                sx={{
                                    fontWeight: 600,
                                    fontSize: '21px',
                                    mb: matches ? '0px' : '24px',
                                }}
                            >
                                Beneficiaries List
                            </Box>
                            <Box className="header" sx={{ display: 'flex', gap: '16px' }}>
                                <Box
                                    sx={{
                                        flexGrow: 0,
                                        display: 'flex',
                                        padding: '6px 20px',
                                        borderRadius: '18px',
                                        backgroundColor: '#BCBCBC1A',
                                        cursor: 'pointer',
                                        color: 'black',
                                        alignItems: 'center',
                                        gap: '8px',
                                        mb: matches ? '0px' : '16px',
                                    }}
                                >
                                    <img src={search} alt="search"></img>
                                    <InputBase
                                        placeholder="Search for beneficiaries"
                                        sx={{
                                            fontSize: '15px',
                                            lineHeight: '1.5',
                                            fontWeight: 600,
                                            color: 'black',
                                            opacity: '1',
                                            flex: 1,
                                        }}
                                        onChange={(e) => {
                                            setSearch(e.target.value);
                                        }}
                                        inputProps={{
                                            sx: {
                                                padding: '0', // Adjust padding if necessary
                                                '::placeholder': {
                                                    color: 'black', // Set placeholder color to transparent
                                                },
                                                ':focus::placeholder': {
                                                    color: 'transparent', // Set placeholder color when focused
                                                },
                                            },
                                        }}
                                    />
                                </Box>

                                <Box
                                    component="div"
                                    sx={{
                                        flexGrow: 0,
                                        display: 'flex',
                                        padding: '6px 20px',
                                        borderRadius: '18px',
                                        backgroundColor: '#BCBCBC1A',
                                        cursor: 'pointer',
                                        color: 'black',
                                        gap: '100px',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        fontWeight: '500',
                                    }}
                                    onClick={handleClicked}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '16px',
                                            alignItems: 'center',
                                            fontSize: '15px',
                                        }}
                                    >
                                        <img src={Lable} alt="lable"></img>
                                        Labels
                                    </Box>
                                    <Box>
                                        <img
                                            src={SelectArrow}
                                            alt="SelectArrow"
                                            width={'14px'}
                                            height={'14px'}
                                        ></img>
                                    </Box>
                                </Box>
                                <Menu
                                    anchorEl={anchorLabelEl}
                                    open={Boolean(anchorLabelEl)}
                                    onClose={handleClosed}
                                >
                                    <Box sx={{ padding: '14px 20px' }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: '8px',
                                                backgroundColor: '#BCBCBC1A',
                                                borderRadius: '16px',
                                                padding: '7px 16px',
                                                fontSize: '12px',
                                                marginBottom: '16px',
                                            }}
                                        >
                                            {/* Menu items */}
                                            <img src={search} alt="search"></img>
                                            <InputBase
                                                placeholder="Search for label name"
                                                onChange={(e) => {
                                                    setSearchLabel(e.target.value);
                                                }}
                                                sx={{
                                                    fontSize: '15px',
                                                    lineHeight: '1.5',
                                                    fontWeight: 600,
                                                    color: 'black',
                                                    opacity: '1',
                                                    flex: 1,
                                                }}
                                                inputProps={{
                                                    sx: {
                                                        padding: '0', // Adjust padding if necessary
                                                        '::placeholder': {
                                                            color: 'black', // Set placeholder color to transparent
                                                        },
                                                        ':focus::placeholder': {
                                                            color: 'transparent', // Set placeholder color when focused
                                                        },
                                                    },
                                                }}
                                            />
                                        </Box>
                                        <FormGroup>
                                            {labelList &&
                                                labelList.length > 0 &&
                                                labelList.map((label: any, index: any) => {
                                                    return (
                                                        <Box
                                                            key={index}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <Checkbox
                                                                checked={selectedLabel === label}
                                                                onChange={() =>
                                                                    handleCheckboxChange(label)
                                                                }
                                                                // defaultChecked
                                                                sx={{
                                                                    '& .MuiSvgIcon-root': {
                                                                        color: 'black',
                                                                    },
                                                                }}
                                                            />{' '}
                                                            <Box
                                                                className="assets_span"
                                                                sx={{
                                                                    borderRadius: '16px',
                                                                    padding: '7px 20px',
                                                                    fontSize: '12px',
                                                                    fontWeight: '600',
                                                                    backgroundColor:
                                                                        selectedLabel === label
                                                                            ? 'black'
                                                                            : '#8649D333',
                                                                    color:
                                                                        selectedLabel === label
                                                                            ? '#FFFF'
                                                                            : '#8649D3',
                                                                    display: 'flex',
                                                                    gap: '8px',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        height: '8px',
                                                                        width: '8px',
                                                                        borderRadius: '50%',
                                                                        display: 'inline-block',
                                                                        backgroundColor:
                                                                            selectedLabel === label
                                                                                ? '#FFFF'
                                                                                : '#8649D3',
                                                                    }}
                                                                ></span>
                                                                {label.labelName}
                                                            </Box>
                                                        </Box>
                                                    );
                                                })}
                                        </FormGroup>
                                    </Box>
                                    {/* Add more menu items as needed */}
                                </Menu>
                            </Box>
                        </Box>
                    </div>
                </Box>
                <TableContainer
                    component={Paper}
                    sx={{
                        overflowX: 'auto',
                        '&::-webkit-scrollbar': {
                            height: '6px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            borderRadius: '3px',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            borderRadius: '4px',
                        },
                    }}
                >
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    width={150}
                                    sx={{ borderBottom: '1px solid black' }}
                                    align="center"
                                >
                                    #
                                </TableCell>
                                <TableCell
                                    width={300}
                                    sx={{
                                        fontWeight: '600',
                                        borderBottom: '1px solid black',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            color: 'rgb(102, 102, 102)', // Change this to the desired color when hovering
                                        },
                                    }}
                                >
                                    Name
                                </TableCell>
                                <TableCell
                                    width={300}
                                    sx={{
                                        fontWeight: '600',
                                        borderBottom: '1px solid black',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            color: 'rgb(102, 102, 102)', // Change this to the desired color when hovering
                                        },
                                    }}
                                >
                                    Nick Name
                                </TableCell>
                                <TableCell
                                    width={600}
                                    sx={{
                                        fontWeight: '600',
                                        borderBottom: '1px solid black',
                                        cursor: 'pointer',
                                        textAlign: 'start',
                                        '&: hover': {
                                            color: 'rgb(102, 102, 102)',
                                        },
                                    }}
                                >
                                    Label
                                </TableCell>
                                <TableCell
                                    width={400}
                                    sx={{
                                        fontWeight: '600',
                                        borderBottom: '1px solid black',
                                        cursor: 'pointer',
                                        textAlign: 'start',
                                        '&: hover': {
                                            color: 'rgb(102, 102, 102)',
                                        },
                                    }}
                                >
                                    Action
                                </TableCell>
                                <TableCell
                                    width={300}
                                    sx={{ borderBottom: '1px solid black', alignItems: 'center' }}
                                />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rowData &&
                                rowData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row: any, index: any) => (
                                        <AllBeneficiariesRow
                                            key={row.name}
                                            row={row}
                                            walletTok={walletTok}
                                            labelList={labelList}
                                            handleUpdateBenData={handleUpdateBenData}
                                        />
                                    ))}
                            {!rowData.length && (
                                <TableRow>
                                    <TableCell colSpan={5}>
                                        <Typography
                                            color={'rgb(111,111,111)'}
                                            noWrap
                                            align="center"
                                            variant="h5"
                                        >
                                            No Data Found.
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {currentUser && !currentUser.isCustomer && (
                    <Box className="upgrade" textAlign={'center'}>
                        <Grid
                            container
                            spacing={3}
                            sx={{
                                textAlign: 'center',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Grid item lg={7} md={12} sm={12}>
                                <Typography
                                    sx={{
                                        fontSize: '1.5rem',
                                        lineHeight: '1.75rem',
                                        fontWeight: '600',
                                        margin: '0px',
                                    }}
                                >
                                    Choose a plan that is the best for you
                                </Typography>
                                <Typography
                                    sx={{
                                        margin: '24px 0px',
                                        fontSize: '0.875rem',
                                        fontWeight: '400',
                                        lineHeight: '1.75',
                                    }}
                                >
                                    Lorem ipsum dolor sit amet consectetur. Gravida ipsum sagittis
                                    auctor vivamus ornare vulputate id. Hac maecenas cursus
                                    suspendisse nibh massa nibh. Ipsum euismod non viverra neque et.
                                    Leo nibh at id vestibulum arcu maecenas. Et.
                                </Typography>
                                <Button
                                    className="btn_opacity"
                                    style={{
                                        backgroundColor: '#191919',
                                        borderRadius: '20px',
                                        color: '#fff',
                                        padding: '6px 20px',
                                        textTransform: 'capitalize',
                                    }}
                                    onClick={() => window.open('/subscription', '_blank')}
                                >
                                    Subscription
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                )}
                <Box>
                    <TablePagination
                        sx={{
                            overflowX: 'auto',
                            '&::-webkit-scrollbar': {
                                height: '6px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                borderRadius: '3px',
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                borderRadius: '4px',
                            },
                        }}
                        component="div"
                        count={rowData.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </Box>

            <Footer />
        </>
    );
}

export default Assets;

