import { Autocomplete, TextField } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CurrencyContext, WalletContext } from '../CommonLayout/context';

export default function AutoCompleteRow(props: any) {
    const { labelName, keyName, walletBen, apiFor, benID, alreadyAssign } = props;
    const arrayColumn = (array: any, column: string) => {
        return array?.map((item: any) => item[column].toLowerCase());
    };
    const assignedBenId = arrayColumn(
        alreadyAssign,
        keyName == 'beneficiaryName' ? 'beneficiaryID' : 'walletTokenID'
    );
    const currency = useContext(CurrencyContext);
    const walletID = useContext(WalletContext);
    const navigate = useNavigate();
    const [ids, setIds] = useState('');
    useEffect(() => {
        if (ids && ids != '') {
            if (apiFor == 'add-beneficiaries') {
                navigate('/split-assets', {
                    state: { walletID: walletID, benID: ids },
                });
            }
            if (apiFor == 'add-assets') {
                navigate('/split-assets', {
                    state: { walletID: walletID, benID: benID },
                });
            }
        }
    }, [ids]);
    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            onChange={(event: any, newValue: string | null) => {
                walletBen.map((item: any) => {
                    if (item[keyName] == newValue) {
                        setIds(item.id);
                    }
                });
            }}
            options={walletBen
                .map((item: any) => {
                    if (
                        assignedBenId &&
                        assignedBenId.length &&
                        assignedBenId?.indexOf(item.id) != -1
                    ) {
                        return undefined;
                    } else {
                        return item[keyName];
                    }
                })
                .filter((item: any) => item !== undefined)}
            sx={{ width: 200 }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={labelName}
                    sx={{
                        position: 'relative',
                        width: '200px',
                        fontSize: '14px',
                        fontWeight: 600,
                        color: '#000000',
                        '& label': {
                            top: '-5px',
                            position: 'absolute',
                            fontSize: '14px',
                        },
                        '&::placeholder': {
                            color: 'rgba(1, 2, 3, 0.5)',
                            fontSize: '14px',
                        },
                        '& .MuiOutlinedInput-root': {
                            height: '40px', // Adjust the height as needed
                        },
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                            height: '6px', // Adjust the height as needed
                        },
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-input.internal-autofill-selected':
                            {
                                height: '6px', // Adjust the height as needed
                            },
                        '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                            position: 'absolute',
                            width: '70%',
                            height: '1rem',
                            fontSize: '14px',
                        },
                    }}
                />
            )}
        />
    );
}

